import '../App.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-dropdown-select';
import { ActiveDbContext, LoginAuthContext, StyleContext } from "../App";
import lookupInstrument from "../components/Lookup.js"
import CandleChart from '../components/CandleChart';
import CollapsibleTableRow from '../components/CollapsibleTableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import io from 'socket.io-client'
import logo from '../logoLoading.svg'
import loadingSpinner from '../loadingSpinner.svg'
import logoLoading from '../logoLogin.svg'
import FilterMenu from '../components/FilterMenu';
import StatsMenu from '../components/StatsMenu';
import SettingsMenu from '../components/SettingsMenu';
import Cookies from 'universal-cookie';
import jwtE from 'jwt-encode';
import jwtD from 'jwt-decode';
import { ACTIVE_DB, WEBSOCKET_URL, IO_URL, DISCORD_OAUTH_URL, PRE_ROLES, TRADE_TYPES, THIS_URL } from '../components/envVars';
import { calcCertToUnderlying, calcGainDiff, calcUnderlyingToCert, roundToSignificant } from '../components/helpFunc';

//TODO: cookies och port problemet med discord guilds. Innan helt live, testa med ett par A-B test subjekt om det går OK trots att man lägger till/tar bort access till discord guilden med tanke på cookies och auth.

const socket = io(WEBSOCKET_URL);
const regexRemoveParenthesis = /((CASH).*)|((\(([^)]+)\).*))|\(\d+\)|(SHORT)|(LONG)/;
var g_access_token = "";

function removeParenthesis(a) {
  a = a.replace(regexRemoveParenthesis, ' ');
  a = a.trim();
  return a;
}

function replaceBB(a) {
  var ret = "";
	switch(a.split(' ')[0]){
		case "BULL": ret = a.slice(5); break;
		case "BEAR": ret = a.slice(5); break;
		default: ret = a; break;
	}
	return ret;
}

function replaceBBwithArrows(a, bb) {
  var ret = "";
	switch(a.split(' ')[0]){
		case "BULL": ret = a.slice(5); break;
		case "BEAR": ret = a.slice(5); break;
    case "▼": return a;
    case "▲": return a;
		default: ret = a; break;
	}
  ret = bb ? "▼ "+ret : "▲ "+ret;
	return ret;
}

const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

var activeDb = ACTIVE_DB; //default till vår testdatabas
//var activeDb = "759569532221653053" //default till AMO 

const defaultFetchLimit = 10;
const defaultFetchSort = ">ts";

//var activeDb = "871087817948798996" //Testkanalen



//   █████  ██████  ██ 
//  ██   ██ ██   ██ ██ 
//  ███████ ██████  ██ 
//  ██   ██ ██      ██ 
//  ██   ██ ██      ██ 

async function getAuthToken(URLAuthCode, cookies){
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify({
        code: URLAuthCode,
        redirect: THIS_URL,
      })
    };
  
    console.log(requestOptions)
    const response = await fetch(IO_URL+'/auth/', requestOptions);
    if(response.status !== 200){
      console.error("Auth error: ", response)
      return;
    }
    const rec = await response.json();
    console.log(rec);
  
  /*
    cookies.set("access_token", rec.tokens.access_token, {
      expires: new Date(rec.tokens.expire)
    })

    cookies.set("refresh_token", rec.tokens.refresh_token, {
      expires: new Date(rec.tokens.expire)
    })
*/
    cookies.set("access_token", rec.token, {
      expires: new Date("2038-01-19")
    })

    cookies.set("refresh_token", rec.token, {
      expires: new Date("2038-01-19")
    })
    window.location.replace(THIS_URL);
    
  }catch(e){
    console.error(e)
  }
}

function tryTokenTimeout(timeoutDelay){
  return new Promise(resolve => setTimeout(resolve, timeoutDelay))
}

async function tryTokenOk(access_token, setTokenOk, setAuthType, timeoutDelay){
  await tryTokenTimeout(timeoutDelay);
  //console.log("in tryTokenOk, activeDb: ")
  //console.log(activeDb)
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + access_token},
      body: JSON.stringify({
        db: activeDb,
      })
    };
  
    //console.log(requestOptions)
    const response = await fetch(IO_URL+'/getOperator/', requestOptions);
    
    if(response.status == 200){
      //console.log(response.statusText);
      setTokenOk(2)
      const res = await response.json();

      var isAdmin = false;
      for(var a in res.guilds){
        if(res.guilds[a].id == activeDb){
          if(res.guilds[a].access == "admin"){
            isAdmin = true;
            setAuthType(0)                         //authType = 0: admin, 1: user, 2: unitialized
          }                                        //tokenOk = 2: ok, 1: not ok
        }
      }
      if(!isAdmin){
        setAuthType(1)
      }
    } else{
      //console.log(response.statusText);
      setTokenOk(1)
      setAuthType(1)
    }
  }
  catch(e){
    console.log(e)
    setTokenOk(1)
    setAuthType(1)
  }
}

async function getGuilds(setGuilds){
  //console.log("in getGuilds, activeDb: ")
  //console.log(activeDb)
  if(g_access_token === ""){
    return;
  }
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
      body: JSON.stringify({
      })
    };
    const response = await fetch(IO_URL+'/getOperator/', requestOptions);
    var rec = await response.json();
    const recGuilds = rec.guilds;
    //console.log("recGuilds")
    //console.log(recGuilds)
    setGuilds(recGuilds)
    var defaultAvailable = false;
    //console.log("in getGuilds, checking if selected db is available: ")
    for(var a in recGuilds){
      //console.log(recGuilds[a].id)
      if(recGuilds[a].id == activeDb){
        defaultAvailable = true;
      }
    }
    if(!defaultAvailable){
      //console.log("none found, setting activeDb to");
      //console.log(recGuilds[a].id ?? 0);
      activeDb = recGuilds[a].id ?? 0;
      //console.log("activeDb is now: ");
      //console.log(activeDb);
    }
  }catch(e){
    console.log(e)
  }
}

//GET ALL POSITIONS
async function getTradePositions(setTradePositions, filterParams){
  if(g_access_token === ""){
    return;
  }
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
      body: JSON.stringify({
        db: activeDb,
        ...filterParams,
      })
    };
  
    //console.log(requestOptions)
    const response = await fetch(IO_URL+'/getTrades/', requestOptions);
    if(response.statusText === "Method Not Allowed"){
      //console.log(response)
      setTradePositions({})
      return;
    }
    const rec = await response.json();
    
    //console.log("First trade fetch rec: ")
    //console.log(rec)  

    setTradePositions(rec)
    //console.log(rec)
  }
  catch(e){
    console.warn("ERROR FETCHING TRADES: ")
    console.log(e)
  }
}

//GET CHANNELS
async function getChannels(setChannels) {
  if(g_access_token === ""){
    return;
  }
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
      body: JSON.stringify({
        db: activeDb,
      })
    };
  
    //console.log(requestOptions)
    const response = await fetch(IO_URL+'/getChannels/', requestOptions);
    const rec = await response.json();
    setChannels(rec)
    //console.log(rec)
  }
  catch(e){
    console.log(e)
  }
}

//GET USERS
async function getUsers(setUsers) {
  if(g_access_token === ""){
    return;
  }
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
      body: JSON.stringify({
        db: activeDb,
      })
    };
  
    //console.log(requestOptions)
    const response = await fetch(IO_URL+'/getAdmins/', requestOptions);
    const rec = await response.json();
    setUsers(rec)
    //console.log(rec)
  }
  catch(e){
    console.log(e)
  }
}

//GET ADMINS
async function getAdmins(setAdmins) {
  if(g_access_token === ""){
    return;
  }
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
      body: JSON.stringify({
        db: activeDb,
      })
    };
  
    //console.log(requestOptions)
    const response = await fetch(IO_URL+'/getAdmins/', requestOptions);
    const rec = await response.json();
    setAdmins(rec)
    //console.log(rec)
  }
  catch(e){
    console.log(e)
  }
}

//GET ROLES
async function getRoles(setRoles) {
  if(g_access_token === ""){
    return;
  }
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
      body: JSON.stringify({
        db: activeDb,
      })
    };
  
    //console.log(requestOptions)
    const response = await fetch(IO_URL+'/getRoles/', requestOptions);
    const rec = await response.json();
    setRoles(rec)
    //console.log(rec)
  }
  catch(e){
    console.log(e)
  }
}

//GET INSTRUMENT INFO (ENTRY)
async function getInstrumentInfoEntry(instrument, docElementPrice, docElementUnderlyingPrice, docElementInstrument, docElementTicker, docElementNameUnderlying, docElementISINUnderlying, setNewTradeGraphTicker, setNewTradeGraphEntry, docElementConfirmedSelector, docElementCertificateCheckbox, docElementDirectionLong, docElementDirectionShort, docElementLeverage, docElementIsin, docElementCertStrikePrice, docElementCertRatio, docElementCertRiskBuffer, docElementCertExchangeRate, docElementMsgId) {  
  if(g_access_token === ""){
    return;
  }
  if(instrument === ""){
    document.getElementById(docElementPrice).value = ""
    document.getElementById(docElementUnderlyingPrice).value = ""
    document.getElementById(docElementInstrument).value = ""
    document.getElementById(docElementTicker).value = ""
    document.getElementById(docElementNameUnderlying).value = ""
    document.getElementById(docElementISINUnderlying).value = ""
    document.getElementById(docElementCertificateCheckbox).checked = false
    document.getElementById(docElementLeverage).value = 1
    document.getElementById(docElementIsin).value = ""
    document.getElementById(docElementCertStrikePrice).value = ""
    document.getElementById(docElementCertRatio).value = ""
    document.getElementById(docElementCertRiskBuffer).value = ""
    document.getElementById(docElementCertExchangeRate).value = ""
    document.getElementById(docElementMsgId).value = ""
    try{
      setNewTradeGraphTicker({ticker: '', source: '', id: ''});
      setNewTradeGraphEntry(null);
    }catch(e){
      console.log(e)
    }
  }
  try{
    //instrument = lookupInstrument(instrument);
    const msg_id = Date.now();
    console.log("msg_id")
    console.log(msg_id)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
      body: JSON.stringify({
        ticker: instrument,
        history: false,
        timeframe: "1d",
        msg_id: msg_id
      })
    };
  
    //console.log(requestOptions)
    const response = await fetch(IO_URL+'/stockInfo/', requestOptions);
    const rec = await response.json();
    console.log(rec)
    var recIndex = 0;
    //Skippa om någon array entry skulle vara null
    while(!rec[recIndex]){
      recIndex += 1;
      if(recIndex == 10) break;
    }
    //Ändrar pris samt namnet på instrumentet
    document.getElementById(docElementPrice).value = rec[recIndex].lastprice;
    document.getElementById(docElementUnderlyingPrice).value = rec[recIndex].underlying_price ?? 0;
    document.getElementById(docElementInstrument).value = rec[recIndex].name;
    document.getElementById(docElementTicker).value = rec[recIndex].ticker;
    document.getElementById(docElementNameUnderlying).value = rec[recIndex].underlying_name ?? null;
    document.getElementById(docElementISINUnderlying).value = rec[recIndex].underlying_isin ?? null;
    document.getElementById(docElementCertificateCheckbox).checked = rec[recIndex].certificate ? rec[recIndex].certificate : false;
    document.getElementById(docElementLeverage).value = rec[recIndex].leverage ? rec[recIndex].leverage : 1;
    document.getElementById(docElementIsin).value = rec[recIndex].isin ? rec[recIndex].isin : "";
    document.getElementById(docElementCertStrikePrice).value = rec[recIndex].strike ? rec[recIndex].strike : "";
    document.getElementById(docElementCertRatio).value = rec[recIndex].ratio ? rec[recIndex].ratio : "";
    document.getElementById(docElementCertRiskBuffer).value = rec[recIndex].riskBuffer ? rec[recIndex].riskBuffer : "";
    document.getElementById(docElementCertExchangeRate).value = rec[recIndex].exchangeRate ? rec[recIndex].exchangeRate : "";
    document.getElementById(docElementMsgId).value = msg_id;
    console.log(rec[recIndex].direction)
    console.log(rec[recIndex])
    if(rec[recIndex].direction){
      if(rec[recIndex].direction == "long"){
        console.log(document.getElementById(docElementDirectionLong).checked)
        document.getElementById(docElementDirectionLong).checked = true;
        document.getElementById(docElementDirectionShort).checked = false;
        console.log(document.getElementById(docElementDirectionLong).checked)
      } else {
        document.getElementById(docElementDirectionLong).checked = false;
        document.getElementById(docElementDirectionShort).checked = true;
      }
    } else {
      document.getElementById(docElementDirectionLong).checked = true;
      document.getElementById(docElementDirectionShort).checked = false;
    }
    try{
      setNewTradeGraphTicker({ticker: rec[recIndex].ticker, source: rec[recIndex].source, id: rec[recIndex].id})
      setNewTradeGraphEntry(rec[recIndex].lastprice)
    }catch(e){
      console.log(e)
    }
    try{
      var instSel = document.getElementById(docElementConfirmedSelector);
      if(instSel.options.length == 0){
        for(var i in rec){
          if(!rec[i]){
            continue;
          }
          var name = rec[i].name ?? "null"
          var ticker = rec[i].ticker ?? "null"
          var underlyingName = rec[i].underlying_name ?? "null"
          var underlyingISIN = rec[i].underlying_isin ?? "null"
          var source = rec[i].source ?? "null"
          var link = rec[i].link ?? "null"
          var id = rec[i].id ?? "null"
          var price = rec[i].lastprice ?? "null"
          var underlyingPrice = rec[i].underlying_price ?? "null"
          var certificate = rec[i].certificate ?? "null"
          var leverage = rec[i].leverage ?? 1
          var isin = rec[i].isin ?? ""
          var certStrikePrice = rec[i].strike ?? ""
          var certRatio = rec[i].ratio ?? ""
          var certRiskBuffer = rec[i].riskBuffer ?? ""
          var certExchangeRate = rec[i].exchangeRate ?? ""
          if(name != "null"){
            //console.log(rec[i])
            instSel.options[instSel.options.length] = new Option(name, JSON.stringify({ticker, underlyingName, underlyingISIN, source, link, id, price, underlyingPrice, certificate, leverage, isin, certStrikePrice, certRatio, certRiskBuffer, certExchangeRate}));
          } else{
            console.warn("Name was null: ")
            console.warn(i)
            console.warn(rec[i])
          }
        }
      }
    }catch(e){
      console.log(e)
    }
  }
  catch(e){
    console.warn(e)
    console.warn("Officiellt instrument hittades inte, kontakta admin.")
  }
}

//GET INSTRUMENT INFO (EXIT)
async function getInstrumentInfoExit(instrument, docElementPrice, docElementUnderlyingPrice, timef, funcCalcGain) {
  if(g_access_token === ""){
    return;
  }
  try{
    //instrument = lookupInstrument(instrument);
    console.log(instrument)

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
      body: JSON.stringify({
				ticker: instrument.ticker,
				source: instrument.source,
				id: instrument.id,
				history: false,
        timeframe: timef,
      })
    };
  
    //console.log(requestOptions)
    const response = await fetch(IO_URL+'/stockInfo/', requestOptions);
    const rec = await response.json();
    document.getElementById(docElementPrice).value = rec.lastprice ?? rec[0].lastprice ?? 0;
    document.getElementById(docElementUnderlyingPrice).value = rec.underlying_price ?? rec[0].underlying_price ?? 0;
    if(funcCalcGain){
      funcCalcGain();
    }
  }
  catch(e){
    console.log(e)
  }
}

//SEND TRADE UPDATE
async function sendTradeUpdate(params){
  if(g_access_token === ""){
    return;
  }
  console.log("Skickar följande objekt: ")
  console.log(params)
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
      body: JSON.stringify({
        db: activeDb,
        ...params
      })
    };

    const response = await fetch(IO_URL+'/tradeAPI/', requestOptions);
    const rec = await response.json();
    console.log("rec:")
    console.log(rec)
    return rec
  }
  catch(e){
    console.log(e)
  }
}

//EXPORT TO CSV XLSL EXCEL
async function exportToSpreadsheet(filter){
  //getExcelList
  if(g_access_token === ""){
    return;
  }
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
      body: JSON.stringify({
        db: activeDb,
        ...filter
      })
    };
  
    //console.log(requestOptions)
    const response = await fetch(IO_URL+'/getExcelList/', requestOptions);
    const rec = await response.json();
    const xlsxUrl = rec["url"];

    const xlsxRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
      body: JSON.stringify({
        db: activeDb,
      })
    };

    const xlsxRec = await fetch(xlsxUrl, xlsxRequestOptions);
    const xlsxContent = await xlsxRec.arrayBuffer();
    console.log(xlsxContent)
    let jsonBlob = new Blob([xlsxContent])
    const blobUrl = URL.createObjectURL(jsonBlob, {oneTimeOnly: true});

    //Lägg "filen" i en länk som sedan automatiskt klickas -> den laddas ned
    const dl = document.createElement('a');
    dl.setAttribute('href', blobUrl);
    dl.setAttribute('download', activeDb + "_export" + xlsxUrl.split(activeDb)[1]);
    dl.style.display = "none";
    document.body.appendChild(dl);
    dl.dispatchEvent(
      new MouseEvent('click', { 
        bubbles: true, 
        cancelable: true, 
        view: window 
      })
    );    
    try{
      console.log("removing download element")
      document.body.removeChild(dl);
    }catch(e){
      console.error("error removing download element")
    }
  }
  catch(e){
    console.log(e)
  }
}

//  ██ ███    ██ ███████  ██████  
//  ██ ████   ██ ██      ██    ██ 
//  ██ ██ ██  ██ █████   ██    ██ 
//  ██ ██  ██ ██ ██      ██    ██ 
//  ██ ██   ████ ██       ██████  

const AR = {textAlign:"right"}
const AL = {textAlign:"left"}

function genChangeInfo(styleC, a, setSelectedTrade, chartContainerRef, chartRef, resizeObserverRef, isEditingClosedTrade) {
  if(a.exit){
    if(!a.exit.ts){
      a.exit.ts = Date.now();
    }
  }
  const tzOffset = (new Date().getTimezoneOffset())*-60000; //Returnerar -60 för 60 minuters skillnad mot GMT // Gör om till millisek!
  const tradeDate = new Date(a.exit ? a.exit.ts+tzOffset : a.entry.ts+tzOffset).toISOString().slice(0, 16) //Formatet 2022-12-09T14:34
  const currTime = new Date(Date.now()+tzOffset).toISOString().slice(0, 16)

  const updateTradeBtnClick = () => {
    //Hämta alla värden från alla input-element
    const leverage = a.exit ? null : document.getElementById("editLeverageInput").value;
    const ek = document.getElementById("editEkInput").value;
    const entry = a.exit ? null : document.getElementById("editEntryInput").value;
    const gav = a.exit ? null : document.getElementById("editGavInput") ? document.getElementById("editGavInput").value : a.gav ?? null;
    const sl = a.exit ? null : document.getElementById("editSlInput").value;
    const tp1 = a.exit ? null : document.getElementById("editTp1Input").value;
    const tp2 = a.exit ? null : document.getElementById("editTp2Input").value;
    const tp3 = a.exit ? null : document.getElementById("editTp3Input").value;
    const entrydatetime = document.getElementById("editDateInput").value;
    const message = document.getElementById("editMessageInput").value;
    const exit = document.getElementById("editExitInput") ? document.getElementById("editExitInput").value : null;

    const entryUnderlying = a.exit ? null : document.getElementById("editEntryInputUnderlying") ? document.getElementById("editEntryInputUnderlying").value : null;
    const gavUnderlying = a.exit ? null : document.getElementById("editGavInputUnderlying") ? document.getElementById("editGavInputUnderlying").value : null;
    const slUnderlying = a.exit ? null : document.getElementById("editSlInputUnderlying") ? document.getElementById("editSlInputUnderlying").value : null;
    const tp1Underlying = a.exit ? null : document.getElementById("editTp1InputUnderlying") ? document.getElementById("editTp1InputUnderlying").value : null;
    const tp2Underlying = a.exit ? null : document.getElementById("editTp2InputUnderlying") ? document.getElementById("editTp2InputUnderlying").value : null;
    const tp3Underlying = a.exit ? null : document.getElementById("editTp3InputUnderlying") ? document.getElementById("editTp3InputUnderlying").value : null;
    const exitUnderlying = document.getElementById("editExitInputUnderlying") ? document.getElementById("editExitInputUnderlying").value : null;

    //debugga för att se till att tiderna stämmer överens
    console.log("entrydatetime")
    console.log(entrydatetime)
    //const testTime = new Date(entrydatetime);
    console.log('Entry TS: '+tradeDate)
    
    //params är ett key-value objekt som håller alla saker som ska skickas i request bodyn
    var params = {}; 
    params = {...params, trade: a.exit ? a.exit.msg.id : a.entry.msg.id};

    var errors = [];

    if(isEditingClosedTrade){
      if(ek !== "" && ek !== a.ek) params = {...params, ek: Number(ek)};
      if(exit !== "" && exit !== a.exit.price) params = {...params, exit: exit};
      if(exitUnderlying && exitUnderlying !== "" && exitUnderlying !== a.underlying_exit) params = {...params, underlying_exit: exitUnderlying};
      if(entrydatetime !== "" && entrydatetime !== tradeDate) params = {...params, ts: Number(new Date(entrydatetime.replace('T', ' ')))}
      if(message !== "" && message !== a.exit.info) params = {...params, info: message};
    } else{
      //Om leverage inputen innehåller nånting som skiljer sig från sitt tidigare värde så ska vi skicka nytt
      if(leverage !== "" && leverage !== a.häv) params = {...params, häv: leverage};
      if(ek !== "" && ek !== a.ek){
        if(ek < 10){
          params = {...params, ek: Number(ek)};
        } else{
          errors.push("Ek är över 10");
        }
      } 
      if(entry !== "" && entry !== a.entry.price) params = {...params, entry: Number(entry)};
      if(gav !== "" && gav !== a.entry.gav) params = {...params, gav: Number(gav)};
      if(entryUnderlying && entryUnderlying !== "" && entryUnderlying !== a.underlying_entry) params = {...params, underlying_entry: Number(entryUnderlying)};
      if(gavUnderlying && gavUnderlying !== "" && gavUnderlying !== a.underlying_gav) params = {...params, underlying_gav: Number(gavUnderlying)};
      if(sl !== "" && sl !== a.sl){
        params = {...params, sl: sl};
      }else if(!sl && a.sl){ //OM SL inputen nu är tom, men brukade innehålla nånting
        params = {...params, sl: "null"}; //så ska vi nu skicka "null"
      }

      if(slUnderlying !== "" && slUnderlying !== a.underlying_sl){
        params = {...params, underlying_sl: slUnderlying};
      }else if(!slUnderlying && a.underlying_sl){ //OM UNDERLYING SL inputen nu är tom, men brukade innehålla nånting
        params = {...params, underlying_sl: "null"}; //så ska vi nu skicka "null"
      }

      if(tp1 !== "" && (tp1 !== a.tp && tp1 !== a.tp1)){ //Vi jämställer "tp" och "tp1"
        params = {...params, tp1: tp1};
      }else if(!tp1 && a.tp1){ //Samma princip här som med SL ovan, och samma nedan för tp2 och tp3
        params = {...params, tp1: "null"};
      }

      if(tp1Underlying !== "" && tp1Underlying !== a.underlying_tp1){
        params = {...params, underlying_tp1: tp1Underlying};
      }else if(!tp1Underlying && a.underlying_tp1){
        params = {...params, underlying_tp1: "null"};
      } 

      if(tp2 !== "" && tp2 !== a.tp2){
        params = {...params, tp2: tp2};
      }else if(!tp2 && a.tp2){
        params = {...params, tp2: "null"};
      } 
      
      if(tp2Underlying !== "" && tp2Underlying !== a.underlying_tp2){
        params = {...params, underlying_tp2: tp2Underlying};
      }else if(!tp2Underlying && a.underlying_tp2){
        params = {...params, underlying_tp2: "null"};
      } 

      if(tp3 !== "" && tp3 !== a.tp3){
        params = {...params, tp3: tp3};
      }else if(!tp3 && a.tp3){
        params = {...params, tp3: "null"};
      }

      if(tp3Underlying !== "" && tp3Underlying !== a.underlying_tp3){
        params = {...params, underlying_tp3: tp3Underlying};
      }else if(!tp3Underlying && a.underlying_tp3){
        params = {...params, underlying_tp3: "null"};
      }

      if(entrydatetime !== "" && entrydatetime !== tradeDate) params = {...params, ts: Number(new Date(entrydatetime.replace('T', ' ')))}
      if(message !== "" && message !== a.entry.info) params = {...params, info: message};
    }
    
    params = {...params, type: "edit"};

    if(errors.length === 0){
      const invoke = (async () => { //IIFE
        const retResult = await sendTradeUpdate(params);
        alert(retResult);
      })();
    } else{
      var errorsString = "";
      errors.forEach(a => {
        errorsString += a+', ';
      });
      alert("Felmeddelanden:\n" + errorsString.slice(0, -2) + "\nFixa dem eller kontakta admin.");
    }

  }
  
  const sendDeleteTradeBtnClick = () => {
    var params = {}
    params = {type: "delete"};
    if(a.exit){
      params = {...params, trade: a.exit.msg.id};
    }else{
      params = {...params, trade: a.entry.msg.id};
    }
    const invoke = (async () => { //IIFE
      const retResult = await sendTradeUpdate(params);
      alert(retResult);
      setSelectedTrade(null);
    })();
  }

  var result = (
    <>
      <h2 style={{cursor: "default", display:"inline-block", marginLeft:"1%"}}>Ändra info</h2>
      <h2 style={{cursor: "pointer", display:"inline-block", float:"right", width:"4%", fontWeight:"400"}}>
        <span onClick={() => {setSelectedTrade(null); return;}}>×</span>
      </h2>
      <hr></hr>
      <div className='stickyTable'>
        <table className='updateInfosTable'>
          <thead>
            <tr> 
              <th colSpan={2}>Ändra info: 
                <div style={{display:"inline-block", position:"absolute", right:"20px"}}> 
                  <Tooltip placement='top' title="Om du t.ex. öppnade traden med fel instrument, eller valde bull istället för bear" leaveDelay={200}>
                    <button onClick={sendDeleteTradeBtnClick}>Ta bort felaktig trade</button>
                  </Tooltip>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={AR}>Trade: </td>
              <td style={AL}>{a.ticker} {a.name ? ' - ' + a.name : ''}</td>
            </tr>
            <tr>
              <td style={AR}>Kanal: </td>
              <td style={AL}>{a.channel.category ? a.channel.category+" - " : ""} {a.channel.name}</td>
            </tr>
            <tr>
              <td style={AR}>Typ av Instrument: </td>
              <td style={AL}>{a.trade_type ? toTitleCase(a.trade_type) : a.trade_type}</td>
            </tr>
            <tr>
              <td style={AR}>Riktning: </td>
              <td style={AL}>{a.bear === "BEAR" ? "Bear" : "Bull"}</td>
            </tr>
            {
              a.exit ?
              null
            :
              <tr>
                <td style={AR}>Hävstång: </td>
                <td style={AL}><input key={"editLeverageInput"} type="text" id="editLeverageInput" name="editLeverageInput" defaultValue={a.häv} placeholder={a.häv}></input></td>
              </tr>
            }
            <tr>
              <td style={AR}>EK: </td>
              <td style={AL}><input key={"editEkInput"} type="text" id="editEkInput" name="editEkInput" defaultValue={a.ek} placeholder={a.ek}></input></td>
            </tr>

            {
              a.exit ?
              <tr>
                <td style={AR}>Exit: </td>
                <td style={AL}>
                  {
                    a.underlying_exit ? 
                      <>
                        <input onBlur={() => {calcCertToUnderlying("editExitInput", "editExitInputUnderlying", a["häv"], "editExitInput", "editExitInputUnderlying", true, a.direction, a.certificate)}} key={"editExitInput"} type="text" id="editExitInput" name="editExitInput" defaultValue={a.exit.price} placeholder={a.exit.price}></input>
                        <input onBlur={() => {calcUnderlyingToCert("editExitInput", "editExitInputUnderlying", a["häv"], "editExitInput", "editExitInputUnderlying", true, a.direction, a.certificate)}} style={{marginLeft: "2%"}} key={"editExitInputUnderlying"} type="text" id="editExitInputUnderlying" defaultValue={a.underlying_exit ?? null} name="editExitInputUnderlying"></input>
                      </>
                      :
                      <>
                        <input key={"editExitInput"} type="text" id="editExitInput" name="editExitInput" defaultValue={a.exit.price} placeholder={a.exit.price}></input>
                      </>
                  }
                  </td>
              </tr>
            :
            <>
              <tr>
                <td style={AR}>Entry: </td>
                <td style={AL}>
                  {
                    a.underlying_entry ? 
                      <>
                        <input onBlur={() => {calcCertToUnderlying("editEntryInput", "editEntryInputUnderlying", a["häv"], "editEntryInput", "editEntryInputUnderlying", true, a.direction, a.certificate)}} key={"editEntryInput"} type="text" id="editEntryInput" name="editEntryInput" defaultValue={a.entry.price} placeholder={a.entry.price}></input>
                        <input onBlur={() => {calcUnderlyingToCert("editEntryInput", "editEntryInputUnderlying", a["häv"], "editEntryInput", "editEntryInputUnderlying", true, a.direction, a.certificate)}} style={{marginLeft: "2%"}} key={"editEntryInputUnderlying"} type="text" id="editEntryInputUnderlying" defaultValue={a.underlying_entry ?? null} name="editEntryInputUnderlying"></input>
                      </>
                      :
                      <>
                        <input key={"editEntryInput"} type="text" id="editEntryInput" name="editEntryInput" defaultValue={a.entry.price} placeholder={a.entry.price}></input>
                      </>
                  }
                </td>
              </tr>
              { 
                (a.entry.price == a.entry.gav) || !a.entry.gav ? 
                null
                :
                <tr> 
                  <td style={AR}>Gav: </td>
                  <td style={AL}>
                    {
                      a.underlying_entry ? 
                        <>
                          <input onBlur={() => {calcCertToUnderlying("editGavInput", "editGavInputUnderlying", a["häv"], "editEntryInput", "editEntryInputUnderlying", true, a.direction, a.certificate)}} key={"editGavInput"} type="text" id="editGavInput" name="editGavInput" defaultValue={a.entry.gav ?? a.entry.price} placeholder={a.entry.gav ?? a.entry.price}></input>
                          <input onBlur={() => {calcUnderlyingToCert("editGavInput", "editGavInputUnderlying", a["häv"], "editEntryInput", "editEntryInputUnderlying", true, a.direction, a.certificate)}} style={{marginLeft: "2%"}} key={"editGavInputUnderlying"} type="text" id="editGavInputUnderlying" defaultValue={a.underlying_entry ?? a.underlying_gav ?? null} name="editGavInputUnderlying"></input>
                        </>
                        :
                        <>
                          <input key={"editGavInput"} type="text" id="editGavInput" name="editGavInput" defaultValue={a.entry.gav ?? a.entry.price} placeholder={a.entry.gav ?? a.entry.price}></input>
                        </>
                    }
                  </td>
                </tr>
              }
              <tr>
                <td style={AR}>SL: </td>
                <td style={AL}>
                  {
                    a.underlying_entry ? 
                      <>
                        <input onBlur={() => {calcCertToUnderlying("editSlInput", "editSlInputUnderlying", a["häv"], "editEntryInput", "editEntryInputUnderlying", false, a.direction, a.certificate)}} key={"editSlInput"} type="text" id="editSlInput" name="editSlInput" defaultValue={a.sl} placeholder={a.sl}></input>
                        <input onBlur={() => {calcUnderlyingToCert("editSlInput", "editSlInputUnderlying", a["häv"], "editEntryInput", "editEntryInputUnderlying", false, a.direction, a.certificate)}} style={{marginLeft: "2%"}} key={"editSlInputUnderlying"} type="text" id="editSlInputUnderlying" defaultValue={a.underlying_sl ?? null} name="editSlInputUnderlying"></input>
                      </>
                      :
                      <>
                      <input key={"editSlInput"} type="text" id="editSlInput" name="editSlInput" defaultValue={a.sl} placeholder={a.sl}></input>
                      </>
                  }
                </td>
              </tr>

                {
                  ((a.tp && a.tp !== "null") || (a.tp1 && a.tp1 !== "null")) ? 
                  <tr>
                    <td style={AR}>TP1: </td>
                    <td style={AL}>
                      {
                        a.underlying_entry ? 
                          <>
                            <input onBlur={() => {calcCertToUnderlying("editTp1Input", "editTp1InputUnderlying", a["häv"], "editEntryInput", "editEntryInputUnderlying", false, a.direction, a.certificate)}} key={"editTp1Input"} type="text" id="editTp1Input" name="editTp1Input" defaultValue={a.tp ? a.tp : a.tp1} placeholder={a.tp ? a.tp : a.tp1}></input>
                            <input onBlur={() => {calcUnderlyingToCert("editTp1Input", "editTp1InputUnderlying", a["häv"], "editEntryInput", "editEntryInputUnderlying", false, a.direction, a.certificate)}} style={{marginLeft: "2%"}} key={"editTp1InputUnderlying"} type="text" id="editTp1InputUnderlying" defaultValue={a.underlying_tp1 ?? null} name="editTp1InputUnderlying"></input>
                          </>
                          :
                          <>
                            <input key={"editTp1Input"} type="text" id="editTp1Input" name="editTp1Input" defaultValue={a.tp ? a.tp : a.tp1} placeholder={a.tp ? a.tp : a.tp1}></input>
                          </>
                      }
                    </td>
                  </tr>
                :
                  <tr>
                    <td style={AR}>TP1: </td>
                    <td style={AL}>
                      {
                        a.underlying_entry ? 
                          <>
                            <input onBlur={() => {calcCertToUnderlying("editTp1Input", "editTp1InputUnderlying", a["häv"], "editEntryInput", "editEntryInputUnderlying", false, a.direction, a.certificate)}} key={"editTp1Input"} type="text" id="editTp1Input" name="editTp1Input"></input>
                            <input onBlur={() => {calcUnderlyingToCert("editTp1Input", "editTp1InputUnderlying", a["häv"], "editEntryInput", "editEntryInputUnderlying", false, a.direction, a.certificate)}} style={{marginLeft: "2%"}} key={"editTp1InputUnderlying"} type="text" id="editTp1InputUnderlying" name="editTp1InputUnderlying"></input>
                          </>
                          :
                          <>
                            <input key={"editTp1Input"} type="text" id="editTp1Input" name="editTp1Input"></input>
                          </>
                      }
                    </td>
                  </tr>
                }
              
              <tr>
                <td style={AR}>TP2: </td>
                <td style={AL}>
                  {
                    a.underlying_entry ? 
                      <>
                        <input onBlur={() => {calcCertToUnderlying("editTp2Input", "editTp2InputUnderlying", a["häv"], "editEntryInput", "editEntryInputUnderlying", false, a.direction, a.certificate)}} key={"editTp2Input"} type="text" id="editTp2Input" name="editTp2Input" defaultValue={a.tp2} placeholder={a.tp2}></input>
                        <input onBlur={() => {calcUnderlyingToCert("editTp2Input", "editTp2InputUnderlying", a["häv"], "editEntryInput", "editEntryInputUnderlying", false, a.direction, a.certificate)}} style={{marginLeft: "2%"}} key={"editTp2InputUnderlying"} type="text" id="editTp2InputUnderlying" defaultValue={a.underlying_tp2 ?? null} name="editTp2InputUnderlying"></input>
                      </>
                      :
                      <>
                        <input key={"editTp2Input"} type="text" id="editTp2Input" name="editTp2Input" defaultValue={a.tp2} placeholder={a.tp2}></input>
                      </>
                  }
                  </td>
              </tr>
              <tr>
                <td style={AR}>TP3: </td>
                <td style={AL}>
                  {
                    a.underlying_entry ? 
                      <>
                        <input onBlur={() => {calcCertToUnderlying("editTp3Input", "editTp3InputUnderlying", a["häv"], "editEntryInput", "editEntryInputUnderlying", false, a.direction, a.certificate)}} key={"editTp3Input"} type="text" id="editTp3Input" name="editTp3Input" defaultValue={a.tp3} placeholder={a.tp3}></input>
                        <input onBlur={() => {calcUnderlyingToCert("editTp3Input", "editTp3InputUnderlying", a["häv"], "editEntryInput", "editEntryInputUnderlying", false, a.direction, a.certificate)}} style={{marginLeft: "2%"}} key={"editTp3InputUnderlying"} type="text" id="editTp3InputUnderlying" defaultValue={a.underlying_tp3 ?? null} name="editTp3InputUnderlying"></input>
                      </>
                      :
                      <>
                        <input key={"editTp3Input"} type="text" id="editTp3Input" name="editTp3Input" defaultValue={a.tp3} placeholder={a.tp3}></input>
                      </>
                  }
                  </td>
              </tr>
            </>
            }

            
            <tr>
              <td style={AR}>Tidpunkt: </td>
              <td style={AL}><input key={"editDateInput"} type="datetime-local" id="editDateInput" name="editDateInput" defaultValue={tradeDate} placeholder={tradeDate}></input>
              <div className='widener'></div></td>
            </tr>
            <tr>
              <td style={AR}>Meddelande: </td>
              <td style={AL}><input className='fullWidth' key={"editMessageInput"} type="text" id="editMessageInput" name="editMessageInput" defaultValue={a.exit ? a.exit.info : a.entry.info} placeholder={a.exit ? a.exit.info : a.entry.info}></input></td>
            </tr>
            <tr>  
              <td style={{fontWeight:300, textAlign:"right"}}>Kom ihåg att dubbekolla alla siffror</td>
              <td style={AL}>
                <button onClick={updateTradeBtnClick}>Uppdatera info</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{position: "sticky", top: "420px"}}>
        <CandleChart cContRef={chartContainerRef} cRef={chartRef} resObsRef={resizeObserverRef} timef={a.timeframe} information={[{ticker: a.ticker, source: a.data_source, id: a.data_id}, a.entry.price, a.sl, a.tp1, a.tp2, a.tp3]} custOffset={400}/>
      </div>
    </>
  );
  setSelectedTrade(result)

  if(a.exit){
    try{
      document.getElementById("editExitInput").value = a.exit.price ?? "";
    }catch(e){}
    try{
      document.getElementById("editExitInputUnderlying").value = a.underlying_exit ?? "";
    }catch(e){}
    try{
      document.getElementById("editMessageInput").value = a.exit.info ? a.exit.info : a.info ? a.info : "";
    }catch(e){}
    try{
      document.getElementById("editLeverageInput").value = a.häv ?? "";
    }catch(e){}
  } else {
    try{
      document.getElementById("editEntryInput").value = a.entry.price ?? "";
    }catch(e){}
    try{
      document.getElementById("editGavInput").value = a.entry.gav ?? "";
    }catch(e){}
    try{
      document.getElementById("editMessageInput").value = a.entry.info ? a.entry.info : a.info ? a.info : "";
    }catch(e){}
    try{
      document.getElementById("editLeverageInput").value = a.häv ?? "";
    }catch(e){}
    try{
      document.getElementById("editSlInput").value = a.sl ?? "";
    }catch(e){}
    try{
      if((a.tp !==undefined && a.tp!==null) || ( a.tp1!==undefined && a.tp1!==null)) document.getElementById("editTp1Input").value = a.tp ? a.tp : a.tp1;
    }catch(e){}
    try{
      if(a.tp2!==undefined) document.getElementById("editTp2Input").value = a.tp2;
    }catch(e){}
    try{
      if(a.tp3!==undefined) document.getElementById("editTp3Input").value = a.tp3;
    }catch(e){}
    try{
      document.getElementById("editEntryInputUnderlying").value = a.underlying_entry ?? "";
    }catch(e){}
    try{
      document.getElementById("editGavInputUnderlying").value = a.underlying_gav ?? "";
    }catch(e){}
    try{
      document.getElementById("editSlInputUnderlying").value = a.underlying_sl ?? "";
    }catch(e){}
    try{
      document.getElementById("editTp1InputUnderlying").value = a.underlying_tp1 ?? "";
    }catch(e){}
    try{
      document.getElementById("editTp2InputUnderlying").value = a.underlying_tp2 ?? "";
    }catch(e){}
    try{
      document.getElementById("editTp3InputUnderlying").value = a.underlying_tp3 ?? "";
    }catch(e){}
  }
  try{
    document.getElementById("editEkInput").value = a.ek ?? "";
  }catch(e){}
  try{
    document.getElementById("editDateInput").value = tradeDate;
  }catch(e){}
}

//   █████  ██    ██ ███████ ██      ██    ██ ████████ 
//  ██   ██ ██    ██ ██      ██      ██    ██    ██    
//  ███████ ██    ██ ███████ ██      ██    ██    ██    
//  ██   ██  ██  ██       ██ ██      ██    ██    ██    
//  ██   ██   ████   ███████ ███████  ██████     ██    

function genMakeExit(styleC, a, setSelectedTrade, chartContainerRef, chartRef, resizeObserverRef,) {
  const tzOffset = (new Date().getTimezoneOffset())*-60000; //Returnerar -60 för 60 minuters skillnad mot GMT // Gör om till millisek!
  const tradeDate = new Date(a.entry.ts+tzOffset).toISOString().slice(0, 16) //Formatet 2022-12-09T14:34
  const currTime = new Date(Date.now()+tzOffset).toISOString().slice(0, 16)

  console.log(a)

  const sendCloseTradeBtnClick = () => {
    const ek = document.getElementById("editEkInputClose").value;
    const exit = document.getElementById("editExitInputClose").value;
    const exitUnderlying = document.getElementById("editExitInputCloseUnderlying") ? document.getElementById("editExitInputCloseUnderlying").value : null;
    const entrydatetime = document.getElementById("editDateInputClose").value;
    const message = document.getElementById("editMessageInputClose").value;
    var params = {};

    params = {...params, trade: a.exit ? a.exit.msg.id : a.entry.msg.id};
    if(ek !== "")    params = {...params, ek: Number(ek.replace(',','.'))};
    if(exit !== "")  params = {...params, exit: Number(exit.replace(',','.'))};
    if(exitUnderlying && exitUnderlying !== "")  params = {...params, underlying_exit: Number(exitUnderlying.replace(',','.'))};
    params = {...params, ts: Number(new Date(entrydatetime.replace('T', ' ')))}
    params = {...params, info: message};

    params = {...params, type: "close"};
    const invoke = (async () => { //IIFE
      const retResult = await sendTradeUpdate(params);
      alert(retResult);
      setSelectedTrade(null)
    })();
  }

  const calcTheoGain = () => {
    const exitPrice = document.getElementById("editExitInputClose").value;
    const g = (exitPrice - (a.entry.gav ?? a.entry.price))/(a.entry.gav ?? a.entry.price)*100
    document.getElementById("editExitInputTheoGain").value = roundToSignificant(g) + "%";
  }

  const fetchLivePrice = () => {
    console.log("Fetching live price data")
    try{ //återanvänder exit funktionen, den utför samma önskade operation
      const confInst = {ticker: a.ticker, source: a.data_source, id: a.data_id, link: a.data_link}
      console.log(confInst)
      getInstrumentInfoExit(confInst, "editExitInputClose", "", a.timeframe, calcTheoGain)
    }catch(e){
      console.log(e)
    }
  }
  

  var result = (
    <>
      <h2 style={{cursor: "default", display:"inline-block", marginLeft:"1%"}}>Gör avslut och delavslut</h2>
      <h2 style={{cursor: "pointer", display:"inline-block", float:"right", width:"4%", fontWeight:"400"}}>
        <span onClick={() => {setSelectedTrade(null); return;}}>×</span>
      </h2>
      <hr></hr>
      <div className='stickyTable'>
          <table className='closeTradeTable'>
            <thead>
              <tr>  
                <th colSpan={2}>Gör avslut och delavslut: </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={AR}>Trade: </td>
                <td style={AL}>{a.ticker} {a.name ? ' - ' + a.name : ''}</td>
              </tr>
              <tr>
                <td style={AR}>Kanal: </td>
                <td style={AL}>{a.channel.category ? a.channel.category+" - " : ""} {a.channel.name}</td>
              </tr>
              <tr>
                <td style={AR}>Riktning: </td>
                <td style={AL}>{a.bear === "BEAR" ? "Bear" : "Bull"}</td>
              </tr>
              <tr>
                <td style={AR}>Nuvarande gav: </td>
                <td style={AL}>
                  {
                      a.underlying_entry ? 
                      <>
                        <input onBlur={() => {calcCertToUnderlying("editGavInputClose", "editGavInputCloseUnderlying", a["häv"], "editGavInputClose", "editGavInputCloseUnderlying", true, a.direction, a.certificate)}} key={"editGavInputClose"} type="text" id="editGavInputClose" name="editGavInputClose" disabled={true} defaultValue={a.entry.gav ?? a.entry.price} placeholder={a.entry.gav ?? a.entry.price}></input>
                        <input onBlur={() => {calcUnderlyingToCert("editGavInputClose", "editGavInputCloseUnderlying", a["häv"], "editGavInputClose", "editGavInputCloseUnderlying", true, a.direction, a.certificate)}} style={{marginLeft: "2%"}} key={"editGavInputCloseUnderlying"} type="text" id="editGavInputCloseUnderlying" name="editGavInputCloseUnderlying" disabled={true} defaultValue={a.underlying_gav ?? a.underlying_entry} placeholder={a.underlying_gav ?? a.underlying_entry}></input>
                      </>
                      :
                      <>
                        <input key={"editGavInputClose"} type="text" id="editGavInputClose" name="editGavInputClose" disabled={true} defaultValue={a.entry.gav ?? a.entry.price} placeholder={a.entry.gav ?? a.entry.price}></input>
                      </>
                  }
                </td>
              </tr>
              <tr>
                <td style={AR}>EK att ta hem: </td>
                <td style={AL}><input key={"editEkInputClose"} type="text" id="editEkInputClose" name="editEkInputClose" defaultValue={a.ek} placeholder={a.ek}></input></td>
              </tr>
              <tr>
                <td style={AR}>Exitpris: </td>
                <td style={AL}>
                  {
                      a.underlying_entry ? 
                      <>
                        <input onBlur={() => {calcCertToUnderlying("editExitInputClose", "editExitInputCloseUnderlying", a["häv"], "editGavInputClose", "editGavInputCloseUnderlying", false, a.direction, a.certificate)}} key={"editExitInputClose"} type="text" id="editExitInputClose" name="editExitInputClose"></input>
                        <input onBlur={() => {calcUnderlyingToCert("editExitInputClose", "editExitInputCloseUnderlying", a["häv"], "editGavInputClose", "editGavInputCloseUnderlying", false, a.direction, a.certificate)}} style={{marginLeft: "2%"}} key={"editExitInputCloseUnderlying"} type="text" id="editExitInputCloseUnderlying" name="editExitInputCloseUnderlying"></input>
                        <button onClick={() => {fetchLivePrice()}} style={{ width: "18%", marginLeft: "2%", fontWeight:300}}>Hämta&nbsp;pris</button>
                      </>
                      :
                      <>
                        <input onInput={calcTheoGain} key={"editExitInputClose"} type="text" id="editExitInputClose" name="editExitInputClose" defaultValue={0}></input>
                        <button onClick={() => {fetchLivePrice();}} style={{ width: "18%", marginLeft: "1%", marginRight: "1%", fontWeight:300}}>Hämta&nbsp;pris</button>
                      </>
                  }
                  Gain: &nbsp;
                  <input style={{cursor: "default", width: "15%"}} key={"editExitInputTheoGain"} type="text" id="editExitInputTheoGain" name="editExitInputTheoGain" readOnly={true}></input>
                </td>
              </tr>
              <tr>
                <td style={AR}>Tidpunkt: </td>
                <td style={AL}><input key={"editDateInputClose"} type="datetime-local" id="editDateInputClose" name="editDateInputClose" /*disabled={true}*/ defaultValue={currTime} placeholder={currTime}></input>
                <div className='widener'></div></td>
              </tr>
              <tr>
                <td style={AR}>Meddelande: </td>
                <td style={AL}><input className='fullWidth' key={"editMessageInputClose"} type="text" id="editMessageInputClose" name="editMessageInputClose"></input></td>
              </tr>
              <tr>  
                <td style={{fontWeight:300, textAlign:"right"}}>Kom ihåg att dubbekolla alla siffror</td>
                <td style={AL}>
                  <button style={{}} onClick={sendCloseTradeBtnClick}>Gör avslut</button>
                </td>
              </tr>
            </tbody>
          </table>
      </div>
      <div style={{position: "sticky", top: "420px"}}>
        <CandleChart cContRef={chartContainerRef} cRef={chartRef} resObsRef={resizeObserverRef} timef={a.timeframe} information={[{ticker: a.ticker, source: a.data_source, id: a.data_id}, a.entry.price, a.sl, a.tp1, a.tp2, a.tp3]} custOffset={400}/>
      </div>
    </>
  );
  setSelectedTrade(result)
  try{
    document.getElementById("editGavInputClose").value = a.entry.gav ?? a.entry.price;
  }catch(e) {}
  try{
    document.getElementById("editGavInputCloseUnderlying").value = a.underlying_gav ?? a.underlying_entry;
  }catch(e) {}
  try{
    getInstrumentInfoExit({ticker: a.ticker, source: a.data_source, id: a.data_id, link:a.data_link}, "editExitInputClose", "editExitInputCloseUnderlying", a.timeframe ?? "1d", calcTheoGain);
  }catch(e) {console.log(e)}
  try{
    document.getElementById("editEkInputClose").value = a.ek;
  }catch(e) {}
  try{
    document.getElementById("editDateInputClose").value = currTime;
  }catch(e) {}
}

//  ███████ ██      ██    ██ ████████ ████████  █████  
//  ██      ██       ██  ██     ██       ██    ██   ██ 
//  █████   ██        ████      ██       ██    ███████ 
//  ██      ██         ██       ██       ██    ██   ██ 
//  ██      ███████    ██       ██       ██    ██   ██ 

function genMoveLevels(styleC, a, setSelectedTrade, chartContainerRef, chartRef, resizeObserverRef) {
  const tzOffset = (new Date().getTimezoneOffset())*-60000; //Returnerar -60 för 60 minuters skillnad mot GMT // Gör om till millisek!
  const tradeDate = new Date(a.entry.ts+tzOffset).toISOString().slice(0, 16) //Formatet 2022-12-09T14:34
  const currTime = new Date(Date.now()+tzOffset).toISOString().slice(0, 16)

  const sendNewLevelsBtnClick = () => {
    const sl = document.getElementById("editSlInputMoveLevel").value;
    const tp1 = document.getElementById("editTp1InputMoveLevel").value;
    const tp2 = document.getElementById("editTp2InputMoveLevel").value;
    const tp3 = document.getElementById("editTp3InputMoveLevel").value;
    const slUnderlying = document.getElementById("editSlInputMoveLevelUnderlying") ? document.getElementById("editSlInputMoveLevelUnderlying").value : null;
    const tp1Underlying = document.getElementById("editTp1InputMoveLevelUnderlying") ? document.getElementById("editTp1InputMoveLevelUnderlying").value : null;
    const tp2Underlying = document.getElementById("editTp2InputMoveLevelUnderlying") ? document.getElementById("editTp2InputMoveLevelUnderlying").value : null;
    const tp3Underlying = document.getElementById("editTp3InputMoveLevelUnderlying") ? document.getElementById("editTp3InputMoveLevelUnderlying").value : null;
    const entrydatetime = document.getElementById("editDateInputMoveLevel").value;
    const message = document.getElementById("editMessageInputMoveLevel").value;
    var params = {}

    params = {...params, trade: a.exit ? a.exit.msg.id : a.entry.msg.id};
    if(sl !== "" && sl !== a.sl)                                              params = {...params, sl: sl};
    if(tp1 !== "" && (tp1 !== a.tp && tp1 !== a.tp1))                         params = {...params, tp1: tp1};
    if(tp2 !== "" && tp2 !== a.tp2)                                           params = {...params, tp2: tp2};
    if(tp3 !== "" && tp3 !== a.tp3)                                           params = {...params, tp3: tp3};
    if(slUnderlying && slUnderlying !== "" && slUnderlying !== a.underlying_sl)                params = {...params, underlying_sl: slUnderlying};
    if(tp1Underlying && tp1Underlying !== "" && tp1Underlying !== a.underlying_tp1)            params = {...params, underlying_tp1: tp1Underlying};
    if(tp2Underlying && tp2Underlying !== "" && tp2Underlying !== a.underlying_tp2)            params = {...params, underlying_tp2: tp2Underlying};
    if(tp3Underlying && tp3Underlying !== "" && tp3Underlying !== a.underlying_tp3)            params = {...params, underlying_tp3: tp3Underlying};
    params = {...params, ts: Number(new Date(entrydatetime.replace('T', ' ')))}
    params = {...params, info: message};

    params = {...params, type: "change"};
    const invoke = (async () => { //IIFE
      const retResult = await sendTradeUpdate(params);
      alert(retResult);
    })();
    setSelectedTrade(null)
  }

  const sendAddToPositionBtnClick = () => {
    const entry = document.getElementById("addToPosEntry").value;
    const entryUnderlying = document.getElementById("addToPosEntryUnderlying") ? document.getElementById("addToPosEntryUnderlying").value : null;
    const ek = document.getElementById("addToPosEk").value;
    const date = document.getElementById("addToPosDate").value;
    const message = document.getElementById("addToPosMessage").value;
    var params = {}

    var errors = []
    
    params = {...params, trade: a.exit ? a.exit.msg.id : a.entry.msg.id};
    if(entry !== "")                                                          params = {...params, entry: Number(entry.replace(',','.'))};
    if(entryUnderlying && entryUnderlying !== "")                             params = {...params, underlying_entry: Number(entryUnderlying.replace(',','.'))};
    if(ek !== "")                                                             params = {...params, ek: Number(ek.replace(',','.'))};
    if((Number(ek) + a.ek) > 10){
      errors.push("Ek är över 10");
    }
    params = {...params, ts: Number(new Date(date.replace('T', ' ')))}
    params = {...params, info: String(message)};

    params = {...params, type: "fill"};

    console.log("errors")
    console.log(errors)
    if(errors.length === 0){
      const invoke = (async () => { //IIFE
        const retResult = await sendTradeUpdate(params);
        alert(retResult);
      })();
    } else{
      var errorsString = "";
      errors.forEach(a => {
        errorsString += a+', ';
      });
      alert("Felmeddelanden:\n" + errorsString.slice(0, -2) + "\nFixa dem eller kontakta admin.");
    }
    
    setSelectedTrade(null)
  }

  const fetchLivePrice = () => {
    console.log("Fetching live price data")
    try{ //återanvänder exit funktionen, den utför samma önskade operation
      const confInst = {ticker: a.ticker, source: a.data_source, id: a.data_id, link: a.data_link}
      console.log(confInst)
      getInstrumentInfoExit(confInst, "addToPosEntry", "addToPosEntryUnderlying", a.timeframe)
    }catch(e){
      console.log(e)
    }
  }

  const moveSLtoGAVPrice = () => {
    try{
      const gav = a.entry.gav ?? a.entry.price ?? null
      if(gav!==null) document.getElementById("editSlInputMoveLevel").value = gav;
    }catch(e){
      console.log(e)
    }
    try{
      const gavUnderlying = a.underlying_gav ?? a.underlying_entry ?? null
      if(gavUnderlying!==null) document.getElementById("editSlInputMoveLevelUnderlying").value = gavUnderlying;
    }catch(e){
      console.log(e)
    }
  }
  
  console.warn(a)
  
  var result = (
    <>
      <h2 style={{cursor: "default", display:"inline-block", marginLeft:"1%"}}>Money management</h2>
      <h2 style={{cursor: "pointer", display:"inline-block", float:"right", width:"4%", fontWeight:"400"}}>
        <span onClick={() => {setSelectedTrade(null); return;}}>×</span>
      </h2>
      <hr></hr>
      <div className='stickyTable'>
          <table className='newLevelsTable'>
            <thead>
              <tr>  
                <th colSpan={2}>Flytta SL/TP:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={AR}>Trade: </td>
                <td style={AL}>{a.ticker} {a.name ? ' - ' + a.name : ''}</td>
              </tr>
              <tr>
                <td style={AR}>Kanal: </td>
                <td style={AL}>{a.channel.category ? a.channel.category+" - " : ""} {a.channel.name}</td>
              </tr>
              <tr>
                <td style={AR}>Riktning: </td>
                <td style={AL}>{a.bear === "BEAR" ? "Bear" : "Bull"}</td>
              </tr>
              <tr>
                <td style={AR}>Nuvarande gav: </td>
                <td style={AL}>
                  {
                    a.underlying_entry ? 
                    <>
                      <input key={"editGavInputMoveLevel"} type="text" id="editGavInputMoveLevel" name="editGavInputMoveLevel" disabled={true} defaultValue={a.entry.gav ?? a.entry.price} placeholder={a.entry.gav ?? a.entry.price}></input>
                      <input style={{marginLeft: "2%"}} key={"editGavInputMoveLevelUnderlying"} type="text" id="editGavInputMoveLevelUnderlying" defaultValue={a.underlying_gav ?? a.underlying_entry ?? ""} name="editGavInputMoveLevelUnderlying" disabled={true}></input>
                    </>
                    :
                    <>
                      <input key={"editGavInputMoveLevel"} type="text" id="editGavInputMoveLevel" name="editGavInputMoveLevel" disabled={true} defaultValue={a.entry.gav ?? a.entry.price} placeholder={a.entry.gav ?? a.entry.price}></input>
                    </>
                  }
                </td>
              </tr>
              <tr>
                <td style={AR}>EK kvar i traden: </td>
                <td style={AL}><input key={"editEkInputMoveLevel"} type="text" id="editEkInputMoveLevel" name="editEkInputMoveLevel" disabled={true} defaultValue={a.ek} placeholder={a.ek}></input></td>
              </tr>
              <tr>
                <td style={AR}>Riktning: </td>
                <td style={AL}>{a.bear === "BEAR" ? "Bear" : "Bull"}</td>
              </tr>
             <tr>
                <td style={AR}>SL: </td>
                <td style={AL}>
                {
                    a.underlying_entry ? 
                    <>
                      <input onBlur={() => {calcCertToUnderlying("editSlInputMoveLevel", "editSlInputMoveLevelUnderlying", a["häv"], "editGavInputMoveLevel", "editGavInputMoveLevelUnderlying", false, a.direction, a.certificate)}} key={"editSlInputMoveLevel"} type="text" id="editSlInputMoveLevel" name="editSlInputMoveLevel" defaultValue={a.sl} placeholder={a.sl}></input>
                      <input onBlur={() => {calcUnderlyingToCert("editSlInputMoveLevel", "editSlInputMoveLevelUnderlying", a["häv"], "editGavInputMoveLevel", "editGavInputMoveLevelUnderlying", false, a.direction, a.certificate)}} style={{marginLeft: "2%"}} key={"editSlInputMoveLevelUnderlying"} type="text" id="editSlInputMoveLevelUnderlying" defaultValue={a.underlying_sl ?? null} name="editSlInputMoveLevelUnderlying"></input>
                      <button onClick={() => {moveSLtoGAVPrice()}} style={{ width: "18%", marginLeft: "2%", fontWeight:300}}>Breakeven&nbsp;pris</button>
                    </>
                    :
                    <>
                      <input key={"editSlInputMoveLevel"} type="text" id="editSlInputMoveLevel" name="editSlInputMoveLevel" defaultValue={a.sl} placeholder={a.sl}></input>
                      <button onClick={() => {moveSLtoGAVPrice()}} style={{ width: "18%", marginLeft: "2%", fontWeight:300}}>Breakeven&nbsp;pris</button>
                    </>
                }
                </td>
              </tr>
              <tr>
                <td style={AR}>TP1: </td>
                <td style={AL}>
                {
                    a.underlying_entry ? 
                    <>
                      <input onBlur={() => {calcCertToUnderlying("editTp1InputMoveLevel", "editTp1InputMoveLevelUnderlying", a["häv"], "editGavInputMoveLevel", "editGavInputMoveLevelUnderlying", false, a.direction, a.certificate)}} key={"editTp1InputMoveLevel"} type="text" id="editTp1InputMoveLevel" name="editTp1InputMoveLevel" defaultValue={a.tp ? a.tp : a.tp1} placeholder={a.tp ? a.tp : a.tp1}></input>
                      <input onBlur={() => {calcUnderlyingToCert("editTp1InputMoveLevel", "editTp1InputMoveLevelUnderlying", a["häv"], "editGavInputMoveLevel", "editGavInputMoveLevelUnderlying", false, a.direction, a.certificate)}} style={{marginLeft: "2%"}} key={"editTp1InputMoveLevelUnderlying"} type="text" id="editTp1InputMoveLevelUnderlying" defaultValue={a.underlying_tp1 ?? null} name="editTp1InputMoveLevelUnderlying"></input>
                    </>
                    :
                    <>
                      <input key={"editTp1InputMoveLevel"} type="text" id="editTp1InputMoveLevel" name="editTp1InputMoveLevel" defaultValue={a.tp ? a.tp : a.tp1} placeholder={a.tp ? a.tp : a.tp1}></input>
                    </>
                }
                </td>
              </tr>
              <tr>
                <td style={AR}>TP2: </td>
                <td style={AL}>
                {
                    a.underlying_entry ? 
                    <>
                      <input onBlur={() => {calcCertToUnderlying("editTp2InputMoveLevel", "editTp2InputMoveLevelUnderlying", a["häv"], "editGavInputMoveLevel", "editGavInputMoveLevelUnderlying", false, a.direction, a.certificate)}} key={"editTp2InputMoveLevel"} type="text" id="editTp2InputMoveLevel" name="editTp2InputMoveLevel" defaultValue={a.tp2} placeholder={a.tp2}></input>
                      <input onBlur={() => {calcUnderlyingToCert("editTp2InputMoveLevel", "editTp2InputMoveLevelUnderlying", a["häv"], "editGavInputMoveLevel", "editGavInputMoveLevelUnderlying", false, a.direction, a.certificate)}} style={{marginLeft: "2%"}} key={"editTp2InputMoveLevelUnderlying"} type="text" id="editTp2InputMoveLevelUnderlying" defaultValue={a.underlying_tp2 ?? null} name="editTp2InputMoveLevelUnderlying"></input>
                    </>
                    :
                    <>
                      <input key={"editTp2InputMoveLevel"} type="text" id="editTp2InputMoveLevel" name="editTp2InputMoveLevel" defaultValue={a.tp2} placeholder={a.tp2}></input>
                    </>
                }
                </td>
              </tr>
              <tr>
                <td style={AR}>TP3: </td>
                <td style={AL}>
                {
                    a.underlying_entry ? 
                    <>
                      <input onBlur={() => {calcCertToUnderlying("editTp3InputMoveLevel", "editTp3InputMoveLevelUnderlying", a["häv"], "editGavInputMoveLevel", "editGavInputMoveLevelUnderlying", false, a.direction, a.certificate)}} key={"editTp3InputMoveLevel"} type="text" id="editTp3InputMoveLevel" name="editTp3InputMoveLevel" defaultValue={a.tp3} placeholder={a.tp3}></input>
                      <input onBlur={() => {calcUnderlyingToCert("editTp3InputMoveLevel", "editTp3InputMoveLevelUnderlying", a["häv"], "editGavInputMoveLevel", "editGavInputMoveLevelUnderlying", false, a.direction, a.certificate)}} style={{marginLeft: "2%"}} key={"editTp3InputMoveLevelUnderlying"} type="text" id="editTp3InputMoveLevelUnderlying" defaultValue={a.underlying_tp3 ?? null} name="editTp3InputMoveLevelUnderlying"></input>
                    </>
                    :
                    <>
                      <input key={"editTp3InputMoveLevel"} type="text" id="editTp3InputMoveLevel" name="editTp3InputMoveLevel" defaultValue={a.tp3} placeholder={a.tp3}></input>
                    </>
                }
                </td>
              </tr>
              <tr>
              <td style={AR}>Tidpunkt: </td>
                <td style={AL}><input key={"editDateInputMoveLevel"} type="datetime-local" id="editDateInputMoveLevel" name="editDateInputMoveLevel" defaultValue={currTime} placeholder={currTime}></input>
                <div className='widener'></div></td>
              </tr>
              <tr>
                <td style={AR}>Meddelande: </td>
                <td style={AL}><input className='fullWidth' key={"editMessageInputMoveLevel"} type="text" id="editMessageInputMoveLevel" name="editMessageInputMoveLevel"></input></td>
              </tr>
              <tr>  
                <td style={{fontWeight:300, textAlign:"right"}}>Kom ihåg att dubbekolla alla siffror</td>
                <td style={AL}>
                  <button style={{}} onClick={sendNewLevelsBtnClick}>Skicka nya nivåer</button>
                </td>
              </tr> 
              <tr>  
                <th colSpan={2}>Fyll på mer kapital:</th>
              </tr>
              <tr>
                <td style={AR}>Entry: </td>
                <td style={AL}>
                {
                    a.underlying_entry ? 
                    <>
                      <input onBlur={() => {calcCertToUnderlying("addToPosEntry", "addToPosEntryUnderlying", a["häv"], "editGavInputMoveLevel", "editGavInputMoveLevelUnderlying", true, a.direction, a.certificate)}} key={"addToPosEntry"} type="text" id="addToPosEntry" name="addToPosEntry"></input>
                      <input onBlur={() => {calcUnderlyingToCert("addToPosEntry", "addToPosEntryUnderlying", a["häv"], "editGavInputMoveLevel", "editGavInputMoveLevelUnderlying", true, a.direction, a.certificate)}} style={{marginLeft: "2%"}} key={"addToPosEntryUnderlying"} type="text" id="addToPosEntryUnderlying" name="addToPosEntryUnderlying"></input>
                      <button onClick={() => {fetchLivePrice()}} style={{ width: "18%", marginLeft: "2%", fontWeight:300}}>Hämta&nbsp;pris</button>
                    </>
                    :
                    <>
                      <input key={"addToPosEntry"} type="text" id="addToPosEntry" name="addToPosEntry"></input>
                      <button onClick={() => {fetchLivePrice()}} style={{ width: "18%", marginLeft: "1%", fontWeight:300}}>Hämta&nbsp;pris</button>
                    </>
                }
                </td>
              </tr>
              <tr>
                <td style={AR}>EK att öka: </td>
                <td style={AL}><input key={"addToPosEk"} type="text" id="addToPosEk" name="addToPosEk"></input></td>
              </tr>
              <tr>
              <td style={AR}>Tidpunkt: </td>
                <td style={AL}><input key={"addToPosDate"} type="datetime-local" id="addToPosDate" name="addToPosDate" defaultValue={currTime} placeholder={currTime}></input>
                <div className='widener'></div></td>
              </tr>
              <tr>
                <td style={AR}>Meddelande: </td>
                <td style={AL}><input className='fullWidth' key={"addToPosMessage"} type="text" id="addToPosMessage" name="addToPosMessage"></input></td>
              </tr>
              <tr>  
                <td style={{fontWeight:300, textAlign:"right"}}>Kom ihåg att dubbekolla alla siffror</td>
                <td style={AL}>
                  <button style={{}} onClick={sendAddToPositionBtnClick}>Fyll på</button>
                </td>
              </tr> 
            </tbody>
          </table>
        </div>
        <div style={{position: "sticky", top: "420px"}}>
          <CandleChart cContRef={chartContainerRef} cRef={chartRef} resObsRef={resizeObserverRef} timef={a.timeframe} information={[{ticker: a.ticker, source: a.data_source, id: a.data_id}, a.entry.price, a.sl, a.tp1, a.tp2, a.tp3]} custOffset={400}/>
        </div>
    </>
  );
  setSelectedTrade(result)
  try{ 
    //Ändra nivåer
    if(a.sl!==undefined) document.getElementById("editSlInputMoveLevel").value = a.sl;
    if((a.tp !==undefined && a.tp!==null) || ( a.tp1!==undefined && a.tp1!==null)) document.getElementById("editTp1InputMoveLevel").value = a.tp ? a.tp : a.tp1;
    if(a.tp2!==undefined) document.getElementById("editTp2InputMoveLevel").value = a.tp2;
    if(a.tp3!==undefined) document.getElementById("editTp3InputMoveLevel").value = a.tp3;
    document.getElementById("editDateInputMoveLevel").value = currTime;
  }catch(e) {}
  try { 
    document.getElementById("editGavInputMoveLevel").value = a.entry.gav ?? a.entry.price;
  } catch(e) {}
  try {
    document.getElementById("editEkInputMoveLevel").value = a.ek;
  } catch (e) {}
  try { 
    getInstrumentInfoExit({ticker: a.ticker, source: a.data_source, id: a.data_id, link:a.data_link}, "addToPosEntry", "addToPosEntryUnderlying", a.timeframe);
    document.getElementById("addToPosDate").value = currTime;
  } catch(e) {}
  try{ 
    //Ändra nivåer
    if(a.underlying_sl && a.underlying_sl !==null) document.getElementById("editSlInputMoveLevelUnderlying").value = a.underlying_sl ?? "";
    if(a.underlying_tp1 && a.underlying_tp1 !==null) document.getElementById("editTp1InputMoveLevelUnderlying").value = a.underlying_tp1 ?? "";
    if(a.underlying_tp2 && a.underlying_tp2 !==null) document.getElementById("editTp2InputMoveLevelUnderlying").value = a.underlying_tp2 ?? "";
    if(a.underlying_tp3 && a.underlying_tp3 !==null) document.getElementById("editTp3InputMoveLevelUnderlying").value = a.underlying_tp3 ?? "";
  }catch(e) {}
  try { 
    document.getElementById("editGavInputMoveLevelUnderlying").value = a.underlying_gav ?? a.underlying_price;
  } catch(e) {}
}

//  ███    ██ ███████ ██     ██     ████████ ██████   █████  ██████  ███████ 
//  ████   ██ ██      ██     ██        ██    ██   ██ ██   ██ ██   ██ ██      
//  ██ ██  ██ █████   ██  █  ██        ██    ██████  ███████ ██   ██ █████   
//  ██  ██ ██ ██      ██ ███ ██        ██    ██   ██ ██   ██ ██   ██ ██      
//  ██   ████ ███████  ███ ███         ██    ██   ██ ██   ██ ██████  ███████                                                                                                                                                                                                       

//GENERATE NEW TRADE ELEMENT
function genOpenNewTrade(styleC, setNewTradeGen, instruments, channels, admins, roles, setNewTradeGraphTicker, setNewTradeGraphEntry, setNewTradeGraphSl, setNewTradeGraphTp1, setNewTradeGraphTp2, setNewTradeGraphTp3, chartTimeframe, chartContainerRef, chartRef, resizeObserverRef, cookies) {
  const tzOffset = (new Date().getTimezoneOffset())*-60000; //Returnerar -60 för 60 minuters skillnad mot GMT // Gör om till millisek!
  const currTime = new Date(Date.now()+tzOffset).toISOString().slice(0, 16)

  const AR = {textAlign:"right"}
  const AL = {textAlign:"left"}

  const cookieDefaultAuthorIndex = cookies.get("default_author_index")
  console.log(cookieDefaultAuthorIndex)
    /*cookies.set("default_author", "abc", {
    expires: new Date("2038-01-19")
  })*/

  //NTInstrumentConfirmed

  const sendNewTrade = () => {
    console.log("Värden på inputs")
    const inst = document.getElementById("NTInstrument").value ? document.getElementById("NTInstrument").value : null;
    console.log(inst)
    var instConfirmed;
    try{
      instConfirmed = document.getElementById("NTConfirmedInstrumentSelector").selectedOptions[0].text ? document.getElementById("NTConfirmedInstrumentSelector").selectedOptions[0].text : null;
      console.log(instConfirmed)
    }catch(e) {
      console.log(e)
      instConfirmed = inst;
    }
    const isin = document.getElementById("NTInstrumentIsin").value ? document.getElementById("NTInstrumentIsin").value : null;
    console.log(isin)
    const channel = document.getElementById("NTChannel").value ? document.getElementById("NTChannel").value : null;
    console.log(channel)
    var userName = document.getElementById("NTAdmin").value ? JSON.parse(document.getElementById("NTAdmin").value).name : null;
    console.log(userName)
    var userId = document.getElementById("NTAdmin").value ? JSON.parse(document.getElementById("NTAdmin").value).id : null;
    console.log(userId)
    var roles = document.getElementById("NTRoles").value ? JSON.parse(document.getElementById("NTRoles").value) : null;
    console.log(roles)
    const tradeType = document.getElementById("NTTradeType").value ? document.getElementById("NTTradeType").value : null;
    console.log(tradeType)
    const dir = document.querySelector('input[name=NTLongShort]:checked') ? document.querySelector('input[name=NTLongShort]:checked').value ? document.querySelector('input[name=NTLongShort]:checked').value : null : null
    console.log(dir)
    const cert = document.getElementById("NTCertificate").checked;
    console.log(cert)
    const leverage = document.getElementById("NTLeverage").value ? document.getElementById("NTLeverage").value.replace(",",".") : null
    console.log(leverage)
    const ek = document.getElementById("NTEk").value ? document.getElementById("NTEk").value.replace(",",".") : null;
    console.log(ek)
    const entry = document.getElementById("NTEntry").value ? document.getElementById("NTEntry").value.replace(",",".") : null;
    console.log(entry)
    const sl = document.getElementById("NTSl").value ? document.getElementById("NTSl").value.replace(",",".") : null;
    console.log(sl)
    const tp1 = document.getElementById("NTTp1").value ? document.getElementById("NTTp1").value.replace(",",".") : null;
    console.log(tp1)
    const tp2 = document.getElementById("NTTp2").value ? document.getElementById("NTTp2").value.replace(",",".") : null;
    console.log(tp2)
    const tp3 = document.getElementById("NTTp3").value ? document.getElementById("NTTp3").value.replace(",",".") : null;
    console.log(tp3)
    const message = document.getElementById("NTMessage").value ? document.getElementById("NTMessage").value.replace(",",".") : null;
    console.log(message)
    const date = document.getElementById("NTDate").value ? document.getElementById("NTDate").value : null;
    console.log(date)
    const nameUnderlying = document.getElementById("NTNameUnderlying").value ? document.getElementById("NTNameUnderlying").value : null;
    console.log(nameUnderlying)
    const isinUnderlying = document.getElementById("NTISINUnderlying").value ? document.getElementById("NTISINUnderlying").value : null;
    console.log(isinUnderlying)
    var entryUnderlying = document.getElementById("NTEntryUnderlying").value ? document.getElementById("NTEntryUnderlying").value.replace(",",".") : null;
    console.log(entryUnderlying)
    var slUnderlying = document.getElementById("NTSlUnderlying").value ? document.getElementById("NTSlUnderlying").value.replace(",",".") : null;
    console.log(slUnderlying)
    var tp1Underlying = document.getElementById("NTTp1Underlying").value ? document.getElementById("NTTp1Underlying").value.replace(",",".") : null;
    console.log(tp1Underlying)
    var tp2Underlying = document.getElementById("NTTp2Underlying").value ? document.getElementById("NTTp2Underlying").value.replace(",",".") : null;
    console.log(tp2Underlying)
    var tp3Underlying = document.getElementById("NTTp3Underlying").value ? document.getElementById("NTTp3Underlying").value.replace(",",".") : null;
    console.log(tp3Underlying)
    var msg_id = document.getElementById("NTMsgId").value;
    console.log(msg_id)

    entryUnderlying = (entryUnderlying == 0) ? null : entryUnderlying;
    slUnderlying = (slUnderlying == 0) ? null : slUnderlying;
    tp1Underlying = (tp1Underlying == 0) ? null : tp1Underlying;
    tp2Underlying = (tp2Underlying == 0) ? null : tp2Underlying;
    tp3Underlying = (tp3Underlying == 0) ? null : tp3Underlying;

    //Man inte välja att ha inga roller med lösningen under, men bör vara OK, att välja "inga roller" ska vara ekvivalent med "välj default"
    if(roles == null){
      roles = PRE_ROLES;   //REACT_APP_PRE_ROLES
      console.log(roles)
    }
    
    if(userName == null && userId == null){
      userName = admins[cookieDefaultAuthorIndex].name;   //default author cookie
      console.log(userName)
      userId = admins[cookieDefaultAuthorIndex].id;   //default author cookie
      console.log(userId)
    }

    var confirmedInfo;
    var dataSource = '';
    var dataId = '';
    var dataLink = '';
    try{
      confirmedInfo = JSON.parse(document.getElementById("NTConfirmedInstrumentSelector").value);
      dataSource = confirmedInfo.source;
      dataId = confirmedInfo.id;
      dataLink = confirmedInfo.link;
    }catch(e){
    }
    var params = {}
    
    var missing = [];
    var warning = [];

    if(!inst)      {missing.push("Instrument");  } else{                                params = {...params, ticker: String(inst)};}
                                                                                        params = {...params, name: String(instConfirmed)};
                                                                                        params = {...params, isin: String(isin)};
    if(!channel)   {missing.push("Kanal");       } else{                                params = {...params, channel: String(channel)};}
    if(!userId)    {missing.push("Användare");   } else{                                params = {...params, user: {"name": String(userName), "id": String(userId)}};}
    if(!roles)     {warning.push("Roller");      } else{                                params = {...params, roles: roles};}
    if(!tradeType) {warning.push("Typ av Instrument");} else{                           params = {...params, trade_type: String(tradeType)};}
    if(!dir)       {missing.push("Riktning");    } else{                                params = {...params, direction: String(dir)};}
                                                                                        params = {...params, certificate: cert};
    if(!leverage || isNaN(leverage))  {warning.push("Hävstång");} else{                 params = {...params, häv: Number(leverage)};}
    if(!ek || isNaN(ek))              {warning.push("EK");} else {
    if(ek > 10)   {missing.push("EK är över 10") } else{                                params = {...params, ek: Number(ek)};}}
    if(!entry || isNaN(entry))        {missing.push("Entrypris");} else{                params = {...params, entry: Number(entry)};}
    if(!sl || isNaN(sl))              {warning.push("SL");} else{                       params = {...params, sl: Number(sl)};}
    if(tp1 !== "")                                                                      params = {...params, tp1: Number(tp1)};
    if(tp2 !== "")                                                                      params = {...params, tp2: Number(tp2)};
    if(tp2 !== "")                                                                      params = {...params, tp3: Number(tp3)};
    if(message !== "")                                                                  params = {...params, info: String(message)};
    if(date !== "")                                                                     params = {...params, ts: Number(new Date(date.replace('T', ' ')))}
    if((!entryUnderlying && cert) || (isNaN(entryUnderlying) && cert)){warning.push("Underliggande entry");} else{  params = {...params, underlying_entry: Number(entryUnderlying)};}
    if((!slUnderlying && cert) || (isNaN(slUnderlying) && cert)){warning.push("Underliggande SL");}          else{  params = {...params, underlying_sl: Number(slUnderlying)};}
    if(tp1Underlying && tp1Underlying !== "")                                                                       params = {...params, underlying_tp1: Number(tp1Underlying)};
    if(tp2Underlying && tp2Underlying !== "")                                                                       params = {...params, underlying_tp2: Number(tp2Underlying)};
    if(tp3Underlying && tp3Underlying !== "")                                                                       params = {...params, underlying_tp3: Number(tp3Underlying)};
    if(nameUnderlying && nameUnderlying !== "")                                                                     params = {...params, underlying_name: String(nameUnderlying)};
    if(isinUnderlying && isinUnderlying !== "")                                                                     params = {...params, underlying_isin: String(isinUnderlying)};

    params = {...params, type: "new", timeframe: String(chartTimeframe.current), data_source: dataSource, data_id: dataId, data_link: dataLink, msg_id: msg_id};
    //console.log(params)
    if(missing.length === 0){
      if(warning.length === 0){
        console.log("sending ")
        console.log(params)
        const invoke = (async () => { //IIFE
          const retResult = await sendTradeUpdate(params);
          console.log(retResult);
          alert(retResult);
          if(retResult != "Ticker finns redan som öppen trade"){
            console.log("Traden gick igenom")
            /*resizeObserverRef.current.disconnect();
            chartRef.current = null;
            chartContainerRef.current = null;
            resizeObserverRef.current = null;*/
            setNewTradeGen(null)
          }
        })();
      }else{
        var confirmString = "";
        warning.forEach(a => {
          confirmString += a+', ';
        });
        if(window.confirm("Följande värden saknas:\n" + confirmString.slice(0, -2) + "\nÖppna trade ändå?")){
          console.log("sending ")
          console.log(params)

          const invoke = (async () => { //IIFE
            const retResult = await sendTradeUpdate(params);
            console.log(retResult);
            alert(retResult);
            if(retResult != "Ticker finns redan som öppen trade"){
             /*resizeObserverRef.current.disconnect();
              chartRef.current = null;
              chartContainerRef.current = null;
              resizeObserverRef.current = null;*/
              setNewTradeGen(null)
            }
          })();
        }else{
          console.log("not confirmed")
        }
      }
    } else{
      var missingString = "";
        missing.forEach(a => {
          missingString += a+', ';
        });
      alert("Följande värden saknas:\n" + missingString.slice(0, -2) + "\nDe krävs för att öppna traden.");
    }
  }

  const handleSelectChangeInstrument = (values) => {
    console.log("Selected instrument: ")
    console.log(values)
    try {
      var ticker = values[0].ticker;
    } catch (e) {
      console.log(e)
    }
    
    console.log(ticker)
    var instSel = document.getElementById("NTConfirmedInstrumentSelector");
    while(instSel.length > 0){
      //console.log(instSel.options[0])
      instSel.remove(0);
    }
    if(values.length > 0){
      try{
        document.getElementById("NTInstrument").value = ticker;
      }catch(e){
      }
      try{
        //Försök hitta prisinfo för intrumentet
        console.log("hämtar prisinfo för: ", ticker)
        getInstrumentInfoEntry(ticker, "NTEntry", "NTEntryUnderlying", "NTInstrumentConfirmed", "NTInstrumentTicker", "NTNameUnderlying", "NTISINUnderlying", setNewTradeGraphTicker, setNewTradeGraphEntry, "NTConfirmedInstrumentSelector", "NTCertificate", "NTLongShortL", "NTLongShortS", "NTLeverage", "NTInstrumentIsin", "NTCertStrikePrice", "NTCertRatio", "NTCertRiskBuffer", "NTCertExchangeRate", "NTMsgId");
        instSel.disabled = false;
      }catch(e){
        console.log(e)
      }
    } else{
      try{
        document.getElementById("NTInstrument").value = "";
        console.log("hämtar prisinfo för: ", "tom sträng")
        getInstrumentInfoEntry("", "NTEntry", "NTEntryUnderlying", "NTInstrumentConfirmed", "NTInstrumentTicker", "NTNameUnderlying", "NTISINUnderlying", setNewTradeGraphTicker, setNewTradeGraphEntry, "NTConfirmedInstrumentSelector", "NTCertificate", "NTLongShortL", "NTLongShortS", "NTLeverage", "NTInstrumentIsin", "NTCertStrikePrice", "NTCertRatio", "NTCertRiskBuffer", "NTCertExchangeRate", "NTMsgId");
        instSel.disabled = true;
      }catch(e){
        console.log(e)
      }
      try{
        document.getElementById("NTNameUnderlying").value = null;
        document.getElementById("NTISINUnderlying").value = null;
        document.getElementById("NTSl").value = null;
        document.getElementById("NTTp1").value = null;
        document.getElementById("NTTp2").value = null;
        document.getElementById("NTTp3").value = null;
        document.getElementById("NTSlUnderlying").value = null;
        document.getElementById("NTTp1Underlying").value = null;
        document.getElementById("NTTp2Underlying").value = null;
        document.getElementById("NTTp3Underlying").value = null;
        document.getElementById("NTCertStrikePrice").value = null;
        document.getElementById("NTCertRatio").value = null;
        document.getElementById("NTCertRiskBuffer").value = null;
        document.getElementById("NTCertExchangeRate").value = null;
        document.getElementById("NTMsgId").value = null;
        setNewTradeGraphEntry(null);
        setNewTradeGraphSl(null); 
        setNewTradeGraphTp1(null); 
        setNewTradeGraphTp2(null); 
        setNewTradeGraphTp3(null); 
      }catch(e){

      }
    }
  }

  const handleSelectChangeChannel = (values) => {
    try{
      document.getElementById("NTChannel").value = values[0].id;
    }catch(e){
      console.log(e)
    }
  }

  
  const handleSelectChangeUser = (values) => {
    console.log(values)
    try{
      document.getElementById("NTAdmin").value = JSON.stringify({"name": values[0].name, "id": values[0].id});
    }catch(e){
      console.log(e)
    }
    try{
      for(var a = 0; a < admins.length; a++){
        if(admins[a].id == values[0].id){
          console.log("Found matching index, setting cookie to: ", a)
          cookies.set("default_author_index", a, {
            expires: new Date("2038-01-19")
          })
        }
      }
    }catch(e){
      console.log(e)
    }
  }

  const handleSelectChangeRoles = (values) => {
    try{
      var toSend = [];
      for(var i in values){
        toSend.push({"name": values[i].name, "id": values[i].id});
      }
      document.getElementById("NTRoles").value = JSON.stringify(toSend);
    }catch(e){
      console.log(e)
    }
  }

  const handleSelectChangeTradeType = (values) => {
    try{
      document.getElementById("NTTradeType").value = values[0].id;
    }catch(e){
      console.log(e)
    }
  }

  const handleUpdateLevelsInChart = (values) => {
    try{
      switch(values.target.id){
        case "NTEntry": setNewTradeGraphEntry(values.target.value); break;
        case "NTSl": setNewTradeGraphSl(values.target.value); break;
        case "NTTp1": setNewTradeGraphTp1(values.target.value); break;
        case "NTTp2": setNewTradeGraphTp2(values.target.value); break;
        case "NTTp3": setNewTradeGraphTp3(values.target.value); break;
        default: console.log(values.target.id);
      }
    }catch(e){
      console.log(e)
    }
  }

  const ignoreConfirmedInstrument = () => {
    console.log("removing found instrument")
    try{
      console.log("hämtar prisinfo för: ", "tom sträng")
      getInstrumentInfoEntry("", "NTEntry", "NTEntryUnderlying", "NTInstrumentConfirmed", "NTInstrumentTicker", "NTNameUnderlying", "NTISINUnderlying", setNewTradeGraphTicker, setNewTradeGraphEntry, "NTConfirmedInstrumentSelector", "NTCertificate", "NTLongShortL", "NTLongShortS", "NTLeverage", "NTInstrumentIsin", "NTCertStrikePrice", "NTCertRatio", "NTCertRiskBuffer", "NTCertExchangeRate");
    }catch(e){
      console.log(e)
    }
    try{
      var instSel = document.getElementById("NTConfirmedInstrumentSelector");
      while(instSel.length > 0){
        instSel.remove(0);
      }
    }catch(e){
      console.log(e)
    }
  }

  const handleSelectChangeConfirmed = (el) => {
    console.log("handling confirmed change: ")
    console.log(el.target.value)
    var ticker;
    var underlyingName;
    var underlyingISIN;
    var source;
    var id;
    var link;
    var price;
    var underlyingPrice;
    var cert;
    var leverage;
    var isin;
    var certStrikePrice;
    var certRatio;
    var certRiskBuffer;
    var certExchangeRate;
    try{
      var jsn = JSON.parse(el.target.value)
      console.log(jsn)
      ticker = jsn.ticker;
      underlyingName = jsn.underlyingName;
      underlyingISIN = jsn.underlyingISIN;
      source = jsn.source;
      id = jsn.id;
      link = jsn.link;
      price = jsn.price;
      underlyingPrice = jsn.underlyingPrice;
      cert = jsn.certificate ? jsn.certificate != "null" ? true : false : false;
      leverage = jsn.leverage;
      isin = jsn.isin;
      certStrikePrice = jsn.certStrikePrice;
      certRatio = jsn.certRatio;
      certRiskBuffer = jsn.certRiskBuffer;
      certExchangeRate = jsn.certExchangeRate;
    }catch(e){
      console.warn(jsn)
    }

    console.log(ticker)
    console.log(source)
    console.log(id)

    setNewTradeGraphTicker({ticker, source, id});

    console.log("ändrar prisinfo till motsvarande för: ", ticker)

    document.getElementById("NTEntry").value = price;
    document.getElementById("NTEntryUnderlying").value = underlyingPrice;
    document.getElementById("NTNameUnderlying").value = underlyingName;
    document.getElementById("NTISINUnderlying").value = underlyingISIN;
    document.getElementById("NTInstrumentConfirmed").value = '' + ticker + ' - ' + source;
    document.getElementById("NTInstrumentTicker").value = ticker;
    document.getElementById("NTCertificate").checked = cert;
    document.getElementById("NTLeverage").value = leverage;
    document.getElementById("NTInstrumentIsin").value = isin;
    document.getElementById("NTCertStrikePrice").value = certStrikePrice;
    document.getElementById("NTCertRatio").value = certRatio;
    document.getElementById("NTCertRiskBuffer").value = certRiskBuffer;
    document.getElementById("NTCertExchangeRate").value = certExchangeRate;
    setNewTradeGraphEntry(price);
  }

  const fetchLivePrice = () => {
    console.log("Fetching live price data")
    try{ //återanvänder exit funktionen, den utför samma önskade operation
      const confInst = JSON.parse(document.getElementById("NTConfirmedInstrumentSelector").value);
      console.log(confInst)
      getInstrumentInfoExit(confInst, "NTEntry", "NTEntryUnderlying", chartTimeframe.current)
    }catch(e){
      console.log(e)
    }
  }

  console.log(instruments)
  console.log(channels)
  console.log(admins)

  const selectBoxInstrument = (
    <Select 
    options={instruments} 
    onChange={(values) => handleSelectChangeInstrument(values)} 
    placeholder="Instrument"
    searchable={true}
    create={true}
    valueField="ticker"
    labelField="ticker"
    multi={false}
    className={"selectBoxDropdown"}
    />
  )

  const selectBoxChannel = (
    <Select 
    options={channels} 
    onChange={(values) => handleSelectChangeChannel(values)} 
    placeholder="Kanal"
    searchable={true}
    create={false}
    valueField="id"
    labelField="longname"
    multi={false}
    className={"selectBoxDropdown"}
    />
  )

  const selectBoxAdmin = (
    <Select 
    options={admins} 
    onChange={(values) => handleSelectChangeUser(values)} 
    placeholder="Användare"
    searchable={true}
    create={false}
    valueField="id"
    labelField="name"
    multi={false}
    className={"selectBoxDropdown"}
    values={[admins[cookieDefaultAuthorIndex]]}
    />
  )

  const selectBoxRoles = (
    <Select 
    options={roles} 
    onChange={(values) => handleSelectChangeRoles(values)} 
    placeholder="Roller"
    searchable={true}
    create={false}
    valueField="id"
    labelField="name"
    multi={true}
    className={"selectBoxDropdown"}
    values={PRE_ROLES}
    />
  )

  const selectBoxTradeType = (
    <Select 
      options={[//TODO REACT_APP_TRADE_TYPES
        {id: "stocks", value: "stocks", label: "Aktiebolag"},
        {id: "commodities", value: "commodities", label: "Råvaror"},
        {id: "indices", value: "indices", label: "Index"},
        {id: "currencies", value: "currencies", label: "Valutor"},
        {id: "crypto", value: "crypto", label: "Krypto"},
        {id: "fire", value: "fire", label: "Fire"},
      ]}
      onChange={(values) => handleSelectChangeTradeType(values)} 
      placeholder="Typ av Instrument"
      searchable={true}
      create={true}
      valueField="id"
      labelField="label"
      multi={false}
      className={"selectBoxDropdown"}
    />
  )

  const handleChangedMessageTextArea = (values) => {
    try{
      document.getElementById("NTMessage").value = values.target.value;
    }catch(e){
      console.log(e)
    }
  }

  const messageTextArea = (
    <textarea
    defaultValue={"Öppnar trade"}
    onChange={(values) => handleChangedMessageTextArea(values)}
    rows={1} cols={29}
    className={"textArea"}
    key={"NTMessage"}
    id="NTMessage" 
    name="NTMessage"
    />
  )

  var result = (
    <>
      <h2 style={{cursor: "default", display:"inline-block", marginLeft:"1%"}}>Ny Trade</h2>
      <h2 style={{cursor: "pointer", display:"inline-block", float:"right", width:"4%", fontWeight:"400"}}>
        <span onClick={() => {setNewTradeGen(null); setNewTradeGraphTicker({ticker: '', source: '', id: ''}); return;}}>×</span>
      </h2>
      <hr></hr>
      <div className='stickyTable' id='stickyTable'>
        <table className='newTradeTable'>
          <thead>
            <tr>  
              <th colSpan={2}>Ny trade: </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={AR}>Instrument: </td>
              <td style={AL}>{selectBoxInstrument}<input key={"NTInstrument"} type="text" id="NTInstrument" name="NTInstrument" disabled={true} hidden={true}></input></td>
            </tr>
            <tr id="NTInstrumentConfirmedRow">
              <td style={AR}>Bekräftat instrument: </td>
              <td style={AL}>
                <input key={"NTInstrumentConfirmed"} type="text" id="NTInstrumentConfirmed" name="NTInstrumentConfirmed" disabled={true} hidden={false} size="30"></input>
                <input key={"NTInstrumentTicker"} type="text" id="NTInstrumentTicker" name="NTInstrumentTicker" disabled={true} hidden={true} size="0"></input>
                <button onClick={() => {ignoreConfirmedInstrument()}} style={{ width: "15%", marginLeft: "1%", fontWeight:300}}>Ignorera</button>
                <select key={"NTConfirmedInstrumentSelector"} id="NTConfirmedInstrumentSelector" name="NTConfirmedInstrumentSelector" disabled onChange={handleSelectChangeConfirmed} placeholder="Välj instrument ovan"></select>
                                
                <input key={"NTNameUnderlying"} type="text" id="NTNameUnderlying" name="NTNameUnderlying" disabled={true} hidden={true}></input>
                <input key={"NTISINUnderlying"} type="text" id="NTISINUnderlying" name="NTISINUnderlying" disabled={true} hidden={true}></input>
                <input key={"NTCertStrikePrice"} type="text" id="NTCertStrikePrice" name="NTCertStrikePrice" disabled={true} hidden={true}></input>
                <input key={"NTCertRatio"} type="text" id="NTCertRatio" name="NTCertRatio" disabled={true} hidden={true}></input>
                <input key={"NTCertRiskBuffer"} type="text" id="NTCertRiskBuffer" name="NTCertRiskBuffer" disabled={true} hidden={true}></input>
                <input key={"NTCertExchangeRate"} type="text" id="NTCertExchangeRate" name="NTCertExchangeRate" disabled={true} hidden={true}></input>
                <input key={"NTMsgId"} type="text" id="NTMsgId" name="NTMsgId" disabled={true} hidden={true}></input>
              </td>
            </tr>
            <tr id="NTInstrumentConfirmedRow">
              <td style={AR}>ISIN: </td>
              <td style={AL}>
                <input key={"NTInstrumentIsin"} type="text" id="NTInstrumentIsin" name="NTInstrumentIsin" disabled={false} hidden={false} size="30"></input>
              </td>
            </tr>
            <tr>
              <td style={AR}>Kanal: </td>
              <td style={AL}>{selectBoxChannel}<input key={"NTChannel"} type="text" id="NTChannel" name="NTChannel" disabled={true} hidden={true}></input></td>
            </tr>
            <tr>
              <td style={AR}>Användare: </td>
              <td style={AL}>{selectBoxAdmin}<input key={"NTAdmin"} type="text" id="NTAdmin" name="NTAdmin" disabled={true} hidden={true}></input></td>
            </tr>
            <tr>
              <td style={AR}>Roller: </td>
              <td style={AL}>{selectBoxRoles}<input key={"NTRoles"} type="text" id="NTRoles" name="NTRoles" disabled={true} hidden={true}></input></td>
            </tr>
            <tr>
              <td style={AR}>Typ av Instrument: </td>
              <td style={AL}>{selectBoxTradeType}<input key={"NTTradeType"} type="text" id="NTTradeType" name="NTTradeType" disabled={true} hidden={true}></input></td>
            </tr>
            <tr>
              <td style={AR}>Riktning: </td>
              <td style={AL}>
                <label htmlFor="NTLongShortL">Lång</label>
                <input key={"NTLongShortL"} type="radio" id="NTLongShortL" name="NTLongShort" value="long"></input>
                <label htmlFor="NTLongShortS">Kort</label>
                <input key={"NTLongShortS"} type="radio" id="NTLongShortS" name="NTLongShort" value="short"></input>
                &nbsp;
                <label htmlFor="NTCertificate">Certifikat/Warrant</label>
                <input key={"NTCertificate"} type="checkbox" id="NTCertificate" name="NTCertificate" value="true"></input>
              </td>
            </tr>
            <tr>
              <td style={AR}>Hävstång: </td>
              <td style={AL}><input key={"NTLeverage"} type="text" id="NTLeverage" name="NTLeverage" defaultValue={1}></input></td>
            </tr>
            <tr>
              <td style={AR}>EK: </td>
              <td style={AL}><input key={"NTEk"} type="text" id="NTEk" name="NTEk" defaultValue={3}></input></td>
            </tr>
            <tr>
              <td></td>
              <td> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp; Normalt pris &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;   &nbsp;  Underliggande (om applicerbart)</td>
            </tr>
            <tr>
            <td style={AR}>Entry: </td>
              <td style={AL}>
                <input onBlur={(e) => {handleUpdateLevelsInChart(e); calcCertToUnderlying("NTEntry", "NTEntryUnderlying", "NTLeverage", "NTEntry", "NTEntryUnderlying", true, "NTLongShort", "NTCertificate", "NTCertStrikePrice", "NTCertRatio", "NTCertRiskBuffer", "NTCertExchangeRate")}} key={"NTEntry"} type="text" id="NTEntry" name="NTEntry"></input>
                <input onBlur={(e) => {handleUpdateLevelsInChart(e); calcUnderlyingToCert("NTEntry", "NTEntryUnderlying", "NTLeverage", "NTEntry", "NTEntryUnderlying", true, "NTLongShort", "NTCertificate", "NTCertStrikePrice", "NTCertRatio", "NTCertRiskBuffer", "NTCertExchangeRate")}} style={{marginLeft: "2%"}} key={"NTEntryUnderlying"} type="text" id="NTEntryUnderlying" name="NTEntryUnderlying"></input>
                <button onClick={() => {fetchLivePrice()}} style={{ width: "18%", marginLeft: "2%", fontWeight:300}}>Hämta&nbsp;pris</button>
              </td> 
            </tr>
            <tr>
              <td style={AR}>SL: </td>
              <td style={AL}>
                <input onBlur={(e) => {calcGainDiff("NTEntry", "NTSl", "NTSlTheoGain"); handleUpdateLevelsInChart(e); calcCertToUnderlying("NTSl", "NTSlUnderlying", "NTLeverage", "NTEntry", "NTEntryUnderlying", false, "NTLongShort", "NTCertificate", "NTCertStrikePrice", "NTCertRatio", "NTCertRiskBuffer", "NTCertExchangeRate")}} key={"NTSl"} type="text" id="NTSl" name="NTSl"></input>
                <input onBlur={(e) => {handleUpdateLevelsInChart(e); calcUnderlyingToCert("NTSl", "NTSlUnderlying", "NTLeverage", "NTEntry", "NTEntryUnderlying", false, "NTLongShort", "NTCertificate", "NTCertStrikePrice", "NTCertRatio", "NTCertRiskBuffer", "NTCertExchangeRate")}} style={{marginLeft: "2%"}} key={"NTSlUnderlying"} type="text" id="NTSlUnderlying" name="NTSlUnderlying"></input>
                <input style={{cursor: "default", width: "12%", marginLeft: "2%"}} key={"NTSlTheoGain"} type="text" id="NTSlTheoGain" name="NTSlTheoGain" disabled readOnly={true}></input>
              </td>
            </tr>
            <tr>
              <td style={AR}>TP1: </td>
              <td style={AL}>
                <input onBlur={(e) => {calcGainDiff("NTEntry", "NTTp1", "NTTp1TheoGain"); handleUpdateLevelsInChart(e); calcCertToUnderlying("NTTp1", "NTTp1Underlying", "NTLeverage", "NTEntry", "NTEntryUnderlying", false, "NTLongShort", "NTCertificate", "NTCertStrikePrice", "NTCertRatio", "NTCertRiskBuffer", "NTCertExchangeRate")}} key={"NTTp1"} type="text" id="NTTp1" name="NTTp1"></input>
                <input onBlur={(e) => {handleUpdateLevelsInChart(e); calcUnderlyingToCert("NTTp1", "NTTp1Underlying", "NTLeverage", "NTEntry", "NTEntryUnderlying", false, "NTLongShort", "NTCertificate", "NTCertStrikePrice", "NTCertRatio", "NTCertRiskBuffer", "NTCertExchangeRate")}} style={{marginLeft: "2%"}} key={"NTTp1Underlying"} type="text" id="NTTp1Underlying" name="NTTp1Underlying"></input>
                <input style={{cursor: "default", width: "12%", marginLeft: "2%"}} key={"NTTp1TheoGain"} type="text" id="NTTp1TheoGain" name="NTTp1TheoGain" disabled readOnly={true}></input>
              </td>
            </tr>
            <tr>
              <td style={AR}>TP2: </td>
              <td style={AL}>
                <input onBlur={(e) => {calcGainDiff("NTEntry", "NTTp2", "NTTp2TheoGain"); handleUpdateLevelsInChart(e); calcCertToUnderlying("NTTp2", "NTTp2Underlying", "NTLeverage", "NTEntry", "NTEntryUnderlying", false, "NTLongShort", "NTCertificate", "NTCertStrikePrice", "NTCertRatio", "NTCertRiskBuffer", "NTCertExchangeRate")}} key={"NTTp2"} type="text" id="NTTp2" name="NTTp2"></input>
                <input onBlur={(e) => {handleUpdateLevelsInChart(e); calcUnderlyingToCert("NTTp2", "NTTp2Underlying", "NTLeverage", "NTEntry", "NTEntryUnderlying", false, "NTLongShort", "NTCertificate", "NTCertStrikePrice", "NTCertRatio", "NTCertRiskBuffer", "NTCertExchangeRate")}} style={{marginLeft: "2%"}} key={"NTTp2Underlying"} type="text" id="NTTp2Underlying" name="NTTp2Underlying"></input>
                <input style={{cursor: "default", width: "12%", marginLeft: "2%"}} key={"NTTp2TheoGain"} type="text" id="NTTp2TheoGain" name="NTTp2TheoGain" disabled readOnly={true}></input>
              </td>
            </tr>
            <tr>
              <td style={AR}>TP3: </td>
              <td style={AL}>
                <input onBlur={(e) => {calcGainDiff("NTEntry", "NTTp3", "NTTp3TheoGain"); handleUpdateLevelsInChart(e); calcCertToUnderlying("NTTp3", "NTTp3Underlying", "NTLeverage", "NTEntry", "NTEntryUnderlying", false, "NTLongShort", "NTCertificate", "NTCertStrikePrice", "NTCertRatio", "NTCertRiskBuffer", "NTCertExchangeRate")}} key={"NTTp3"} type="text" id="NTTp3" name="NTTp3"></input>
                <input onBlur={(e) => {handleUpdateLevelsInChart(e); calcUnderlyingToCert("NTTp3", "NTTp3Underlying", "NTLeverage", "NTEntry", "NTEntryUnderlying", false, "NTLongShort", "NTCertificate", "NTCertStrikePrice", "NTCertRatio", "NTCertRiskBuffer", "NTCertExchangeRate")}} style={{marginLeft: "2%"}} key={"NTTp3Underlying"} type="text" id="NTTp3Underlying" name="NTTp3Underlying"></input>
                <input style={{cursor: "default", width: "12%", marginLeft: "2%"}} key={"NTTp3TheoGain"} type="text" id="NTTp3TheoGain" name="NTTp3TheoGain" disabled readOnly={true}></input>
              </td>
            </tr>
            <tr>
              <td style={AR}>Tidpunkt: </td>
              <td style={AL}><input key={"NTDate"} style={{width: "195px"}} type="datetime-local" id="NTDate" name="NTDate" required defaultValue={currTime} placeholder={currTime}></input>
              <div className='widener'></div></td>
            </tr>
            <tr>
              <td style={AR}>Meddelande: </td>
              <td style={AL}>{messageTextArea}</td>
            </tr>
            <tr>  
              <td style={{fontWeight:300, textAlign:"right"}}>Kom ihåg att dubbekolla alla siffror</td>
              <td style={AL}>
                <button style={{}} onClick={sendNewTrade}>Öppna trade</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
  setNewTradeGen(result)
  try{ 
    document.getElementById("NTLeverage").value = document.getElementById("NTLeverage").value ? document.getElementById("NTLeverage").value : 1;
    document.getElementById("NTEk").value = document.getElementById("NTEk").value ? document.getElementById("NTEk").value : 10;
    document.getElementById("NTEntry").value = null;
    document.getElementById("NTSl").value = null;
    document.getElementById("NTTp1").value = null;
    document.getElementById("NTTp2").value = null;
    document.getElementById("NTTp3").value = null;
    document.getElementById("NTDate").value = currTime;
    document.getElementById("NTMessage").value = document.getElementById("NTMessage").value ? document.getElementById("NTMessage").value : "Öppnar trade";
  }catch(e){}
  try{
    document.getElementById("NTEntryUnderlying").value = null;
    document.getElementById("NTSlUnderlying").value = null;
    document.getElementById("NTTp1Underlying").value = null;
    document.getElementById("NTTp2Underlying").value = null;
    document.getElementById("NTTp3Underlying").value = null;
  }catch(e){}
}


//  ███    ███  █████  ██ ███    ██ 
//  ████  ████ ██   ██ ██ ████   ██ 
//  ██ ████ ██ ███████ ██ ██ ██  ██ 
//  ██  ██  ██ ██   ██ ██ ██  ██ ██ 
//  ██      ██ ██   ██ ██ ██   ████ 

//MAIN FUNCTION
export default function Overview() {
  document.title = "Redigera trades";

  const cookies = new Cookies();

  const chartContainerRef = useRef();
  const chartRef = useRef();
  const resizeObserverRef = useRef();
  const chartTimeframe = useRef("1d");

  const [openPositions, setOpenPositions] = useState();
  const [closedPositions, setClosedPositions] = useState();

  const [tradePositions, setTradePositions] = useState();
  const [instruments, setInstruments] = useState();
  const [channels, setChannels] = useState();
  const [users, setUsers] = useState();
  const [guilds, setGuilds] = useState();

  const [roles, setRoles] = useState();
  const [admins, setAdmins] = useState();

  const [tokenOk, setTokenOk] = useState();
  
  const [authType, setAuthType] = useState(2);

  const openPositionRef = useRef();
  const closedPositionsRef = useRef();

  const [combinedTradesState, setCombinedTradesState] = useState();

  openPositionRef.current = openPositions;
  closedPositionsRef.current = closedPositions;

  const [FO, setFO] = useState();
  const [tooLongLoadingTime, setTooLongLoadingTime] = useState(false);

  const [hasTranslatedTrades, setHasTranslatedTrades] = useState(false); //1sec delay for effect
   
  const [positionsTable, setPositionsTable] = useState();
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [showStatsMenu, setShowStatsMenu] = useState(false);
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);

  const [filterParams, setFilterParams] = useState({from: "2023-01-01"});
  const wsTradesFilterParams = useRef();
  wsTradesFilterParams.current = filterParams;
  const wsTradesFilterUsers = useRef();
  wsTradesFilterUsers.current = users;
  const exportCsvFilterParams = useRef();
  exportCsvFilterParams.current = filterParams;
  const [loadAdditionalTrades, setLoadAdditionalTrades] = useState(0);
  const [currentlyLoadingMoreTrades, setCurrentlyLoadingMoreTrades] = useState(false);


  const [selectedTrade, setSelectedTrade] = useState();
  const [newTradeGen, setNewTradeGen] = useState();
  const [newTradeGraphTicker, setNewTradeGraphTicker] = useState();
  const [newTradeGraphEntry, setNewTradeGraphEntry] = useState();
  const [newTradeGraphSl, setNewTradeGraphSl] = useState();
  const [newTradeGraphTp1, setNewTradeGraphTp1] = useState();
  const [newTradeGraphTp2, setNewTradeGraphTp2] = useState();
  const [newTradeGraphTp3, setNewTradeGraphTp3] = useState();

  const activeDbC = React.useContext(ActiveDbContext);
  const loginAuthC = React.useContext(LoginAuthContext);
  const styleC = React.useContext(StyleContext);

  const [showLoginScreen, setShowLoginScreen] = useState(true);
  const [loggedInLoading, setLoggedInLoading] = useState(false);
  
  useEffect(() => {
    //console.log(styleC)
		document.body.style.backgroundColor = styleC.style.bgColor;
  }, [styleC])

useEffect(() => {
  if(g_access_token !== ""){
    tryTokenOk(g_access_token, setTokenOk, setAuthType, 5000);
    //console.log(authType)
  }
}, [g_access_token])
  
useEffect(() => {
  if(g_access_token !== ""){
    tryTokenOk(g_access_token, setTokenOk, setAuthType, 1000);
    //console.log(authType)
  }
}, [activeDb, activeDbC])

useEffect(() => {
  if(tokenOk === 2){
    //console.log("Token ok")
    setShowLoginScreen(false)
    setLoggedInLoading(false)
  } else if(tokenOk === 1){
    //console.log("Token not ok")
    setShowLoginScreen(true)
    cookies.remove("access_token")
    cookies.remove("refresh_token")
    cookies.remove("activeDb")
    window.location.replace(THIS_URL);
  } else if(tokenOk === 0){

  }
}, [tokenOk])

useEffect(() => {
  if(!cookies.get("default_author_index")){
    console.log("no default author index set, setting to 0")
    cookies.set("default_author_index", 0, {
      expires: new Date("2038-01-19")
    })
  }
}, [])

useEffect(() => {
  const actDbCookieVal = cookies.get("activeDb")
  //console.log(actDbCookieVal)
  if(actDbCookieVal){
    activeDb = actDbCookieVal;
  }
}, [])

  useEffect(() => {
    if(g_access_token === ""){
      //console.log("checking for cookies")
      try{ //Kolla först om det finns en cookie med auth token
        const access_token = cookies.get("access_token")
        //console.log(access_token)
        if(access_token){
          //console.log("token found")
          setLoggedInLoading(true)
          //loginAuthC.setAuth(access_token)
          g_access_token = access_token;
        } else{
          //Om det inte finns en auth token, kolla om det finns "code" i URLSearchParams
          const URLAuthCode = (new URLSearchParams(window.location.search)).get("code");
          if(URLAuthCode){
            //console.log(URLAuthCode)
            try{
              getAuthToken(URLAuthCode, cookies);
            }catch(e){
              console.error("AUTH TOKEN ERROR")
            }
          } else{
            //Annars visa login skärmen med en knapp där de kan få en code
            //setShowLoginScreen(true);
            console.log("Show loginscreen")
          }
        }
      }catch(e){
        console.log(e)
      }    
    } else{

    }
  }, [])

  const login = () => {
    const discordOauthLink = DISCORD_OAUTH_URL;
    if(discordOauthLink){
      window.location.replace(discordOauthLink);
    } else{
      console.error("MISSING OAUTH LINK")
    }

  }

  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      input => (input.value = "")
    );
    setShowStatsMenu(false)
    setShowSettingsMenu(false)
  };

  const loadMoreTrades = () => {
    setCurrentlyLoadingMoreTrades(true);
    if(loadAdditionalTrades == 0){
      setLoadAdditionalTrades(1);
    } else if(loadAdditionalTrades == 1){
      setLoadAdditionalTrades(2);
    } else if(loadAdditionalTrades == 2){
      setLoadAdditionalTrades(3);
    }
  }

  useEffect(() => {
    chartTimeframe.current = "1d"
  }, [])



  useEffect(() => {
    if(activeDbC){
      activeDb = activeDbC;
      console.log("Setting activeDb to URL db from dbcontext")
      //console.log(activeDb)
    }
    //console.log("Asking for API data with filter:")
    //console.log(filterParams)
    var finalFilterParams = filterParams;
    finalFilterParams.limit = defaultFetchLimit;
    finalFilterParams.sort = defaultFetchSort;
    if(loadAdditionalTrades == 1){
      finalFilterParams.limit = defaultFetchLimit * 10;
    } else if(loadAdditionalTrades == 2){
      finalFilterParams.limit = defaultFetchLimit * 100;
    } else if(loadAdditionalTrades == 3){
      finalFilterParams.limit = defaultFetchLimit * 9999;
    }
    getTradePositions(setTradePositions, finalFilterParams);
    getChannels(setChannels);
    getUsers(setUsers);
    getRoles(setRoles);
    getAdmins(setAdmins);
    getGuilds(setGuilds);
    wsTradesFilterParams.current = filterParams;
    exportCsvFilterParams.current = finalFilterParams;
  }, [activeDbC, activeDb, filterParams, loadAdditionalTrades])

  useEffect(() => {
    wsTradesFilterUsers.current = users;
  }, [users])

  useEffect(() => {
    var openTmp = [];
    var closedTmp = [];
    for(var a in tradePositions){
      if(tradePositions[a].exit){
        closedTmp.push(tradePositions[a])
      } else{
        openTmp.push(tradePositions[a])
      }
    }
    setOpenPositions(openTmp)
    setClosedPositions(closedTmp)
    /*
    getUsers(setUsers);
    getRoles(setRoles);
    getAdmins(setAdmins);
    getGuilds(setGuilds);
    */
  }, [tradePositions])

  useEffect(() => {
    console.log("Listening on ws")
    socket.on("trade", (data) => {
      console.log("Received data: ");
      console.dir(data);
      if(data.db) {
        if(data.db != activeDb){
          console.error("Wrong DB")
        } else {


          console.log("wsTradesFilterParams.current")
          console.log(wsTradesFilterParams.current)
          //Om traden inte passar in i filtret ska den tas bort redan nu.
          var tradeWasFiltered = false
          for (const [filterKey, filterValue] of Object.entries(wsTradesFilterParams.current)) {
            console.log("key " + filterKey + ": value: " + filterValue);
            switch (filterKey) {
              case "ticker":
                if(!data.trade["ticker"].includes(filterValue)){
                  console.log("Filter caught on ticker")
                  tradeWasFiltered = true
                }
                break;
              case "exit":
                if(data.trade["exit"] && filterValue == "!exists"){
                  console.log("Filter caught on exit")
                  tradeWasFiltered = true
                }
                break;
              case "user_id":
                var usersNames = [];
                for(var t in wsTradesFilterUsers.current){
                  console.log("Checking if this contains this")
                  console.log(filterValue)
                  console.log(wsTradesFilterUsers.current[t].id)
                  console.log("users: ")
                  console.log(wsTradesFilterUsers.current)
                  if(filterValue.includes(wsTradesFilterUsers.current[t].id)){
                    usersNames.push(wsTradesFilterUsers.current[t].name);
                  }
                }
                console.log("All users here: ")
                console.log(usersNames)
                var userFound = false;
                for(var t in usersNames){
                  if(usersNames.includes(data.trade["user"])){
                    userFound = true;
                  }
                  if(data.trade.user && data.trade.user.name){
                    if(usersNames.includes(data.trade.user.name)){
                      userFound = true;
                    }
                  }
                }
                if(!userFound){
                  console.log("Filter caught on user")
                  tradeWasFiltered = true
                }
                break;
              case "direction":
                if(data.trade["direction"] !== filterValue){
                  console.log("Filter caught on direction")
                  tradeWasFiltered = true
                }
                if(data.trade["certificate"] == true){
                  tradeWasFiltered = false //Eftersom cert kan vara oförutsägbara så ignorera direction på cert
                }
                break;
              default:
                break;
            }
          }

          if(tradeWasFiltered){
            console.log("trade was filtered, should not be added")
          } else{
            console.log("trade passed filter, now the rest")
            if(data.trade.deleted === true){
              var tempOpen = openPositionRef.current.filter(a => a._id !== data.trade._id)
              var tempClosed = closedPositionsRef.current.filter(a => a._id !== data.trade._id)
              setOpenPositions(tempOpen);
              setClosedPositions(tempClosed);
            } else {
              var tradeFoundInOpenIdx = openPositionRef.current.findIndex(a => a._id == data.trade._id)
              var tradeFoundInClosedIdx = closedPositionsRef.current.findIndex(a => a._id == data.trade._id)
              
              var tradeIdx; //detta är alltså potentiellt den trade vi har i vår lokala data just nu
              var inOpen;
              var trade;
  
              if(tradeFoundInOpenIdx != -1 && tradeFoundInClosedIdx == -1){
                tradeIdx = tradeFoundInOpenIdx
                inOpen = true;
                trade = openPositionRef.current[tradeIdx]
                setFO(Date.now());
              } else if(tradeFoundInOpenIdx == -1 && tradeFoundInClosedIdx != -1){
                tradeIdx = tradeFoundInClosedIdx
                inOpen = false;
                trade = closedPositionsRef.current[tradeIdx]
                setFO(Date.now());
              } else{
              }
  
              //console.log("dags att uppdatera följande trade, alt. om det inte finns något så ska en ny trade läggas till...")
              //console.log(trade)
  
              if(trade == undefined){
                if(data.trade.entry && !data.trade.exit){
                  //console.log("Data only had entry, appending to openPositions: ");
                  setOpenPositions([...openPositionRef.current, data.trade]);
                } else if(data.trade.entry && data.trade.exit){
                  //console.log("Data had entry AND exit, appending to closedPositions: ");
                  setClosedPositions([...closedPositionsRef.current, data.trade]);
                } else {
                  console.error("Data not handled")
                }
              } else {
                console.log("Updaterar trade...")
                if(inOpen){
                  var tmp = openPositionRef.current;
                  tmp[tradeIdx] = data.trade;
                  //console.log("uppdaterat nu så att tmp borde ha rätt trade på index: ", tradeIdx)
                  //console.log(tmp)
                  setOpenPositions(tmp)
                } else {
                  var tmp = closedPositionsRef.current;
                  tmp[tradeIdx] = data.trade;
                  //console.log("uppdaterat nu så att tmp borde ha rätt trade på index: ", tradeIdx)
                  //console.log(tmp)
                  setClosedPositions(tmp)
                }
              }
            }
          }
        }
      } else {
        console.error("Data received in wrong format.")
      }
    })
  }, [socket])

  useEffect(() => {
    if(openPositions && closedPositions && authType != 2){
        let allPositions = openPositions.concat(closedPositions);
        allPositions.sort((a,b) => (a.entry.msg.id < b.entry.msg.id) ? 1 : -1);
        console.log("allPositions")
        console.log(allPositions)

        //allPositions = allPositions.filter((x) => x.ts > 1673549606393);

        let tickers = []
        let entryIds = new Set();
        for(var a in allPositions){
          tickers.push({'ticker': replaceBB(removeParenthesis(allPositions[a].ticker)), 'name': allPositions[a].ticker, 'id': a});
          entryIds.add(allPositions[a].entry.msg.id)
        }
        const tickersUniq = [...new Map(tickers.map(v => [v.ticker, v])).values()]
        setInstruments(tickersUniq);

        let completeTrades = []
        entryIds = Array.from(entryIds)
        for(var a in entryIds){
          let filtered = allPositions.filter((item) => item.entry.msg.id == entryIds[a])
          allPositions = allPositions.filter((x) => x.entry.msg.id != entryIds[a])
          completeTrades.push(filtered)
        }

        var combinedTrades = []
        for(var a in completeTrades){
          var made = false;
          for(var b in completeTrades[a]){
            if(!completeTrades[a][b].exit){
              combinedTrades.push({...completeTrades[a][b], exits: completeTrades[a].filter((x) => x._id != completeTrades[a][b]._id)})
              //Här bör vi summera EK för att se att vi har ALLA tillhörande trades, och annars ladda ned via API med /getTrade/
              made = true;
              break; //Det bör bara högst finnas 1 trade utan exit, så när vi hittar den kan vi skippa att loopa genom resten.
            }
          }
          if(!made){ //Om vi inte hittade någon trade utan exit så tar vi o antar att ALLA har exit, dvs hela traden är helt stängd
            completeTrades[a].sort((a,b) => (a.ts < b.ts) ? 1 : -1)
            combinedTrades.push({...completeTrades[a][0], exits: completeTrades[a]})
          }
        }

        /*
        var minEk = 99999;
        var maxEk = 0;
        for(let a in combinedTrades){
          if(combinedTrades[a].ek < minEk){
            minEk = combinedTrades[a].ek
          }
          if(combinedTrades[a].ek > maxEk){
            maxEk = combinedTrades[a].ek
          }
        }

        console.log("minEk")
        console.log(minEk)
        console.log("maxEk")
        console.log(maxEk)

        var numBuckets = 20;
        var bucketsize = maxEk/numBuckets;
        var buckets = Array(numBuckets+1).fill(0);

        for(let a in combinedTrades){
          var rem = Math.floor(combinedTrades[a].ek/bucketsize);
          buckets[rem] += 1;
        }

        console.log("numBuckets")
        console.log(numBuckets)
        console.log("bucketsize")
        console.log(bucketsize)
        console.log("buckets")
        console.log(buckets)*/

        combinedTrades.sort((a,b) => (a.ts < b.ts) ? 1 : -1);

        console.log("combinedTrades")
        console.log(combinedTrades)
        
        const table = combinedTrades.map((a) => (
          <CollapsibleTableRow key={a._id} data={a} exits={a.exits}
           handleReset={handleReset} 
           genChangeInfo={genChangeInfo} 
           genMakeExit={genMakeExit} 
           genMoveLevels={genMoveLevels}
           setSelectedTrade={setSelectedTrade} 
           setNewTradeGen={setNewTradeGen} 
           setNewTradeGraphTicker={setNewTradeGraphTicker}
           chartContainerRef={chartContainerRef}
           chartRef={chartRef}
           resizeObserverRef={resizeObserverRef}
           activeDb={activeDb}
           authType={authType}
           />
        ));
        
        setCombinedTradesState(combinedTrades);
        setPositionsTable(table);
    }   
  }, [openPositions, closedPositions, FO, authType])

  useEffect(() => {
    setTimeout(() => {
      setHasTranslatedTrades(true) //1sec delay for effect
      setCurrentlyLoadingMoreTrades(false)
    }, 1000)
  }, [positionsTable])

  useEffect(() => {
    setTimeout(() => {
      setTooLongLoadingTime(true)
      console.log("Too long loading time...")
    }, 30000)
  }, [])

  useEffect(() => {
    try{
      if(document.getElementsByClassName("tv-lightweight-charts").length > 0){
        document.getElementsByClassName("tv-lightweight-charts")[0].remove();
        document.getElementsByClassName("chart-legend")[0].remove();
      }
    }catch(e){
      console.log(e)
    }
  }, [])

  //{generalLoading ? {loadingSpinner} : null}

  const handleSelectChangeGuild = (values) => {
    console.log(values)
    cookies.set("activeDb", values[0].id, {
      expires: new Date("2038-01-19")
    })
    activeDb = values[0].id;
    window.location.replace(THIS_URL);
  }

  //console.log(guilds ? guilds.length > 1 : false)

  return (
    <main>
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <div className="full-container" style={{backgroundColor: styleC.style.bgColor}}> 
            {loggedInLoading ?
            <div style={{width:"50%", height:"50%", position:"absolute", top:"0", left:"0", bottom:"0", right:"0", margin:"auto", padding:"1%", color:"white"}}>
              <img src={logo} style={{width:"50%", height:"50%", position:"absolute", top:"0", left:"0", bottom:"0", right:"0", margin:"auto", padding:"1%", color:"white"}}></img>
              <img src={loadingSpinner} className='rotate'></img>
              {
                (loggedInLoading && tooLongLoadingTime) ? 
                  <div style={{bottom: "-130px", textAlign: "center", position: "absolute", color: "#759bd4", width: "100%"}}>
                    <h5>Om det har laddat väldigt länge (30+ sekunder) testa att refresha sidan. Om det fortsätter att ladda väldigt länge då så kontakta admin.</h5>
                  </div>
                  :
                  null
              }
            </div>
            : 
            (new URLSearchParams(window.location.search)).get("code") ? 
              <div style={{width:"50%", height:"50%", position:"absolute", top:"0", left:"0", bottom:"0", right:"0", margin:"auto", padding:"1%", color:"white"}}>
                <img src={logoLoading} style={{width:"50%", height:"50%", position:"absolute", top:"0", left:"0", bottom:"0", right:"0", margin:"auto", padding:"1%", color:"white"}}></img>
              </div>
            :
            showLoginScreen ?
            <div style={{width: "30%", display: "block", margin: "auto"}}>
              <br></br>
              <button onClick={() => {login()}}>Logga in via Discord</button>
            </div> 
            :
            positionsTable && hasTranslatedTrades && !loggedInLoading ? 
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <h2 style={{cursor:"default", display:"inline-block", marginLeft:"1%", color: styleC.style.trimColor}}>{positionsTable.length} st. trades visas</h2>
                    {guilds ? guilds.length > 1 ? 
                      <div style={{display:"inline-block", width:"20%", fontWeight:"400", marginLeft:"5%"}}>
                        <Select 
                          options={guilds} 
                          onChange={(values) => handleSelectChangeGuild(values)} 
                          placeholder="Databas"
                          searchable={true}
                          create={true}
                          valueField="id"
                          labelField="name"
                          multi={false}
                          className={"selectBoxDropdown"}
                        /> 
                      </div>
                      : null : null}
                    <Tooltip title="Visa statistik över de trades i listan nedan">
                      <h2 style={{cursor: "pointer", display:"inline-block", float:"right", width:"9%", fontWeight:"400", marginRight:"1%"}}><button onClick={() => {setShowStatsMenu(true); setSelectedTrade(null); setNewTradeGen(null); setShowSettingsMenu(false)}}>Statistik</button></h2>
                    </Tooltip>
                    <Tooltip title="Filtrera vilka trades som visas i listan nedan" enterDelay={600} leaveDelay={50} disableInteractive>
                      <h2 style={{cursor: "pointer", display:"inline-block", float:"right", width:"8%", fontWeight:"400", marginRight:"1%"}}><button onClick={() => setShowFilterMenu(!showFilterMenu)}>Filter</button></h2>
                    </Tooltip>
                    <Tooltip title="Exportera de listade tradesen till .xlsx">
                      <h2 style={{cursor: "pointer", display:"inline-block", float:"right", width:"10%", fontWeight:"400", marginRight:"1%"}}><button onClick={() => {exportToSpreadsheet(exportCsvFilterParams.current)}}>Exportera</button></h2>
                    </Tooltip>
                    <Tooltip title="Ändra inställningar">
                      <h2 style={{cursor: "pointer", display:"inline-block", float:"right", width:"8%", fontWeight:"400", marginRight:"1%"}}><button onClick={() => {setShowStatsMenu(false); setSelectedTrade(null); setNewTradeGen(null); setShowSettingsMenu(true)}}>Settings</button></h2>
                    </Tooltip>
                    <hr></hr>
                    {showFilterMenu ? <FilterMenu setFilterParams={setFilterParams} instruments={instruments} channels={channels} users={users} cookies={cookies} setLoadAdditionalTrades={setLoadAdditionalTrades}/> : null}
                      <TableContainer>
                        <Table style={{borderBottom: "1px", borderColor: styleC.style.trimColor}}>
                          <TableHead>
                              <TableRow>
                                  <th><span style={{cursor:"default"}}>Trade</span> 
                                  {authType == 0 ?
                                    <div>
                                      <Tooltip title="Öppna en helt ny trade" enterDelay={600} leaveDelay={50} disableInteractive>
                                        <button style={{ width: "50%"}} onClick={() => {handleReset(); genOpenNewTrade(styleC, setNewTradeGen, instruments, channels, admins, roles, setNewTradeGraphTicker, setNewTradeGraphEntry, setNewTradeGraphSl, setNewTradeGraphTp1, setNewTradeGraphTp2, setNewTradeGraphTp3, chartTimeframe, chartContainerRef, chartRef, resizeObserverRef, cookies); setSelectedTrade(null);}}>Öppna ny trade</button>
                                      </Tooltip>
                                    </div>
                                    :
                                    null
                                  }
                                  </th>
                                  <Tooltip title="Hovra över ett datum för att se exakt klockslag" disableInteractive>
                                    <th style={{cursor:"help"}}>Datum</th>
                                  </Tooltip>
                                  {authType == 0 ?
                                  <>
                                    <Tooltip title="Redigera entry, exit, SL, etc. om du råkat skriva fel" enterDelay={10} leaveDelay={50} disableInteractive>
                                      <th style={{cursor:"help"}}>Ändra info</th>
                                    </Tooltip>
                                    <Tooltip title="Gör avslut och delavslut" enterDelay={10} leaveDelay={50}>
                                      <th style={{cursor:"help"}}>Gör avslut</th>
                                    </Tooltip>
                                    <Tooltip title="Flytta/lägg till SL och TP eller fyll på traden med mer kapital" enterDelay={10} leaveDelay={50} disableInteractive>
                                      <th style={{width: "10%", cursor:"help"}}>Money manage</th>
                                    </Tooltip>
                                  </>
                                    :
                                    null
                                  }
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {positionsTable ? positionsTable : null}
                          </TableBody>
                        </Table>
                        {loadAdditionalTrades != 3 && !currentlyLoadingMoreTrades ? 
                        <button onClick={() => loadMoreTrades()} style={{ padding: "5px", fontSize:"1em", display:"inline-block", width:"25%", marginTop: "5px", marginBottom: "5px"}}>Ladda fler trades...</button>
                        :
                        currentlyLoadingMoreTrades ? 
                        <span style={{padding: "5px", fontSize:"1em", display:"inline-block", width:"25%", marginTop: "5px", marginBottom: "5px"}}>Laddar...</span>
                        :
                        <span style={{padding: "5px", fontSize:"1em", display:"inline-block", width:"25%", marginTop: "5px", marginBottom: "5px"}}>Alla trades är laddade</span>
                        }
                      </TableContainer>
                </Grid>
                <Grid item xs={6}>
                    {newTradeGen ? newTradeGen : selectedTrade ? selectedTrade : null}
                    {(newTradeGraphTicker && newTradeGen) ? <CandleChart cContRef={chartContainerRef} cRef={chartRef} resObsRef={resizeObserverRef} timef={chartTimeframe} information={[newTradeGraphTicker, newTradeGraphEntry, newTradeGraphSl, newTradeGraphTp1, newTradeGraphTp2, newTradeGraphTp3]}/> : null}
                    {showStatsMenu ? <StatsMenu currentTrades={combinedTradesState} setShowStatsMenu={setShowStatsMenu}/> : null}
                    {showSettingsMenu ? <SettingsMenu currentTrades={combinedTradesState} setShowSettingsMenu={setShowSettingsMenu}/> : null}
                </Grid>
            </Grid>
            :
              <div style={{width:"50%", height:"50%", position:"absolute", top:"0", left:"0", bottom:"0", right:"0", margin:"auto", padding:"1%", color:"white"}}>
                <img src={logo} style={{width:"50%", height:"50%", position:"absolute", top:"0", left:"0", bottom:"0", right:"0", margin:"auto", padding:"1%", color:"white"}}></img>
              </div>
          }</div>
        </Box>
      </div>
    </main>
  );
}
