import '../App.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-dropdown-select';
import { ActiveDbContext, LoginAuthContext, StyleContext } from "../App";
import lookupInstrument from "../components/Lookup.js"
import CandleChart from '../components/CandleChart';
import CollapsibleTableRow from '../components/CollapsibleTableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import io from 'socket.io-client'
import logo from '../logoLoading.svg'
import loadingSpinner from '../loadingSpinner.svg'
import logoLoading from '../logoLogin.svg'
import FilterMenu from '../components/FilterMenu';
import StatsMenu from '../components/StatsMenu';
import FullScreenChart from '../components/FullScreenChart';
import SettingsMenu from '../components/SettingsMenu';
import Cookies from 'universal-cookie';
import jwtE from 'jwt-encode';
import jwtD from 'jwt-decode';
import { ACTIVE_DB, WEBSOCKET_URL, IO_URL, DISCORD_OAUTH_URL, PRE_ROLES, TRADE_TYPES, THIS_URL } from '../components/envVars';
import { calcCertToUnderlying, calcGainDiff, calcUnderlyingToCert, roundToSignificant, colorAdjust } from '../components/helpFunc';

const socket = io(WEBSOCKET_URL);
const regexRemoveParenthesis = /((CASH).*)|((\(([^)]+)\).*))|\(\d+\)|(SHORT)|(LONG)/;
var g_access_token = "";

function removeParenthesis(a) {
  a = a.replace(regexRemoveParenthesis, ' ');
  a = a.trim();
  return a;
}

function replaceBB(a) {
  var ret = "";
	switch(a.split(' ')[0]){
		case "BULL": ret = a.slice(5); break;
		case "BEAR": ret = a.slice(5); break;
		default: ret = a; break;
	}
	return ret;
}

function replaceBBwithArrows(a, bb) {
  var ret = "";
	switch(a.split(' ')[0]){
		case "BULL": ret = a.slice(5); break;
		case "BEAR": ret = a.slice(5); break;
    case "▼": return a;
    case "▲": return a;
		default: ret = a; break;
	}
  ret = bb ? "▼ "+ret : "▲ "+ret;
	return ret;
}

const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

var activeDb = ACTIVE_DB; //default till vår testdatabas
//var activeDb = "759569532221653053" //default till AMO 

const defaultFetchLimit = 10;
const defaultFetchSort = ">ts";

//var activeDb = "871087817948798996" //Testkanalen



//   █████  ██████  ██ 
//  ██   ██ ██   ██ ██ 
//  ███████ ██████  ██ 
//  ██   ██ ██      ██ 
//  ██   ██ ██      ██ 

async function getAuthToken(URLAuthCode, cookies){
	try{
	  const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json'},
		body: JSON.stringify({
		  code: URLAuthCode,
		  redirect: THIS_URL,
		})
	  };
	
	  console.log(requestOptions)
	  const response = await fetch(IO_URL+'/auth/', requestOptions);
	  if(response.status !== 200){
		console.error("Auth error: ", response)
		return;
	  }
	  const rec = await response.json();
	  console.log(rec);
	
	/*
	  cookies.set("access_token", rec.tokens.access_token, {
		expires: new Date(rec.tokens.expire)
	  })
  
	  cookies.set("refresh_token", rec.tokens.refresh_token, {
		expires: new Date(rec.tokens.expire)
	  })
  */
	  cookies.set("access_token", rec.token, {
		expires: new Date("2038-01-19")
	  })
  
	  cookies.set("refresh_token", rec.token, {
		expires: new Date("2038-01-19")
	  })
	  window.location.replace(THIS_URL);
	  
	}catch(e){
	  console.error(e)
	}
  }
  
  function tryTokenTimeout(timeoutDelay){
	return new Promise(resolve => setTimeout(resolve, timeoutDelay))
  }
  
  async function tryTokenOk(access_token, setTokenOk, setAuthType, timeoutDelay){
	await tryTokenTimeout(timeoutDelay);
	//console.log("in tryTokenOk, activeDb: ")
	//console.log(activeDb)
	try{
	  const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + access_token},
		body: JSON.stringify({
		  db: activeDb,
		})
	  };
	
	  //console.log(requestOptions)
	  const response = await fetch(IO_URL+'/getOperator/', requestOptions);
	  
	  if(response.status == 200){
		//console.log(response.statusText);
		setTokenOk(2)
		const res = await response.json();
  
		var isAdmin = false;
		for(var a in res.guilds){
		  if(res.guilds[a].id == activeDb){
			if(res.guilds[a].access == "admin"){
			  isAdmin = true;
			  setAuthType(0)                         //authType = 0: admin, 1: user, 2: unitialized
			}                                        //tokenOk = 2: ok, 1: not ok
		  }
		}
		if(!isAdmin){
		  setAuthType(1)
		}
	  } else{
		//console.log(response.statusText);
		setTokenOk(1)
		setAuthType(1)
	  }
	}
	catch(e){
	  console.log(e)
	  setTokenOk(1)
	  setAuthType(1)
	}
  }
  
  async function getGuilds(setGuilds){
	//console.log("in getGuilds, activeDb: ")
	//console.log(activeDb)
	if(g_access_token === ""){
	  return;
	}
	try{
	  const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
		body: JSON.stringify({
		})
	  };
	  const response = await fetch(IO_URL+'/getOperator/', requestOptions);
	  var rec = await response.json();
	  const recGuilds = rec.guilds;
	  //console.log("recGuilds")
	  //console.log(recGuilds)
	  setGuilds(recGuilds)
	  var defaultAvailable = false;
	  //console.log("in getGuilds, checking if selected db is available: ")
	  for(var a in recGuilds){
		//console.log(recGuilds[a].id)
		if(recGuilds[a].id == activeDb){
		  defaultAvailable = true;
		}
	  }
	  if(!defaultAvailable){
		//console.log("none found, setting activeDb to");
		//console.log(recGuilds[a].id ?? 0);
		activeDb = recGuilds[a].id ?? 0;
		//console.log("activeDb is now: ");
		//console.log(activeDb);
	  }
	}catch(e){
	  console.log(e)
	}
  }
  
  //GET ALL POSITIONS
  async function getTradePositions(setTradePositions, filterParams){
	if(g_access_token === ""){
	  return;
	}
	try{
	  const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
		body: JSON.stringify({
		  db: activeDb,
		  ...filterParams,
		})
	  };
	
	  //console.log(requestOptions)
	  const response = await fetch(IO_URL+'/getTrades/', requestOptions);
	  if(response.statusText === "Method Not Allowed"){
		//console.log(response)
		setTradePositions({})
		return;
	  }
	  const rec = await response.json();
	  
	  //console.log("First trade fetch rec: ")
	  //console.log(rec)  
  
	  setTradePositions(rec)
	  //console.log(rec)
	}
	catch(e){
	  console.warn("ERROR FETCHING TRADES: ")
	  console.log(e)
	}
  }
  
  //GET CHANNELS
  async function getChannels(setChannels) {
	if(g_access_token === ""){
	  return;
	}
	try{
	  const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
		body: JSON.stringify({
		  db: activeDb,
		})
	  };
	
	  //console.log(requestOptions)
	  const response = await fetch(IO_URL+'/getChannels/', requestOptions);
	  const rec = await response.json();
	  setChannels(rec)
	  //console.log(rec)
	}
	catch(e){
	  console.log(e)
	}
  }
  
  //GET USERS
  async function getUsers(setUsers) {
	if(g_access_token === ""){
	  return;
	}
	try{
	  const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
		body: JSON.stringify({
		  db: activeDb,
		})
	  };
	
	  //console.log(requestOptions)
	  const response = await fetch(IO_URL+'/getAdmins/', requestOptions);
	  const rec = await response.json();
	  setUsers(rec)
	  //console.log(rec)
	}
	catch(e){
	  console.log(e)
	}
  }
  
  //GET ADMINS
  async function getAdmins(setAdmins) {
	if(g_access_token === ""){
	  return;
	}
	try{
	  const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
		body: JSON.stringify({
		  db: activeDb,
		})
	  };
	
	  //console.log(requestOptions)
	  const response = await fetch(IO_URL+'/getAdmins/', requestOptions);
	  const rec = await response.json();
	  setAdmins(rec)
	  //console.log(rec)
	}
	catch(e){
	  console.log(e)
	}
  }
  
  //GET ROLES
  async function getRoles(setRoles) {
	if(g_access_token === ""){
	  return;
	}
	try{
	  const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
		body: JSON.stringify({
		  db: activeDb,
		})
	  };
	
	  //console.log(requestOptions)
	  const response = await fetch(IO_URL+'/getRoles/', requestOptions);
	  const rec = await response.json();
	  setRoles(rec)
	  //console.log(rec)
	}
	catch(e){
	  console.log(e)
	}
  }



//  ███    ███  █████  ██ ███    ██ 
//  ████  ████ ██   ██ ██ ████   ██ 
//  ██ ████ ██ ███████ ██ ██ ██  ██ 
//  ██  ██  ██ ██   ██ ██ ██  ██ ██ 
//  ██      ██ ██   ██ ██ ██   ████ 

export default function Equity() {
	document.title = "Fullscreen equity";
  
	const cookies = new Cookies();
  
	const chartContainerRef = useRef();
	const chartRef = useRef();
	const resizeObserverRef = useRef();
	const chartTimeframe = useRef("1d");
  
	const [openPositions, setOpenPositions] = useState();
	const [closedPositions, setClosedPositions] = useState();
  
	const [tradePositions, setTradePositions] = useState();
	const [instruments, setInstruments] = useState();
	const [channels, setChannels] = useState();
	const [users, setUsers] = useState();
	const [guilds, setGuilds] = useState();
  
	const [roles, setRoles] = useState();
	const [admins, setAdmins] = useState();
  
	const [tokenOk, setTokenOk] = useState();
	
	const [authType, setAuthType] = useState(2);
  
	const openPositionRef = useRef();
	const closedPositionsRef = useRef();
  
	const [combinedTradesState, setCombinedTradesState] = useState();
  
	openPositionRef.current = openPositions;
	closedPositionsRef.current = closedPositions;
  
	const [FO, setFO] = useState();
  
	const [hasTranslatedTrades, setHasTranslatedTrades] = useState(false); //1sec delay for effect
	 
	const [positionsTable, setPositionsTable] = useState();
	const [showStatsMenu, setShowStatsMenu] = useState(true);
  
	const [filterParams, setFilterParams] = useState({from: "2023-01-01"});
	const wsTradesFilterParams = useRef();
	wsTradesFilterParams.current = filterParams;
	const wsTradesFilterUsers = useRef();
	wsTradesFilterUsers.current = users;
	const exportCsvFilterParams = useRef();
	exportCsvFilterParams.current = filterParams;
	const [loadAdditionalTrades, setLoadAdditionalTrades] = useState(0);
	const [currentlyLoadingMoreTrades, setCurrentlyLoadingMoreTrades] = useState(false);
  
  
	const [selectedTrade, setSelectedTrade] = useState();
	const [newTradeGen, setNewTradeGen] = useState();
	const [newTradeGraphTicker, setNewTradeGraphTicker] = useState();
  
	const activeDbC = React.useContext(ActiveDbContext);
	const loginAuthC = React.useContext(LoginAuthContext);
	const styleC = React.useContext(StyleContext);
  
	const [showLoginScreen, setShowLoginScreen] = useState(true);
	const [loggedInLoading, setLoggedInLoading] = useState(false);
  
	const [bgCol, setBgCol] = useState(styleC.style.bgColor);
	const [trimCol, setTrimCol] = useState(styleC.style.trimColor);
	const [bgGraphCol, setBgGraphCol] = useState(styleC.style.bgGraph);
	const [lineGraphCol, setLineGraphCol] = useState(styleC.style.lineGraph);

	useEffect(() => {
		const newStyle = styleC.style;

		const BD = colorAdjust(bgCol, -2);
		const D = colorAdjust(bgCol, -7);
		const BL = colorAdjust(bgCol, 4);
		const L = colorAdjust(bgCol, 17);

		newStyle.bgColor = bgCol;
		newStyle.bgBarelyDarker = BD;
		newStyle.bgBarelyLighter = BL;
		newStyle.bgDarker = D;
		newStyle.bgLighter = L;
		document.documentElement.style.setProperty('--bgColorMain', bgCol);
		document.documentElement.style.setProperty('--bgColorDarker', D);
		document.documentElement.style.setProperty('--bgColorLighter', L);
		document.documentElement.style.setProperty('--bgColorBarelyDarker', BD);
		document.documentElement.style.setProperty('--bgColorBarelyLighter', BL);
  		styleC.setStyle(newStyle);
	}, [bgCol])
	useEffect(() => {
		const newStyle = styleC.style;
		
		const D = colorAdjust(trimCol, -7);
		const L = colorAdjust(trimCol, 7);

		newStyle.trimColor = trimCol;
		newStyle.trimDarker = D;
		newStyle.trimLighter = L;
		document.documentElement.style.setProperty('--trimColorMain', trimCol);
		document.documentElement.style.setProperty('--trimColorDarker', D);
		document.documentElement.style.setProperty('--trimColorLighter', L);
  		styleC.setStyle(newStyle);
	}, [trimCol])
	useEffect(() => {
		const newStyle = styleC.style;
		
		const D = colorAdjust(bgGraphCol, -7);
		const L = colorAdjust(bgGraphCol, 7);

		newStyle.bgGraph = bgGraphCol;
		newStyle.bgGraphDarker = D;
		newStyle.bgGraphLighter = L;
		document.documentElement.style.setProperty('--bgColorGraphMain', bgGraphCol);
		document.documentElement.style.setProperty('--bgColorGraphDarker', D);
		document.documentElement.style.setProperty('--bgColorGraphLighter', L);
  		styleC.setStyle(newStyle);
	}, [bgGraphCol])
	useEffect(() => {
		const newStyle = styleC.style;
		
		const D = colorAdjust(lineGraphCol, -7);
		const L = colorAdjust(lineGraphCol, 7);

		newStyle.lineGraph = lineGraphCol;
		newStyle.lineGraphDarker = D;
		newStyle.lineGraphLighter = L;
		document.documentElement.style.setProperty('--trimColorGraphMain', lineGraphCol);
		document.documentElement.style.setProperty('--trimColorGraphDarker', D);
		document.documentElement.style.setProperty('--trimColorGraphLighter', L);
  		styleC.setStyle(newStyle);
	}, [lineGraphCol])
	

	useEffect(() => {
	  console.log(styleC)
		  document.body.style.backgroundColor = styleC.style.bgColor;
	}, [styleC])
	
	useEffect(() => {
	  if(g_access_token !== ""){
		tryTokenOk(g_access_token, setTokenOk, setAuthType, 5000);
		console.log(authType)
	  }
	}, [g_access_token])
	  
	useEffect(() => {
	  if(g_access_token !== ""){
		tryTokenOk(g_access_token, setTokenOk, setAuthType, 1000);
		console.log(authType)
	  }
	}, [activeDb, activeDbC])
	
	useEffect(() => {
	  if(tokenOk === 2){
		console.log("Token ok")
		setShowLoginScreen(false)
		setLoggedInLoading(false)
	  } else if(tokenOk === 1){
		console.log("Token not ok")
		setShowLoginScreen(true)
		cookies.remove("access_token")
		cookies.remove("refresh_token")
		cookies.remove("activeDb")
		window.location.replace(THIS_URL);
	  } else if(tokenOk === 0){
	
	  }
	}, [tokenOk])
  
  useEffect(() => {
	const actDbCookieVal = cookies.get("activeDb")
	console.log(actDbCookieVal)
	if(actDbCookieVal){
	  activeDb = actDbCookieVal;
	}
  }, [])
  
  useEffect(() => {
	if(!cookies.get("default_author_index")){
	  console.log("no default author index set, setting to 0")
	  cookies.set("default_author_index", 0, {
		expires: new Date("2038-01-19")
	  })
	}
  }, [])
  
	useEffect(() => {
	  if(g_access_token === ""){
		console.log("checking for cookies")
		try{ //Kolla först om det finns en cookie med auth token
		  const access_token = cookies.get("access_token")
		  console.log(access_token)
		  if(access_token){
			console.log("token found")
			setLoggedInLoading(true)
			//loginAuthC.setAuth(access_token)
			g_access_token = access_token;
		  } else{
			//Om det inte finns en auth token, kolla om det finns "code" i URLSearchParams
			const URLAuthCode = (new URLSearchParams(window.location.search)).get("code");
			if(URLAuthCode){
			  console.log(URLAuthCode)
			  try{
				getAuthToken(URLAuthCode, cookies);
			  }catch(e){
				console.error("AUTH TOKEN ERROR")
			  }
			} else{
			  //Annars visa login skärmen med en knapp där de kan få en code
			  //setShowLoginScreen(true);
			  console.log("Show loginscreen")
			}
		  }
		}catch(e){
		  console.log(e)
		}    
	  } else{
  
	  }
	}, [])

	const switchPresetColorTheme = (value) => {
		console.log(value)
		switch (value) {
			case "Dark":
				console.log("Yes dark")
				setBgCol("#1C1C1C")
				setTrimCol("#F9FFFF")
				setBgGraphCol("#1B1B1B")
				setLineGraphCol("#2962FF")
				break;
			case "Light":
				console.log("Yes light")
				setBgCol("#ffffff")
				setTrimCol("#373b3e")
				setBgGraphCol("#ffffff")
				setLineGraphCol("#000000")
				break;
			default:
				break;
		}
	}
  
	const login = () => {
	  const discordOauthLink = DISCORD_OAUTH_URL;
	  if(discordOauthLink){
		window.location.replace(discordOauthLink);
	  } else{
		console.error("MISSING OAUTH LINK")
	  }
  
	}
  
	const handleReset = () => {
	  Array.from(document.querySelectorAll("input")).forEach(
		input => (input.value = "")
	  );
	};
  
	const loadMoreTrades = () => {
	  setCurrentlyLoadingMoreTrades(true);
	  if(loadAdditionalTrades == 0){
		setLoadAdditionalTrades(1);
	  } else if(loadAdditionalTrades == 1){
		setLoadAdditionalTrades(2);
	  } else if(loadAdditionalTrades == 2){
		setLoadAdditionalTrades(3);
	  }
	}
  
	useEffect(() => {
	  chartTimeframe.current = "1d"
	  switchPresetColorTheme("Light")
	}, [])
  
  
  
	useEffect(() => {
	  if(activeDbC){
		activeDb = activeDbC;
		console.log(activeDb)
	  }
	  console.log("Asking for API data with filter:")
	  console.log(filterParams)
	  var finalFilterParams = filterParams;
	  finalFilterParams.limit = 10000;
	  finalFilterParams.sort = defaultFetchSort;



	  getTradePositions(setTradePositions, finalFilterParams);
	  getChannels(setChannels);
	  getUsers(setUsers);
	  getRoles(setRoles);
	  getAdmins(setAdmins);
	  getGuilds(setGuilds, cookies);
	  wsTradesFilterParams.current = filterParams;
	  exportCsvFilterParams.current = finalFilterParams;
	}, [activeDbC, filterParams, loadAdditionalTrades])
  
	useEffect(() => {
	  wsTradesFilterUsers.current = users;
	}, [users])
  
	useEffect(() => {
	  var openTmp = [];
	  var closedTmp = [];
	  for(var a in tradePositions){
		if(tradePositions[a].exit){
		  closedTmp.push(tradePositions[a])
		} else{
		  openTmp.push(tradePositions[a])
		}
	  }
	  setOpenPositions(openTmp)
	  setClosedPositions(closedTmp)
	  /*
	  getUsers(setUsers);
	  getRoles(setRoles);
	  getAdmins(setAdmins);
	  getGuilds(setGuilds);
	  */
	}, [tradePositions])
  
	useEffect(() => {
	  console.log("Listening on ws")
	  socket.on("trade", (data) => {
		console.log("Received data: ");
		console.dir(data);
		if(data.db) {
		  if(data.db != activeDb){
			console.error("Wrong DB")
		  } else {
  
  
			console.log("wsTradesFilterParams.current")
			console.log(wsTradesFilterParams.current)
			//Om traden inte passar in i filtret ska den tas bort redan nu.
			var tradeWasFiltered = false
			for (const [filterKey, filterValue] of Object.entries(wsTradesFilterParams.current)) {
			  console.log("key " + filterKey + ": value: " + filterValue);
			  switch (filterKey) {
				case "ticker":
				  if(!data.trade["ticker"].includes(filterValue)){
					console.log("Filter caught on ticker")
					tradeWasFiltered = true
				  }
				  break;
				case "exit":
				  if(data.trade["exit"] && filterValue == "!exists"){
					console.log("Filter caught on exit")
					tradeWasFiltered = true
				  }
				  break;
				case "user_id":
				  var usersNames = [];
				  for(var t in wsTradesFilterUsers.current){
					console.log("Checking if this contains this")
					console.log(filterValue)
					console.log(wsTradesFilterUsers.current[t].id)
					console.log("users: ")
					console.log(wsTradesFilterUsers.current)
					if(filterValue.includes(wsTradesFilterUsers.current[t].id)){
					  usersNames.push(wsTradesFilterUsers.current[t].name);
					}
				  }
				  console.log("All users here: ")
				  console.log(usersNames)
				  var userFound = false;
				  for(var t in usersNames){
					if(usersNames.includes(data.trade["user"])){
					  userFound = true;
					}
					if(data.trade.user && data.trade.user.name){
					  if(usersNames.includes(data.trade.user.name)){
						userFound = true;
					  }
					}
				  }
				  if(!userFound){
					console.log("Filter caught on user")
					tradeWasFiltered = true
				  }
				  break;
				case "direction":
				  if(data.trade["direction"] !== filterValue){
					console.log("Filter caught on direction")
					tradeWasFiltered = true
				  }
				  if(data.trade["certificate"] == true){
					tradeWasFiltered = false //Eftersom cert kan vara oförutsägbara så ignorera direction på cert
				  }
				  break;
				default:
				  break;
			  }
			}
  
			if(tradeWasFiltered){
			  console.log("trade was filtered, should not be added")
			} else{
			  console.log("trade passed filter, now the rest")
			  if(data.trade.deleted === true){
				var tempOpen = openPositionRef.current.filter(a => a._id !== data.trade._id)
				var tempClosed = closedPositionsRef.current.filter(a => a._id !== data.trade._id)
				setOpenPositions(tempOpen);
				setClosedPositions(tempClosed);
			  } else {
				var tradeFoundInOpenIdx = openPositionRef.current.findIndex(a => a._id == data.trade._id)
				var tradeFoundInClosedIdx = closedPositionsRef.current.findIndex(a => a._id == data.trade._id)
				
				var tradeIdx; //detta är alltså potentiellt den trade vi har i vår lokala data just nu
				var inOpen;
				var trade;
	
				if(tradeFoundInOpenIdx != -1 && tradeFoundInClosedIdx == -1){
				  tradeIdx = tradeFoundInOpenIdx
				  inOpen = true;
				  trade = openPositionRef.current[tradeIdx]
				  setFO(Date.now());
				} else if(tradeFoundInOpenIdx == -1 && tradeFoundInClosedIdx != -1){
				  tradeIdx = tradeFoundInClosedIdx
				  inOpen = false;
				  trade = closedPositionsRef.current[tradeIdx]
				  setFO(Date.now());
				} else{
				}
	
				//console.log("dags att uppdatera följande trade, alt. om det inte finns något så ska en ny trade läggas till...")
				//console.log(trade)
	
				if(trade == undefined){
				  if(data.trade.entry && !data.trade.exit){
					//console.log("Data only had entry, appending to openPositions: ");
					setOpenPositions([...openPositionRef.current, data.trade]);
				  } else if(data.trade.entry && data.trade.exit){
					//console.log("Data had entry AND exit, appending to closedPositions: ");
					setClosedPositions([...closedPositionsRef.current, data.trade]);
				  } else {
					console.error("Data not handled")
				  }
				} else {
				  console.log("Updaterar trade...")
				  if(inOpen){
					var tmp = openPositionRef.current;
					tmp[tradeIdx] = data.trade;
					//console.log("uppdaterat nu så att tmp borde ha rätt trade på index: ", tradeIdx)
					//console.log(tmp)
					setOpenPositions(tmp)
				  } else {
					var tmp = closedPositionsRef.current;
					tmp[tradeIdx] = data.trade;
					//console.log("uppdaterat nu så att tmp borde ha rätt trade på index: ", tradeIdx)
					//console.log(tmp)
					setClosedPositions(tmp)
				  }
				}
			  }
			}
		  }
		} else {
		  console.error("Data received in wrong format.")
		}
	  })
	}, [socket])
  
	useEffect(() => {
	  if(openPositions && closedPositions && authType != 2){
		  let allPositions = openPositions.concat(closedPositions);
		  allPositions.sort((a,b) => (a.entry.msg.id < b.entry.msg.id) ? 1 : -1);
		  console.log("allPositions")
		  console.log(allPositions)
  
		  //allPositions = allPositions.filter((x) => x.ts > 1673549606393);
  
		  let tickers = []
		  let entryIds = new Set();
		  for(var a in allPositions){
			tickers.push({'ticker': replaceBB(removeParenthesis(allPositions[a].ticker)), 'name': allPositions[a].ticker, 'id': a});
			entryIds.add(allPositions[a].entry.msg.id)
		  }
		  const tickersUniq = [...new Map(tickers.map(v => [v.ticker, v])).values()]
		  setInstruments(tickersUniq);
  
		  let completeTrades = []
		  entryIds = Array.from(entryIds)
		  for(var a in entryIds){
			let filtered = allPositions.filter((item) => item.entry.msg.id == entryIds[a])
			allPositions = allPositions.filter((x) => x.entry.msg.id != entryIds[a])
			completeTrades.push(filtered)
		  }
  
		  var combinedTrades = []
		  for(var a in completeTrades){
			var made = false;
			for(var b in completeTrades[a]){
			  if(!completeTrades[a][b].exit){
				combinedTrades.push({...completeTrades[a][b], exits: completeTrades[a].filter((x) => x._id != completeTrades[a][b]._id)})
				//Här bör vi summera EK för att se att vi har ALLA tillhörande trades, och annars ladda ned via API med /getTrade/
				made = true;
				break; //Det bör bara högst finnas 1 trade utan exit, så när vi hittar den kan vi skippa att loopa genom resten.
			  }
			}
			if(!made){ //Om vi inte hittade någon trade utan exit så tar vi o antar att ALLA har exit, dvs hela traden är helt stängd
			  completeTrades[a].sort((a,b) => (a.ts < b.ts) ? 1 : -1)
			  combinedTrades.push({...completeTrades[a][0], exits: completeTrades[a]})
			}
		  }
  
		  var minEk = 99999;
		  var maxEk = 0;
		  for(let a in combinedTrades){
			if(combinedTrades[a].ek < minEk){
			  minEk = combinedTrades[a].ek
			}
			if(combinedTrades[a].ek > maxEk){
			  maxEk = combinedTrades[a].ek
			}
		  }
  
		  console.log("minEk")
		  console.log(minEk)
		  console.log("maxEk")
		  console.log(maxEk)
  
		  var numBuckets = 20;
		  var bucketsize = maxEk/numBuckets;
		  var buckets = Array(numBuckets+1).fill(0);
  
		  for(let a in combinedTrades){
			var rem = Math.floor(combinedTrades[a].ek/bucketsize);
			buckets[rem] += 1;
		  }
  
		  console.log("numBuckets")
		  console.log(numBuckets)
		  console.log("bucketsize")
		  console.log(bucketsize)
		  console.log("buckets")
		  console.log(buckets)
  
		  combinedTrades.sort((a,b) => (a.ts < b.ts) ? 1 : -1);
  
		  console.log("combinedTrades")
		  console.log(combinedTrades)
		  
		  const table = combinedTrades.map((a) => (
			<CollapsibleTableRow key={a._id} data={a} exits={a.exits}
			 handleReset={handleReset} 
			 genChangeInfo={[]} 
			 genMakeExit={[]} 
			 genMoveLevels={[]}
			 setSelectedTrade={setSelectedTrade} 
			 setNewTradeGen={setNewTradeGen} 
			 setNewTradeGraphTicker={setNewTradeGraphTicker}
			 chartContainerRef={chartContainerRef}
			 chartRef={chartRef}
			 resizeObserverRef={resizeObserverRef}
			 activeDb={activeDb}
			 authType={authType}
			 />
		  ));
		  
		  setCombinedTradesState(combinedTrades);
		  setPositionsTable(table);
	  }   
	}, [openPositions, closedPositions, FO, authType])
  
	useEffect(() => {
	  setTimeout(() => {
		setHasTranslatedTrades(true) //1sec delay for effect
		setCurrentlyLoadingMoreTrades(false)
	  }, 1000)
	}, [positionsTable])
  
	useEffect(() => {
	  try{
		if(document.getElementsByClassName("tv-lightweight-charts").length > 0){
		  document.getElementsByClassName("tv-lightweight-charts")[0].remove();
		  document.getElementsByClassName("chart-legend")[0].remove();
		}
	  }catch(e){
		console.log(e)
	  }
	}, [])
  
	//{generalLoading ? {loadingSpinner} : null}
  
	const handleSelectChangeGuild = (values) => {
	  console.log(values)
	  cookies.set("activeDb", values[0].id, {
		expires: new Date("2038-01-19")
	  })
	  activeDb = values[0].id;
	  window.location.replace(THIS_URL);
	}
  
	console.log(guilds ? guilds.length > 1 : false)
  
	return (
	  <main>
		<div>
		  <Box sx={{ flexGrow: 1 }}>
			<div className="full-container" style={{backgroundColor: styleC.style.bgColor}}> 
			  {loggedInLoading ?
			  <div style={{width:"50%", height:"50%", position:"absolute", top:"0", left:"0", bottom:"0", right:"0", margin:"auto", padding:"1%", color:"white"}}>
				<img src={logo} style={{width:"50%", height:"50%", position:"absolute", top:"0", left:"0", bottom:"0", right:"0", margin:"auto", padding:"1%", color:"white"}}></img>
				<img src={loadingSpinner} className='rotate'></img>
			  </div>
			  : 
			  (new URLSearchParams(window.location.search)).get("code") ? 
				<div style={{width:"50%", height:"50%", position:"absolute", top:"0", left:"0", bottom:"0", right:"0", margin:"auto", padding:"1%", color:"white"}}>
				  <img src={logoLoading} style={{width:"50%", height:"50%", position:"absolute", top:"0", left:"0", bottom:"0", right:"0", margin:"auto", padding:"1%", color:"white"}}></img>
				</div>
			  :
			  showLoginScreen ?
			  <div style={{width: "30%", display: "block", margin: "auto"}}>
				<br></br>
				<button onClick={() => {login()}}>Logga in via Discord</button>
			  </div> 
			  :
			  positionsTable && hasTranslatedTrades && !loggedInLoading ? 
			  <Grid container spacing={0}>
				  <Grid item xs={12}>
					{showStatsMenu ? <FullScreenChart currentTrades={combinedTradesState} setShowStatsMenu={setShowStatsMenu}/> : null}
				  </Grid>
			  </Grid>
			  :
				<div style={{width:"50%", height:"50%", position:"absolute", top:"0", left:"0", bottom:"0", right:"0", margin:"auto", padding:"1%", color:"white"}}>
				  <img src={logo} style={{width:"50%", height:"50%", position:"absolute", top:"0", left:"0", bottom:"0", right:"0", margin:"auto", padding:"1%", color:"white"}}></img>
				</div>
			}</div>
		  </Box>
		</div>
	  </main>
	);
  }
  