export function roundToSignificant(num){
	if(!num) return 0;
	if(num === 0) return 0;
	try{
		var sign = num > 0 ? true : false
		num = Math.abs(num)
		var orderOfMagnitude = Math.pow(10, (String(Math.trunc(num)).length));
		var res = num.toFixed(1-Math.floor(Math.log(num)/Math.log(orderOfMagnitude)));
		if(res > 100) res = Math.trunc(res);
		return sign ? Number(res) : -1*Number(res)
	}catch(e){
		console.log(e)
		console.log(num)
		return num;
	}
}

export function colorAdjust(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

export function convertSecondsToSignificantTimeframe(seconds) {
	const secString = ""+Math.trunc(seconds);
	switch (secString.length) {
		case 0:
			return "0";
		case 1:
			return roundToSignificant(seconds) + "s"
		case 2:
			return roundToSignificant(seconds) + "s"
		case 3:
			return roundToSignificant(seconds) + "s"
		case 4:
			return roundToSignificant(seconds/60) + "m"
		case 5:
			return roundToSignificant(seconds/(60*60)) + "h"
		case 6:
			return roundToSignificant(seconds/(60*60*24)) + "D"
		case 7:
			return roundToSignificant(seconds/(60*60*24*7)) + "W"
		case 8:
			return roundToSignificant(seconds/(60*60*24*7*30)) + "M"
		case 9:
			return roundToSignificant(seconds/(60*60*24*7*30)) + "Y"
		case 10:
			return roundToSignificant(seconds/(60*60*24*7*30)) + "Y"
		default:
			console.error("No length was found for seconds or number was too large")
			return "0";
			break;
	}
}

export function calcCertToUnderlying(docElementCertPrice, docElementUnderlyingPrice, leverage, entryPrice, orgUnderlyingPrice, isEntry, docElementLS, docElementCert) {
	const dir = document.querySelector('input[name=' + docElementLS + ']:checked') ? document.querySelector('input[name=' + docElementLS + ']:checked').value : docElementLS;
	const cert = document.getElementById(docElementCert) ? document.getElementById(docElementCert).checked : docElementCert;
	if(!cert){
		return;
	}
	if(document.getElementById(docElementCertPrice).value == 0 || document.getElementById(docElementCertPrice).value == ""){
		return;
	}
	if(isEntry == true){
		//Gör inget

	} else{
		const inputtedCertPrice = document.getElementById(docElementCertPrice) ? Number(document.getElementById(docElementCertPrice).value.replace(',','.')) : Number(docElementCertPrice.replace(',','.'));
		const fetchedCurrentCertPrice = document.getElementById(entryPrice) ? Number(document.getElementById(entryPrice).value.replace(',','.')) : Number(entryPrice.replace(',','.'));
		const currentLeverage = document.getElementById(leverage) ? Number(document.getElementById(leverage).value.replace(',','.')) : Number(String(leverage).replace(',','.'));
		const fetchedCurrentUnderlyingPrice = document.getElementById(orgUnderlyingPrice) ? Number(document.getElementById(orgUnderlyingPrice).value.replace(',','.')) : Number(orgUnderlyingPrice.replace(',','.'));
		console.log("Time to calculate the underlying price")
		console.log("params: ")
		console.log(inputtedCertPrice, fetchedCurrentCertPrice, currentLeverage, fetchedCurrentUnderlyingPrice)
		//Vi har entrypriset, räkna ut en av de andra nivåerna
		if(dir === "long"){
			const distanceWithLeverage = ((inputtedCertPrice / fetchedCurrentCertPrice)-1)*100;
			console.log("distanceWithLeverage")
			console.log(distanceWithLeverage)
			const distanceInUnderlying = 1+((distanceWithLeverage / currentLeverage)/100);
			console.log("distanceInUnderlying")
			console.log(distanceInUnderlying)
			const underlyingResult = distanceInUnderlying * fetchedCurrentUnderlyingPrice;
			console.log("underlyingResult")
			console.log(underlyingResult)
			document.getElementById(docElementUnderlyingPrice).value = (underlyingResult);
		}else if(dir === "short"){
			const distanceWithLeverage = -((inputtedCertPrice / fetchedCurrentCertPrice)-1)*100;
			console.log("distanceWithLeverage")
			console.log(distanceWithLeverage)
			const distanceInUnderlying = 1+((distanceWithLeverage / currentLeverage)/100);
			console.log("distanceInUnderlying")
			console.log(distanceInUnderlying)
			const underlyingResult = distanceInUnderlying * fetchedCurrentUnderlyingPrice;
			console.log("underlyingResult")
			console.log(underlyingResult)
			document.getElementById(docElementUnderlyingPrice).value = (underlyingResult);		
		}
	}
}

export function calcUnderlyingToCert(docElementCertPrice, docElementUnderlyingPrice, leverage, entryPrice, orgUnderlyingPrice, isEntry, docElementLS, docElementCert) {
	const dir = document.querySelector('input[name=' + docElementLS + ']:checked') ? document.querySelector('input[name=' + docElementLS + ']:checked').value : docElementLS;
	const cert = document.getElementById(docElementCert) ? document.getElementById(docElementCert).checked : docElementCert;
	if(!cert){
		return;
	}
	if(document.getElementById(docElementUnderlyingPrice).value == 0 || document.getElementById(docElementUnderlyingPrice).value == ""){
		return;
	}
	if(isEntry == true){
		//Gör inget
		//Number(exitUnderlying.replace(',','.'))
	} else{
		const inputtedUnderlyingPrice = document.getElementById(docElementUnderlyingPrice) ? Number(document.getElementById(docElementUnderlyingPrice).value.replace(',','.')) : Number(docElementUnderlyingPrice.replace(',','.'));
		const fetchedCurrentUnderlyingPrice = document.getElementById(orgUnderlyingPrice) ? Number(document.getElementById(orgUnderlyingPrice).value.replace(',','.')) : Number(orgUnderlyingPrice.replace(',','.'));
		const fetchedCurrentCertPrice = document.getElementById(entryPrice) ? Number(document.getElementById(entryPrice).value.replace(',','.')) : Number(entryPrice.replace(',','.'));
		const currentLeverage = document.getElementById(leverage) ? Number(document.getElementById(leverage).value.replace(',','.')) : Number(String(leverage).replace(',','.'));
		console.log("Time to calculate the underlying price")
		console.log("params: ")
		console.log(inputtedUnderlyingPrice, fetchedCurrentCertPrice, currentLeverage, fetchedCurrentUnderlyingPrice)
		//Vi har entrypriset, räkna ut en av de andra nivåerna
		if(dir === "long"){
			const distanceInUnderlying = ((inputtedUnderlyingPrice / fetchedCurrentUnderlyingPrice)-1)*100;
			console.log("distanceInUnderlying")
			console.log(distanceInUnderlying)
			const distanceWithLeverage = 1+((distanceInUnderlying * currentLeverage)/100);
			console.log("distanceWithLeverage")
			console.log(distanceWithLeverage)
			const certResult = distanceWithLeverage * fetchedCurrentCertPrice;
			console.log("certResult")
			console.log(certResult)
			document.getElementById(docElementCertPrice).value = (certResult);
		}else if(dir === "short"){
			const distanceInUnderlying = -((inputtedUnderlyingPrice / fetchedCurrentUnderlyingPrice)-1)*100;
			console.log("distanceInUnderlying")
			console.log(distanceInUnderlying)
			const distanceWithLeverage = 1+((distanceInUnderlying * currentLeverage)/100);
			console.log("distanceWithLeverage")
			console.log(distanceWithLeverage)
			const certResult = distanceWithLeverage * fetchedCurrentCertPrice;
			console.log("certResult")
			console.log(certResult)
			document.getElementById(docElementCertPrice).value = (certResult);
		}
	}
}

export function calcGainDiff(docElementFromPrice, docElementToPrice, docElementResult) {
	try{
		const fromPrice = document.getElementById(docElementFromPrice) ? Number(document.getElementById(docElementFromPrice).value.replace(',','.')) : Number(docElementFromPrice.replace(',','.'));
		const toPrice = document.getElementById(docElementToPrice) ? Number(document.getElementById(docElementToPrice).value.replace(',','.')) : Number(docElementToPrice.replace(',','.'));
	
		const result = (toPrice - fromPrice)/(fromPrice)*100

		document.getElementById(docElementResult).value = String(Number(roundToSignificant(result))+"%");
	}catch(e){
		console.error(e)
	}
}