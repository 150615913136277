import '../App.css';
import React, { useState, useEffect, useRef, useContext } from 'react';
import Select from 'react-dropdown-select';
import { ChromePicker } from 'react-color';
import LineChart from './LineChart';
import { colorAdjust } from './helpFunc'
import { StyleContext } from '../App';

const regexRemoveParenthesis = /((CASH).*)|((\(([^)]+)\).*))|\(\d+\)|(SHORT)|(LONG)/;

export default function SettingsMenu(props) {
	const chartContainerRef = useRef();
	const chartRef = useRef();
	const resizeObserverRef = useRef();
	
	const styleC = useContext(StyleContext);

	//GRAF
	var totGain = 0;
	const tradesUnsorted = props.currentTrades;
	const trades = tradesUnsorted.sort((a,b) => (a.ts < b.ts) ? 1 : -1).reverse();
	var chartData = [{time: {year: 10000, month: 1, day: 1}, value: 0}];
	for(var i in trades){
		if(trades[i].exits){
		  for(var c in trades[i].exits){
			if(trades[i].exits[c].portfolio_gain){
			  totGain += trades[i].exits[c].portfolio_gain;
			}
		  }
  
		  chartData.push({
			time: {year: Number(i)+10001, month: 1, day: 1}, 
			value: (totGain), 
			custom: {
			  instrument: trades[i].name ? trades[i].name.split(' ')[trades[i].name.split(' ').length-1] == "delay)" ? trades[i].name.slice(0, -11) : trades[i].name : trades[i].ticker, 
			  datum: trades[i].ts,
			  gainHere: trades[i].portfolio_gain ? trades[i].portfolio_gain : 0,
			}
		  });
		} 
	}
	//

	const [showBgCol, setShowBgCol] = useState(false);
	const [bgCol, setBgCol] = useState(styleC.style.bgColor);
	const [showTrimCol, setShowTrimCol] = useState(false);
	const [trimCol, setTrimCol] = useState(styleC.style.trimColor);
	const [showBgGraphCol, setShowBgGraphCol] = useState(false);
	const [bgGraphCol, setBgGraphCol] = useState(styleC.style.bgGraph);
	const [showLineGraphCol, setShowLineGraphCol] = useState(false);
	const [lineGraphCol, setLineGraphCol] = useState(styleC.style.lineGraph);

	useEffect(() => {
		const newStyle = styleC.style;

		const BD = colorAdjust(bgCol, -2);
		const D = colorAdjust(bgCol, -7);
		const BL = colorAdjust(bgCol, 4);
		const L = colorAdjust(bgCol, 17);

		newStyle.bgColor = bgCol;
		newStyle.bgBarelyDarker = BD;
		newStyle.bgBarelyLighter = BL;
		newStyle.bgDarker = D;
		newStyle.bgLighter = L;
		document.documentElement.style.setProperty('--bgColorMain', bgCol);
		document.documentElement.style.setProperty('--bgColorDarker', D);
		document.documentElement.style.setProperty('--bgColorLighter', L);
		document.documentElement.style.setProperty('--bgColorBarelyDarker', BD);
		document.documentElement.style.setProperty('--bgColorBarelyLighter', BL);
  		styleC.setStyle(newStyle);
	}, [bgCol])
	useEffect(() => {
		const newStyle = styleC.style;
		
		const D = colorAdjust(trimCol, -7);
		const L = colorAdjust(trimCol, 7);

		newStyle.trimColor = trimCol;
		newStyle.trimDarker = D;
		newStyle.trimLighter = L;
		document.documentElement.style.setProperty('--trimColorMain', trimCol);
		document.documentElement.style.setProperty('--trimColorDarker', D);
		document.documentElement.style.setProperty('--trimColorLighter', L);
  		styleC.setStyle(newStyle);
	}, [trimCol])
	useEffect(() => {
		const newStyle = styleC.style;
		
		const D = colorAdjust(bgGraphCol, -7);
		const L = colorAdjust(bgGraphCol, 7);

		newStyle.bgGraph = bgGraphCol;
		newStyle.bgGraphDarker = D;
		newStyle.bgGraphLighter = L;
		document.documentElement.style.setProperty('--bgColorGraphMain', bgGraphCol);
		document.documentElement.style.setProperty('--bgColorGraphDarker', D);
		document.documentElement.style.setProperty('--bgColorGraphLighter', L);
  		styleC.setStyle(newStyle);
	}, [bgGraphCol])
	useEffect(() => {
		const newStyle = styleC.style;
		
		const D = colorAdjust(lineGraphCol, -7);
		const L = colorAdjust(lineGraphCol, 7);

		newStyle.lineGraph = lineGraphCol;
		newStyle.lineGraphDarker = D;
		newStyle.lineGraphLighter = L;
		document.documentElement.style.setProperty('--trimColorGraphMain', lineGraphCol);
		document.documentElement.style.setProperty('--trimColorGraphDarker', D);
		document.documentElement.style.setProperty('--trimColorGraphLighter', L);
  		styleC.setStyle(newStyle);
	}, [lineGraphCol])
	

	const previewStyle = {
		backgroundColor: bgCol,
		color: trimCol,
	}

	const popover = {
		position: 'absolute',
		zIndex: '2',
		backgroundColor: bgCol
	}

	const cover = {
		position: 'fixed',
		top: '0px',
		right: '0px',
		bottom: '0px',
		left: '0px',
	}

	const switchPresetColorTheme = (values) => {
		console.log(values)
		switch (values[0].label) {
			case "Dark":
				console.log("Yes dark")
				setBgCol("#1C1C1C")
				setTrimCol("#F9FFFF")
				setBgGraphCol("#1B1B1B")
				setLineGraphCol("#2962FF")
				break;
			case "Light":
				console.log("Yes light")
				setBgCol("#fcf9f4")
				setTrimCol("#373b3e")
				setBgGraphCol("#fcf9f4")
				setLineGraphCol("#2e8846")
				break;
			default:
				break;
		}
	}

	const presetSelect = (
		<Select 
		options={[{label: "Dark", value: 1}, {label: "Light", value: 0}]} 
		onChange={(values) => {switchPresetColorTheme(values)}} 
		placeholder="Färgtema"
		valueField="value"
		labelField="label"
		multi={false}
		className={"selectBoxDropdown"}
		/>
	)

	var result = (
		<>
			<h2 style={{cursor: "default", display:"inline-block", marginLeft:"1%"}}>Inställningar</h2>
			<h2 style={{cursor: "pointer", display:"inline-block", float:"right", width:"4%", fontWeight:"400"}}>
				<span onClick={() => {props.setShowSettingsMenu(false); return;}}>×</span>
			</h2>
			<hr></hr>
			<div>
			<table>
                <thead>
                  <tr>  
                    <th colSpan={2}>Tema: </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Förval:</td>
                    <td>{presetSelect}</td>
                  </tr>
                  <tr>
                    <td>Bakgrundsfärg</td>
                    <td>
						<div>
							<button style={{width: "50%"}} onClick={ () => {setShowBgCol(true)} }>Välj Färg</button>
							{ showBgCol ? 
								<div style={ popover }>
								<div style={ cover } onClick={() => {setShowBgCol(false)} }/>
									<ChromePicker color={bgCol} onChange={(e) => {setBgCol(e.hex)}} disableAlpha/>
								</div> 
							: null 
							}
						</div>
					</td>
                  </tr>
                  <tr>
                    <td>Text- och kantfärg</td>
                    <td>
						<div>
							<button style={{width: "50%"}} onClick={ () => {setShowTrimCol(true)} }>Välj Färg</button>
							{ showTrimCol ? 
								<div style={ popover }>
								<div style={ cover } onClick={() => {setShowTrimCol(false)} }/>
									<ChromePicker color={trimCol} onChange={(e) => {setTrimCol(e.hex)}} disableAlpha/>
								</div> 
							: null 
							}
						</div></td>
                  </tr>
                  <tr>
                    <td>Bakgrundsfärg (graf): </td>
                    <td>
						<div>
							<button style={{width: "50%"}} onClick={ () => {setShowBgGraphCol(true)} }>Välj Färg</button>
							{ showBgGraphCol ? 
								<div style={ popover }>
								<div style={ cover } onClick={() => {setShowBgGraphCol(false)} }/>
									<ChromePicker color={bgGraphCol} onChange={(e) => {setBgGraphCol(e.hex)}} disableAlpha/>
								</div> 
							: null 
							}
						</div></td>
                  </tr>
                  <tr>
                    <td>Linje (graf): </td>
                    <td>
						<div>
							<button style={{width: "50%"}} onClick={ () => {setShowLineGraphCol(true)} }>Välj Färg</button>
							{ showLineGraphCol ? 
								<div style={ popover }>
								<div style={ cover } onClick={() => {setShowLineGraphCol(false)} }/>
									<ChromePicker color={lineGraphCol} onChange={(e) => {setLineGraphCol(e.hex)}} disableAlpha/>
								</div> 
							: null 
							}
						</div></td>
                  </tr>
                  <tr>  
                    <th colSpan={2}><button style={{height: 40, fontSize: "1.1em", width: "50%", marginLeft: "auto", marginRight: "auto"}}>Spara</button> </th>
                  </tr>
                  <tr>  
                    <th colSpan={2}>Graf preview: </th>
                  </tr>
                </tbody>
              </table>
			  <div>
                <LineChart chartData={chartData} cContRef={chartContainerRef} cRef={chartRef} resObsRef={resizeObserverRef} />
			  </div>
			</div>
		</>
	);
	return(result);
};