//Global non-specific

//Global specific
export const ACTIVE_DB = process.env.REACT_APP_ACTIVE_DB ?? null;
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL ?? null;
export const IO_URL = process.env.REACT_APP_IO_URL ?? null;
export const DISCORD_OAUTH_URL = process.env.REACT_APP_DISCORD_OAUTH_URL ?? null;
export const PRE_ROLES = JSON.parse(process.env.REACT_APP_PRE_ROLES) ?? [];
export const TRADE_TYPES = JSON.parse(process.env.REACT_APP_TRADE_TYPES) ?? [];
export const THIS_URL = process.env.REACT_APP_THIS_URL ?? null;
