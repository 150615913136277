import React from 'react';
import './App.css';
import Main from './components/Main';

export const ActiveDbContext = React.createContext();
export const LoginAuthContext = React.createContext();
export const StyleContext = React.createContext();
//export const TimeNowContext = React.createContext();
//export const ServerInfoContext = React.createContext();

export default class App extends React.Component {

	setStyle = (val) => {
		console.log(val)
		this.setState((prevState) => ({...prevState, style: val}));
	}
	state = {
		activeDb: null,
		data: null,
		loading: true,
		timeNow: null,
		auth: false,
		authToken: "",
		setAuth: this.setAuth,
		style: {
			bgColor: "#1C1C1C",
			bgDarker: "#0B0B0B",
			bgLighter: "#2D2D2D",
			bgBarelyDarker: "#1B1B1B",
			bgBarelyLighter: "#1D1D1D",

			trimColor: "#F9FFFF",
			trimDarker: "#E8EEEE",
			trimLighter: "#FFFFFF",

			bgGraph: "#1B1B1B",
			bgGraphDarker: "#0A0A0A",
			bgGraphLighter: "#2C2C2C",
			
			lineGraph: "#2962FF",
			lineGraphDarker: "#1851EE",
			lineGraphLighter: "#3A73FF",
		}
	};
	
	async componentDidMount(){
		/*const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json'},

		};*/

		//console.log(requestOptions)
		//const response = await fetch(`http://185.96.163.95:8008`, requestOptions);
		//const rec = await response.json();
		let actdb = (new URLSearchParams(window.location.search)).get("db");
		this.setState({activeDb: actdb, data: "rec", loading: false, timeNow: new Date(Date.now() + (new Date(Date.now()).getTimezoneOffset()*60*1000*-1))});
		}

	render(){
		return(<div className="App">
			{this.state.loading ? "Loading..." : 
			<LoginAuthContext.Provider value={{auth: this.state.auth, setAuth: this.state.setAuth}}>
				<ActiveDbContext.Provider value={this.state.activeDb}>
					<StyleContext.Provider value={{style: this.state.style, setStyle: this.setStyle}}>
						<Main />
					</StyleContext.Provider>
				</ActiveDbContext.Provider>
			</LoginAuthContext.Provider>}
		</div>)
	};
}