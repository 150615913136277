import { createChart, CrosshairMode, LineStyle } from 'lightweight-charts';
import React, { useEffect, useState, useRef } from 'react';
import { roundToSignificant } from './helpFunc';
import Cookies from 'universal-cookie';
import { ACTIVE_DB, WEBSOCKET_URL, IO_URL, DISCORD_OAUTH_LINK, THIS_URL } from './envVars'
import { StyleContext } from '../App';

var g_access_token = "";

function roundToTimeframe(num, timeframe){
	let secondsDivisor
	switch (timeframe) {
		case "5":
			secondsDivisor = 300;
			break;
	
		case "15":
			secondsDivisor = 900;
			break;
	
		case "1h":
			secondsDivisor = 3600;
			break;

		case "1d":
			secondsDivisor = 86400;
			break;

		default:
			secondsDivisor = 86400;
			break;
	}
	console.log(secondsDivisor)
	let rem = num%secondsDivisor;
	console.log(num)
	console.log(rem)
	return num-rem;
	//return(Math.round((num)/secondsDivisor)*secondsDivisor)
}

function timeframeToScaleOutFactor(timeframe){
	switch (timeframe) {
		case "5":
			return 45;
	
		case "15":
			return 35;
	
		case "1h":
			return 25;

		case "1d":
			return 17;

		default:
			return 10;
	}
}

//GET INSTRUMENT INFO (ENTRY)
async function getInstrumentHistorical(instrument, setHistoricalPriceData, setShortName, chartTimeframe, setShowTickerName) {
	if(instrument.ticker == '' || instrument.source == '' || instrument.id == ''
	   || !instrument.ticker || !instrument.source || !instrument.id){
		setHistoricalPriceData(null)
	}
	//console.log(chartTimeframe)
	try{
		console.log(instrument)
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
			body: JSON.stringify({
				ticker: instrument.ticker,
				source: instrument.source,
				id: instrument.id,
				history: true,
				timeframe: chartTimeframe,
			})
		};

		console.log("requesting stockInfo: ", requestOptions)
		const response = await fetch(IO_URL+'/stockInfo/', requestOptions);
		const rec = await response.json();
		if(rec != null){
			setHistoricalPriceData(rec.chart);
			setShortName(rec.name);
			setShowTickerName(true);
			try{
				document.getElementsByClassName("noDataClass")[0].remove();
			}catch(e){

			}
			
		}else{
			setHistoricalPriceData(null);
			setShowTickerName(true);
		}
	}
	catch(e){
		console.log(e)
	}
}


//  ███    ███  █████  ██ ███    ██ 
//  ████  ████ ██   ██ ██ ████   ██ 
//  ██ ████ ██ ███████ ██ ██ ██  ██ 
//  ██  ██  ██ ██   ██ ██ ██  ██ ██ 
//  ██      ██ ██   ██ ██ ██   ████ 


export default function CandleChart(props) {
	const cookies = new Cookies();

	const chartContainerRef = props.cContRef;
	const chart = props.cRef;
	const resizeObserver = props.resObsRef;
	const [historicalPriceData, setHistoricalPriceData] = useState();
	const [shortName, setShortName] = useState();
	const [candlesticksOHLC, setCandlesticksOHLC] = useState();
	const [isCandlestickChart, setIsCandlestickChart] = useState(false);
	const [chartTimeframe, setChartTimeframe] = useState(props.chartTimeframe);
	const [enableGraph, setEnableGraph] = useState();
	const [chartVisibleRange, setChartVisibleRange] = useState();
	const [showTickerName, setShowTickerName] = useState();

	const styleC = React.useContext(StyleContext);

	var finishedElement;

	const shortNameRef = useRef();
	shortNameRef.current = shortName ?? "Loading...";
	const tfRef = useRef();
	tfRef.current = chartTimeframe;

	const [showTfSelector, setShowTfSelector] = useState(false);

	const tzOffset = (new Date().getTimezoneOffset())*-60000;
	
	const handleTimeframeSelect = (value) => {
		//console.log(value.target.value)
		tfRef.previous = tfRef.current;
		setChartTimeframe(value.target.value)
		tfRef.current = value.target.value;
	}

	var offset;
	try{
		offset = props.custOffset ? props.custOffset : document.getElementById("stickyTable").offsetHeight;
		//console.log(offset)
	}catch(e){
		offset = 20
	}

	useEffect(() => {
		if(g_access_token === ""){
		  console.log("checking for cookies")
		  try{
			const access_token = cookies.get("access_token")
			console.log(access_token)
			if(access_token){
			  g_access_token = access_token;
			} else{
			  window.location.replace(THIS_URL);
			}
		  }catch(e){
			console.log(e)
		  }    
		}
	  }, [])

	useEffect(() => {
		const graphFd = (props.information[0].ticker == '' || props.information[0].source == '' || props.information[0].id == ''
						 || !props.information[0].ticker || !props.information[0].source || !props.information[0].id)
		setEnableGraph(!graphFd);
		console.log("Enable graph?: ")
		console.log(props.information[0])
		console.log(!graphFd)
		setShowTickerName(false)
	}, [props.information])

	useEffect(() => {
		setEnableGraph(true)
		//console.log(props)
		if(props.timef){ //current enbart till för assignment
			if(props.timef.current){
				props.timef.current = chartTimeframe ? chartTimeframe : props.timef.current
			}
		}
		var tempTimeframe;
		if(props.timef){
			if(props.timef.current || chartTimeframe){
				tempTimeframe = props.timef.current ?? chartTimeframe
			} else{
				tempTimeframe = props.timef
			}
		} else{
			tempTimeframe = "1d"
		}
		//console.log(tempTimeframe)
		shortNameRef.previous = shortNameRef.current;
		console.log("Getting new instrument historical: ", props.information[0], tempTimeframe)
		getInstrumentHistorical(props.information[0], setHistoricalPriceData, setShortName, tempTimeframe, setShowTickerName)
	}, [props.timef, props.information[0], chartTimeframe])

	useEffect(() => {
		shortNameRef.current = shortName;
		var isNotLineChart = 0;
		var isLineChart = 0;
		var shouldUpdate = false;
		if(historicalPriceData && historicalPriceData.length > 5){
			setEnableGraph(true)
			var res = JSON.parse(JSON.stringify(historicalPriceData));
			console.log(res)
			for(var a in res){
				// Buggfixar ifall datum inte finns på rätt ställe
				if(res[a].date===undefined){
					res[a].date = res[a].ts
				}
				if(res[a].date===undefined){
					try {
						res[a].date = res[a]._id
					} catch(e){
						console.log(e)
					}
				}
				res[a].time = Number(new Date(res[a].date)/1000)+tzOffset/1000
				delete res[a].date
				delete res[a].adjClose
				delete res[a].volume
				if(!res[a].open){
					res[a].open = res[a-1] ? res[a-1].open ?? 0 : 0;
				}
				if(!res[a].high){
					res[a].high = res[a-1] ? res[a-1].high ?? 0 : 0;
				}
				if(!res[a].low){
					res[a].low = res[a-1] ? res[a-1].low ?? 0 : 0;
				}
				if(!res[a].close){
					res[a].close = res[a-1] ? res[a-1].close ?? 0 : 0;
				}
				if(res[a].open == res[a].close && res[a].low == res[a].high){
					isLineChart += 1;
				} else{
					isNotLineChart += 1;
				}
			}
			if(shortNameRef.previous == shortNameRef.current && tfRef.previous == tfRef.current){
				//console.log("same names and timeframe, not updating candles: ")
				//console.log(shortNameRef.previous, shortNameRef.current)
				//console.log(tfRef.previous, tfRef.current)
			} else {
				//console.log("different names or timeframe, updating candles: ")
				//console.log(shortNameRef.previous, shortNameRef.current)
				//console.log(tfRef.previous, tfRef.current)
				shouldUpdate = true;
			}

			//Division med 0 fix
			isLineChart += 1;
			isNotLineChart += 1;
			if(shouldUpdate){
				try {
					if(isNotLineChart/isLineChart > 2){ //Candle eller linechart
						console.log("Candlechart")
						setIsCandlestickChart(true);
						setCandlesticksOHLC(res)
					} else{
						console.log("Linechart")
						setIsCandlestickChart(false);
						for(var a in res){
							res[a].value = res[a].close;
							delete res[a].open;
							delete res[a].high;
							delete res[a].low;
							delete res[a].close;
						}
						setCandlesticksOHLC(res)
					}
				} catch(e) {
					console.log("Linechart")
					setIsCandlestickChart(false);
					for(var a in res){
						res[a].value = res[a].close;
						delete res[a].open;
						delete res[a].high;
						delete res[a].low;
						delete res[a].close;
					}
					setCandlesticksOHLC(res)
				}
			}
		}else{//historicalPriceData var null eller har längd 0
			setCandlesticksOHLC(null)
			setEnableGraph(false)
		}
	}, [historicalPriceData])

	useEffect(() => {
		if(candlesticksOHLC && candlesticksOHLC.length > 5){	
			try{
				chart.current = createChart(chartContainerRef.current, {
					width: chartContainerRef.current.clientWidth,
					height: (window.innerHeight-(offset*1.4)), //"300px", //chartContainerRef.current.clientHeight,
					layout: {
						backgroundColor: styleC.style.bgGraph,
						textColor: styleC.style.trimColor,
					},
					grid: {
						vertLines: {
						//color: "#505050",
						visible: false,
						},
						horzLines: {
						//color: "#505050",
						visible: false,
						}
					},
					crosshair: {
						mode: CrosshairMode.Normal,
					},
					priceScale: {
						borderColor: "#505050"
					},
					timeScale: {
						borderColor: "#505050",
						rightOffset: 25,
						autoScale: false,
						timeVisible: chartTimeframe == "1d" ? false : true,
						secondsVisible: false,
					},
					/*watermark: {
						color: 'rgb(255,255,255,0.1)',
						visible: true,
						text: shortName,
					}*/
				});

				chart.current.timeScale().subscribeVisibleLogicalRangeChange((e) => {
					setChartVisibleRange(chart.current.timeScale().getVisibleLogicalRange());
				})
			
				//console.log(chart.current);
			
				const candleSeries = isCandlestickChart ? chart.current.addCandlestickSeries({
					upColor: "#4bffb5",
					downColor: "#ff4976",
					borderDownColor: "#ff4976",
					borderUpColor: "#4bffb5",
					wickDownColor: "#838ca1",
					wickUpColor: "#838ca1"
				})
				:
				chart.current.addLineSeries({
					color: "#2962FF",
					lineWidth: 2,
					lastValueVisible: false,
					priceLineVisible: false,
				});

				console.log("Setting candle series data to: ")
				console.log(candlesticksOHLC)

				candleSeries.setData(candlesticksOHLC);

				try{
					var entryLevel = {
						price: Number(String(props.information[1]).replace(",",".")),
						title: 'Entry',
						color: '#fff',
						lineWidth: 1,
						axisLabelVisible: true,
						lineStyle: LineStyle.Solid,
					}
					candleSeries.createPriceLine(entryLevel);
				}catch(e){}
				try{
					var slLevel = {
						price: Number(String(props.information[2]).replace(",",".")),
						title: 'SL',
						color: '#f00',
						lineWidth: 1,
						axisLabelVisible: true,
						lineStyle: LineStyle.Solid,
					}
					candleSeries.createPriceLine(slLevel);
				}
				catch(e){
					console.log(e)
				}
				try{
					var tp1Level = {
						price: Number(String(props.information[3]).replace(",",".")),
						title: 'TP1',
						color: '#0f0',
						lineWidth: 1,
						axisLabelVisible: true,
						lineStyle: LineStyle.Solid,
					}
					candleSeries.createPriceLine(tp1Level);
				}
				catch(e){}
				try{
					var tp2Level = {
						price: Number(String(props.information[4]).replace(",",".")),
						title: 'TP2',
						color: '#0f0',
						lineWidth: 1,
						axisLabelVisible: true,
						lineStyle: LineStyle.Solid,
					}
					candleSeries.createPriceLine(tp2Level);
				}
				catch(e){}
				try{
					var tp3Level = {
						price: Number(String(props.information[5]).replace(",",".")),
						title: 'TP3',
						color: '#0f0',
						lineWidth: 1,
						axisLabelVisible: true,
						lineStyle: LineStyle.Solid,
					}
					candleSeries.createPriceLine(tp3Level);
				}
				catch(e){}
				
				const tradeDirection = props.information[8]; //"short" eller "long"
				
				var markers = [];
				for(var entr in props.information[6]){
					//console.log(props.information[6][entr])
					//console.log(props.information[6][entr].ts)
					markers.push({
						time: Number(new Date(props.information[6][entr].ts)/1000)+tzOffset/1000, 
						position: tradeDirection == "long" ? 'belowBar' : 'aboveBar',
						color: tradeDirection == "long" ? '#99ffaa' : '#ff99aa',
						shape: tradeDirection == "long" ? 'arrowUp' : 'arrowDown',
						text: 'Entry@' + roundToSignificant(props.information[6][entr].price),
						size: 1,})
					break;
				}
				
				for(var exts in props.information[7]){
					//console.log(props.information[7][exts])
					//console.log(props.information[7][exts].ts)
					markers.push({
						time: Number(new Date(props.information[7][exts].ts)/1000)+tzOffset/1000,
						position: tradeDirection == "long" ? 'aboveBar' : 'belowBar',
						color: tradeDirection == "long" ? '#ff99aa' : '#99ffaa',
						shape: tradeDirection == "long" ? 'arrowDown' : 'arrowUp',
						text: 'Exit@' + roundToSignificant(props.information[7][exts].price),
						size: 1,})
				}

				//console.log(markers)
				//console.log(props.information)
				if(markers.length > 0){
					candleSeries.setMarkers(markers);
				}

				try{
					try{
						document.getElementsByClassName("noDataClass")[0].remove();
					}catch(e){
						
					}
					
					if(shortNameRef.current != "Loading..." && showTickerName){
						var toolTip = document.createElement('div');
						toolTip.className = 'chart-legend';
						toolTip.id = 'fin';
						chartContainerRef.current.appendChild(toolTip);
						toolTip.style.display = 'block';
						toolTip.style.left = 3 + 'px';
						toolTip.style.top = 3 + 'px';
						toolTip.innerHTML =	'<div>'+shortNameRef.current+'</div>';
					}
				}catch(e){
				}
				

			}catch(e){
				console.log(e)
			}
		}else{
			try{
				chart.current = createChart(chartContainerRef.current, {
					width: chartContainerRef.current.clientWidth,
					height: (window.innerHeight-(offset*1.4)), //"300px", //chartContainerRef.current.clientHeight,
					layout: {
						backgroundColor: styleC.style.bgGraph,
						textColor: styleC.style.trimColor,
					},
					grid: {
						vertLines: {
						//color: "#505050",
						visible: false,
						},
						horzLines: {
						//color: "#505050",
						visible: false,
						}
					},
					crosshair: {
						mode: CrosshairMode.Normal,
					},
					priceScale: {
						borderColor: "#505050"
					},
					timeScale: {
						borderColor: "#505050",
						rightOffset: 25,
						autoScale: false,
						timeVisible: chartTimeframe == "1d" ? false : true,
						secondsVisible: false,
					},
					/*watermark: {
						color: 'rgb(255,255,255,0.1)',
						visible: true,
						text: shortName,
					}*/
				});
	
				chart.current.timeScale().subscribeVisibleLogicalRangeChange((e) => {
					setChartVisibleRange(chart.current.timeScale().getVisibleLogicalRange());
				})
			
				//console.log(chart.current);
			
				const candleSeries = isCandlestickChart ? chart.current.addCandlestickSeries({
					upColor: "#4bffb5",
					downColor: "#ff4976",
					borderDownColor: "#ff4976",
					borderUpColor: "#4bffb5",
					wickDownColor: "#838ca1",
					wickUpColor: "#838ca1"
				})
				:
				chart.current.addLineSeries({
					color: "#2962FF",
					lineWidth: 2,
					lastValueVisible: false,
					priceLineVisible: false,
				});
	
				const dummyData = [
					{
						close: 1,
						open: 1,
						high: 1,
						low: 1,
						time: 1647820800,
						ts: 1647817200000,
					}
				]
	
				console.log("Setting candle series data to dummy data")
				candleSeries.setData(dummyData);
	
				
			}catch(e){
				//console.log(e)
			}

			try{
				if(shortNameRef.current != "Loading..." && showTickerName){
					var toolTip = document.createElement('div');
					toolTip.className = 'chart-legend';
					toolTip.id = 'fin';
					chartContainerRef.current.appendChild(toolTip);
					toolTip.style.display = 'block';
					toolTip.style.left = 3 + 'px';
					toolTip.style.top = 3 + 'px';
					toolTip.innerHTML =	'<div>'+shortNameRef.current + " T" +'</div>';
				}
			}catch(e){
			}
		}

		try{
			if(document.getElementsByClassName("tv-lightweight-charts").length > 1){
				document.getElementsByClassName("tv-lightweight-charts")[0].remove();
				document.getElementsByClassName("chart-legend")[0].remove();
			}
		}catch(e){
		//console.log(e)
		}

		setTimeout(function() {
			try{
				if(chartVisibleRange){
					if(shortNameRef.previous == shortNameRef.current && !markers){
						console.log("Setting view to previous view")
						chart.current.timeScale().setVisibleLogicalRange(chartVisibleRange);
					}
				} else{
					var tempTimeframe;
					if(props.timef){
						if(props.timef.current || chartTimeframe){
							tempTimeframe = props.timef.current ?? chartTimeframe
						} else{
							tempTimeframe = props.timef
						}
					} else{
						tempTimeframe = "1d"
					}
					console.log("Timeframe is: ", tempTimeframe)

					console.log(markers)
					if(markers.length > 1){
						//chart.current.timeScale().setVisibleLogicalRange({from: 0, to: 0});
						console.log("Setting camera view to: ", {from: markers[0].time,	tooo: markers[markers.length-1].time})

						console.log("Rounded: ", roundToTimeframe(markers[0].time, tempTimeframe), roundToTimeframe(markers[markers.length-1].time, tempTimeframe))
						var c1 = chart.current.timeScale().timeToCoordinate(roundToTimeframe(markers[0].time, tempTimeframe))
						var c2 = chart.current.timeScale().timeToCoordinate(roundToTimeframe(markers[markers.length-1].time, tempTimeframe))
						console.log("c1, c2: ", c1, c2)

						var s = chart.current.timeScale().coordinateToLogical(c1)
						var e = chart.current.timeScale().coordinateToLogical(c2)

						var scaleOutFactor = timeframeToScaleOutFactor(tempTimeframe);

						s = s-scaleOutFactor
						e = e+scaleOutFactor

						console.log("s, e: ", s, e)

						if(c1 != null && c2 != null){
							console.log("scrolling to past markers")
							chart.current.timeScale().setVisibleLogicalRange({
								from: s,
								to: e,
							});
						} else {
							console.log("scrolling to now")
							console.log(chart.current.timeScale().getVisibleLogicalRange())
							if(chart.current.timeScale().getVisibleLogicalRange()){
								if(chart.current.timeScale().getVisibleLogicalRange().from < 0){
									chart.current.timeScale().fitContent();
								}
							} else{
								chart.current.timeScale().scrollToRealTime();
							}
						}
					}else{
						console.log("scrolling to now")
						console.log(chart.current.timeScale().getVisibleLogicalRange())
						if(chart.current.timeScale().getVisibleLogicalRange()){
							if(chart.current.timeScale().getVisibleLogicalRange().from < 0){
								chart.current.timeScale().fitContent();
							}
						} else{
							chart.current.timeScale().scrollToRealTime();
						}
					}
				}
			}catch(e){
				try {
					console.log("scrolling to now")
					console.log(chart.current.timeScale().getVisibleLogicalRange())
					if(chart.current.timeScale().getVisibleLogicalRange()){
						if(chart.current.timeScale().getVisibleLogicalRange().from < 0){
							chart.current.timeScale().fitContent();
						}
					} else{
						chart.current.timeScale().scrollToRealTime();
					}
				} catch (error) {
					//console.log(error)
				}
			}
			setShowTfSelector(true);
		}, 25)
	}, [candlesticksOHLC, props.information[1], props.information[2], props.information[3], props.information[4], props.information[5]]);


/*

	useEffect(() => {
		try{
			document.getElementById('fin').remove();
		}catch(e){
		}
		setTimeout(function() {
			try{
				document.getElementById('fin').remove();
			}catch(e){
			}
			try{
				if(shortNameRef.current != "Loading..." && showTickerName){
					var toolTip = document.createElement('div');
					toolTip.className = 'chart-legend';
					toolTip.id = 'fin';
					chartContainerRef.current.appendChild(toolTip);
					toolTip.style.display = 'block';
					toolTip.style.left = 3 + 'px';
					toolTip.style.top = 3 + 'px';
					toolTip.innerHTML =	'<div>'+shortNameRef.current+'</div>';
				}
			}catch(e){
				console.log(e)
			}
			setShowTfSelector(true);
		}, 25)
	}, [shortName, props.information])

	useEffect(() => {
		setTimeout(function() {
			try{
				if(showTickerName){
					var toolTip = document.createElement('div');
					toolTip.className = 'chart-legend';
					toolTip.id = 'fin';
					chartContainerRef.current.appendChild(toolTip);
					toolTip.style.display = 'block';
					toolTip.style.left = 3 + 'px';
					toolTip.style.top = 3 + 'px';
					toolTip.innerHTML =	'<div>'+shortNameRef.current+'</div>';
				}
			}catch(e){
				console.log(e)
			}
		}, 45)
	}, [chartTimeframe])

	useEffect(() => {
		if(enableGraph){
			setTimeout(function() {
				//console.log(chart.current.timeScale().getVisibleLogicalRange())
				try{
					if(chart.current.timeScale().getVisibleLogicalRange() == null){
						try{
							document.getElementById('fin').remove();
						}catch(e){
						}
						var toolTip = document.createElement('div');
						toolTip.className = 'chart-legend';
						toolTip.id = 'fin';
						chartContainerRef.current.appendChild(toolTip);
						toolTip.style.display = 'block';
						toolTip.style.left = 3 + 'px';
						toolTip.style.top = 3 + 'px';
						toolTip.innerHTML =	'<div>'+"No data"+'</div>';
					}
				}catch(e){
					console.log(e)
				}
			}, 5000)
		}else{
			setTimeout(function() {
				//console.log(chart.current.timeScale().getVisibleLogicalRange())
				try{
					if(chart.current.timeScale().getVisibleLogicalRange() == null && showTickerName){
						try{
							document.getElementById('fin').remove();
						}catch(e){
						}
						var toolTip = document.createElement('div');
						toolTip.className = 'chart-legend';
						toolTip.id = 'fin';
						chartContainerRef.current.appendChild(toolTip);
						toolTip.style.display = 'block';
						toolTip.style.left = 3 + 'px';
						toolTip.style.top = 3 + 'px';
						toolTip.innerHTML =	'<div>'+"No data"+'</div>';
					}
				}catch(e){
					console.log(e)
				}
			}, 500)
		}
	}, [])

*/

	  // Resize chart on container resizes.
	useEffect(() => {
		if(enableGraph){
			resizeObserver.current = new ResizeObserver(entries => {
			var { width, height } = entries[0].contentRect;
			height = window.innerHeight-(offset*1.4);
			try {
				chart.current.applyOptions({ width, height });
			} catch (error) {
				
			}
			setTimeout(() => {
				try {
					chart.current.timeScale().fitContent();
				} catch (error) {
					
				}
			}, 0);
			});
	
			resizeObserver.current.observe(chartContainerRef.current);
	
			return () => {
				try{
					resizeObserver.current.disconnect();
				}catch(e){
					console.log(e)
				}
			}
		}
	}, []);

	var defSelectValue;
	if(props.timef){
		if(props.timef.current){
			defSelectValue = props.timef.current
		} else{
			defSelectValue = props.timef
		}
	} else{
		defSelectValue = "1d"
	}

	if(!enableGraph){
		console.log("trying to remove old charts...")
		try{
			if(document.getElementsByClassName("tv-lightweight-charts").length > 0){
				document.getElementsByClassName("tv-lightweight-charts")[0].remove();
				document.getElementsByClassName("chart-legend")[0].remove();
			}
		}catch(e){
		//console.log(e)
		}
	}

	return (
		<>
			{enableGraph ?
			<div className="chartContainer" style={{top:offset}}>
				<div ref={chartContainerRef} className="newTradeChart">
					{showTfSelector ? 
					<select id='tfSelector' className='tfSelector' defaultValue={defSelectValue} onChange={(value) => handleTimeframeSelect(value)}>
						<option className='tfSelectorOption' value="5">5m</option>
						<option className='tfSelectorOption' value="15">15m</option>
						<option className='tfSelectorOption' value="1h">1H</option>
						<option className='tfSelectorOption' value="1d">1D</option>
					</select>
					:
					null
					}
				</div>
			</div>
			:
			showTickerName ?
			<div className="chartContainer" style={{top:offset}}>
				<div className="newTradeChart">
					<br></br>
					<div id='fin' className='noDataClass' style={{color: "#f9fdff", pointerEvents: 'none', zIndex: '-1'}}>&nbsp;&nbsp;&nbsp;Ingen historisk data finns tillgänglig för denna ticker/timeframe</div>
					<br></br>					
				</div>
			</div>
			:
			null
			}
		</>
	);
}