import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Overview from '../pages/Overview';
import Chart from '../pages/Chart';
import Equity from '../pages/Equity';

const Main = () => {
  return (
    <Routes> {}
      <Route path='/' element={<Overview />}></Route>
      <Route path='/chart' element={<Chart />}></Route>
      <Route path='/equity' element={<Equity />}></Route>
    </Routes>
  );
}

export default Main;