import '../App.css';
import Box from '@mui/material/Box';
import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-dropdown-select';
import Tooltip from '@mui/material/Tooltip';

const regexRemoveParenthesis = /((CASH).*)|((\(([^)]+)\).*))|\(\d+\)|(SHORT)|(LONG)/;

function removeParenthesis(a) {
    a = a.replace(regexRemoveParenthesis, ' ');
    a = a.trim();
    return a;
  }
  
function replaceBB(a) {
var ret = "";
    switch(a.split(' ')[0]){
        case "BULL": ret = a.slice(5); break;
        case "BEAR": ret = a.slice(5); break;
        default: ret = a; break;
    }
    return ret;
}

function replaceBBwithArrows(a, bb) {
var ret = "";
    switch(a.split(' ')[0]){
        case "BULL": ret = a.slice(5); break;
        case "BEAR": ret = a.slice(5); break;
    case "▼": return a;
    case "▲": return a;
        default: ret = a; break;
    }
ret = bb ? "▼ "+ret : "▲ "+ret;
    return ret;
}

/*
Instrument, kanaler, users:
  Arrayer av objekt:
    {
      ticker: "US TECH 100",
      name: "BULL US TECH 100 CASH (1€) (EXAW)",
      id: "1784"
    }

    {
      category: "Analyseramedoss",
      name: "signaler_valutor",
      longname: "Analyseramedoss - signaler_valutor",
      id: "970332260010164284"
    }

    {

    }
*/


//  ███    ███  █████  ██ ███    ██ 
//  ████  ████ ██   ██ ██ ████   ██ 
//  ██ ████ ██ ███████ ██ ██ ██  ██ 
//  ██  ██  ██ ██   ██ ██ ██  ██ ██ 
//  ██      ██ ██   ██ ██ ██   ████ 

export default function FilterMenu(props) {
  const AR = {textAlign:"right"}
  const AL = {textAlign:"left"}
  //funktionen setFilterParams finns som: props.setFilterParams;
  const instruments = props.instruments;
  const channels = props.channels;
  const users = props.users.map((a) => {return({"id": a.id, "valueUT": a.id, "name": a.name})})
  const [tempCurrentFilter, setTempCurrentFilter] = useState();
  const [filterToApply, setFilterToApply] = useState();
  const cookies = props.cookies;

  const cookieDefaultAuthorIndex = cookies.get("default_author_index")
  //console.log(cookieDefaultAuthorIndex)

  useEffect(() => {
    if(cookieDefaultAuthorIndex){
      setTempCurrentFilter({user_id: users[cookieDefaultAuthorIndex].id})
    }
  }, [])

  const instrumentPersistsRef = useRef();
  if(!instrumentPersistsRef.current){
    instrumentPersistsRef.current = props.instruments;
  }

  const selectBoxInstrument = (instruments, handleChange) => (
    <Select 
    options={instruments} 
    onChange={(values) => handleChange(values, 1)} 
    placeholder="Instrument"
    searchable={true}
    create={true}
    valueField="ticker"
    labelField="ticker"
    multi={true}
    className={"selectBoxDropdown"}
    clearable={true}
    />
  )
  
  const selectBoxChannel = (channels, handleChange) => (
    <Select 
    options={channels} 
    onChange={(values) => handleChange(values, 2)} 
    placeholder="Kanal"
    searchable={true}
    create={true}
    valueField="id"
    labelField="longname"
    multi={true}
    className={"selectBoxDropdown"}
    clearable={true}
    />
  )
  
  const selectBoxUser = (users, handleChange) => (
    <Select 
    options={users}
    onChange={(values) => handleChange(values, 3)} 
    placeholder="Användare"
    searchable={true}
    create={true}
    valueField="id"
    labelField="name"
    multi={true}
    className={"selectBoxDropdown"}
    clearable={true}
    values={[users[cookieDefaultAuthorIndex]]}
    />
  )
  
  const selectBoxTradeType = (handleChange) => (
    <Select 
    options={[
      {id: "stocks", valueTT: "stocks", label: "Aktiebolag"},
      {id: "commodities", valueTT: "commodities", label: "Råvaror"},
      {id: "indices", valueTT: "indices", label: "Index"},
      {id: "currencies", valueTT: "currencies", label: "Valutor"},
      {id: "crypto", valueTT: "crypto", label: "Krypto"},
      {id: "fire", valueTT: "fire", label: "Fire"},
    ]}
    onChange={(values) => handleChange(values, 4)} 
    placeholder="Tidshorisont"
    searchable={true}
    create={true}
    valueField="id"
    labelField="label"
    multi={true}
    className={"selectBoxDropdown"}
    clearable={true}
    />
  )

  const handleChange = (a, inputType) => {
    if(a.length == 0){
      switch (inputType) {
        case 1:
          try{
            var tmpFilter = {...tempCurrentFilter};
            delete tmpFilter.ticker;
            setTempCurrentFilter(tmpFilter);
          }catch(e){
            console.warn("error removing properties from tmpFilter object: ")
            console.warn(e)
          }
          return;
        case 2:
          try{
            var tmpFilter = {...tempCurrentFilter};
            delete tmpFilter["channel.name"];
            delete tmpFilter["channel.category"];
            setTempCurrentFilter(tmpFilter);
          }catch(e){
            console.warn("error removing properties from tmpFilter object: ")
            console.warn(e)
          }
          return;
        case 3:
          try{
            var tmpFilter = {...tempCurrentFilter};
            delete tmpFilter.user_id;
            setTempCurrentFilter(tmpFilter);
          }catch(e){
            console.warn("error removing properties from tmpFilter object: ")
            console.warn(e)
          }
          return;
        case 4:
          try{
            var tmpFilter = {...tempCurrentFilter};
            delete tmpFilter.trade_type;
            setTempCurrentFilter(tmpFilter);
          }catch(e){
            console.warn("error removing properties from tmpFilter object: ")
            console.warn(e)
          }
          return;
        default:
          break;
      }
    }

    var tmp = "";
    var tmpChannelCategory = "";
    var type = 0;
    for(var q in a){
      //console.log(a[q])
      if(a[q].hasOwnProperty('ticker')){
        tmp+=a[q].ticker + ",";
        type = 1;
      } else if(a[q].hasOwnProperty('category')){
        tmp+=a[q].name + ",";
        tmpChannelCategory+=a[q].category + ",";
        type = 2;
      } else if(a[q].hasOwnProperty('valueUT')){
        tmp+=a[q].id + ",";
        type = 3;
      } else if(a[q].hasOwnProperty('valueTT')){
        tmp+=a[q].valueTT + ",";
        type = 4;
      }
    }
    if(tmp.length > 0){
      tmp = tmp.slice(0,-1);
    }
    if(tmpChannelCategory.length > 0){
      tmpChannelCategory = tmpChannelCategory.slice(0,-1);
    }
    if(type == 1){
      var tmpFilter = {...tempCurrentFilter, ticker: tmp};
    } else if(type == 2){
      var tmpFilter = {...tempCurrentFilter, "channel.name": tmp, "channel.category": tmpChannelCategory};
    } else if(type == 3){
      var tmpFilter = {...tempCurrentFilter, user_id: tmp};
    } else if(type == 4){
      var tmpFilter = {...tempCurrentFilter, trade_type: tmp};
    }
    //console.log("tmpFilter: ")
    //console.log(tmpFilter)
    setTempCurrentFilter(tmpFilter);
  }

  useEffect(() => {
    console.log("tempCurrentFilter: ")
    console.log(tempCurrentFilter)
  }, [tempCurrentFilter])

  const applyFilterToMain = () => {
    var long = document.getElementById('filterLong').checked;
    var short = document.getElementById('filterShort').checked;
    var before = document.getElementById('filterDateBefore').value;
    var after = document.getElementById('filterDateAfter').value;
    var open = document.getElementById('filterOpen').checked;
    var closed = document.getElementById('filterClosed').checked;

    console.log("before: ", before)
    console.log("after: ", after)

    var tmp = {...tempCurrentFilter}
    
    if(before){
      tmp = {...tmp, to: before}
    }
    if(after){
      tmp = {...tmp, from: after}
    } else {
      tmp = {...tmp, from: "2023-01-01"}
    }
    if(!open){
      tmp = {...tmp, exit: "exists"}
    }
    if(!closed){
      tmp = {...tmp, exit: "!exists"}
    }
    if(long && short){
      //gör inget
    } else if(long && !short){
      tmp = {...tmp, direction: "long"}
    } else if(short && !long){
      tmp = {...tmp, direction: "short"}
    } else if(!short && !long){
      tmp = {...tmp, from: "2090-01-01"}
    }

    setFilterToApply(tmp);
  }

  useEffect(() => {
    if(filterToApply !== undefined){
      console.log("Sending filter to main:")
      console.log(filterToApply)
      props.setFilterParams(filterToApply);
      props.setLoadAdditionalTrades(3);
    }
  }, [filterToApply])

  const resetFilterToMain = () => {
    document.getElementById('filterLong').checked = true;
    document.getElementById('filterShort').checked = true;
    document.getElementById('filterDateBefore').value = "";
    document.getElementById('filterDateAfter').value = "2023-01-01";
    document.getElementById('filterOpen').checked = true;
    document.getElementById('filterClosed').checked = true;

    //document.getElementById("filterInst").setState([])
    console.log(document.getElementById("filterChannel"))
    console.log(document.getElementById("filterUser"))

    console.log("Sending reset filter")
    props.setFilterParams({from: "2023-01-01"});
    props.setLoadAdditionalTrades(0);
  }

  //<button onClick={() => resetFilterToMain()} style={{padding: "5px", fontSize:"1em"}}>Återställ filter</button>

  const tzOffset = (new Date().getTimezoneOffset())*-60000; //Returnerar -60 för 60 minuters skillnad mot GMT // Gör om till millisek!
  const currTime = new Date(Date.now()+tzOffset).toISOString().slice(0, 16)

  return (
    <div>
      <p>Filter</p>
      Instrument
      {selectBoxInstrument(instrumentPersistsRef.current, handleChange)}<input key={"filterInst"} type="text" id="filterInst" name="filterInst" disabled={true} hidden={true} />
      <br />
      Kanal
      {selectBoxChannel(channels, handleChange)}<input key={"filterChannel"} type="text" id="filterChannel" name="filterChannel" disabled={true} hidden={true} />
      <br />
      Användare
      {selectBoxUser(users, handleChange)}<input key={"filterUser"} type="text" id="filterUser" name="filterUser" disabled={true} hidden={true} />
      <br />
      Typ av instrument
      {selectBoxTradeType(handleChange)}<input key={"filterTradeType"} type="text" id="filterTradeType" name="filterTradeType" disabled={true} hidden={true} />
      <br />
      <table>
        <tbody>
          <tr>
            <td style={AR}>Long: </td>
            <td style={AL}><input type="checkbox" id="filterLong" name="filterLong" defaultChecked/></td>
          </tr>
          <tr>
            <td style={AR}>Kort: </td>
            <td style={AL}><input type="checkbox" id="filterShort" name="filterShort"  defaultChecked/></td>
          </tr>
          <tr>
            <td style={AR}>Från: </td>
            <td style={AL}><input type="date" id="filterDateAfter" name="filterDateAfter" defaultValue={"2023-01-01"} max={"2999-12-13"}/></td>
          </tr>
          <tr>
            <td style={AR}>Till: </td>
            <td style={AL}><input type="date" id="filterDateBefore" name="filterDateBefore" max={"2999-12-13"}/></td>
          </tr>
          <tr>
            <td style={AR}>Öppna trades: </td>
            <td style={AL}><input type="checkbox" id="filterOpen" name="filterOpen"  defaultChecked/></td>
          </tr>
          <tr>
            <td style={AR}>Stängda trades: </td>
            <td style={AL}><input type="checkbox" id="filterClosed" name="filterClosed" /></td>
          </tr>
        </tbody>
      </table>
      <br />
        <Tooltip title="Applicera filtret och hämta alla trades">
          <button onClick={() => applyFilterToMain()} style={{padding: "5px", fontSize:"1em", display:"inline-block", width:"50%"}}>Applicera filter</button>
        </Tooltip>
        <Tooltip title="Återställ filtret">
          <button onClick={() => resetFilterToMain()} style={{padding: "5px", fontSize:"1em", display:"inline-block", width:"50%"}}>Återställ</button>
        </Tooltip>
      <br />
      <br />
      <br />
    </div>
  );
};