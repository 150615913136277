import '../App.css';
import Box from '@mui/material/Box';
import React, { useState, useEffect, useRef } from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { roundToSignificant } from './helpFunc';
import { StyleContext } from '../App';

const regexRemoveParenthesis = /((CASH).*)|((\(([^)]+)\).*))|\(\d+\)|(SHORT)|(LONG)/;

function removeParenthesis(a) {
    a = a.replace(regexRemoveParenthesis, ' ');
    a = a.trim();
    return a;
  }
  
function replaceBB(a) {
var ret = "";
    switch(a.split(' ')[0]){
        case "BULL": ret = a.slice(5); break;
        case "BEAR": ret = a.slice(5); break;
        default: ret = a; break;
    }
    return ret;
}

function replaceBBwithArrows(a, bb) {
var ret = "";
    switch(a.split(' ')[0]){
        case "BULL": ret = a.slice(5); break;
        case "BEAR": ret = a.slice(5); break;
        case "▼": return a;
        case "▲": return a;
        default: ret = a; break;
    }
ret = bb ? "▼ "+ret : "▲ "+ret;
    return ret;
}

export default function CollapsibleTableRow(props) {
  const a = props.data;
  const exits = props.exits;

  const styleC = React.useContext(StyleContext);

  const tzOffset = (new Date().getTimezoneOffset())*-60000; //Returnerar -60 för 60 minuters skillnad mot GMT // Gör om till millisek!

  //console.log("abc")
  //console.log(exits)
  //console.log(a)
  const [open, setOpen] = useState(false);

  const fullscreenURL = '/chart/?db=' + props.activeDb + '&trade=' + a.entry.msg.id

  var tradeHorizon = a.trade_type ?? null;

   switch (tradeHorizon) {
    case "scalp":
      tradeHorizon = "Scalp"
      break;
    case "daytrade":
      tradeHorizon = "Daytrade"
      break;
    case "swing":
      tradeHorizon = "Swing"
      break;
    case "longterm":
      tradeHorizon = "Långsiktig"
      break;
    case "monthly":
      tradeHorizon = "Månadens"
      break;
    case "fire":
      tradeHorizon = "FIRE"
      break;
    default:
      tradeHorizon = null;
      break;
  }
  

  const tickerHoverTooltip = (
    <>
      <div className={"tickerHoverTooltip"}>
        <span>{a.ticker} {a.name ? ' - ' + a.name : ''}</span>
        <br></br>
        {
          tradeHorizon ? <><span>Tidshorisont: {tradeHorizon}</span> <br></br></> : null
        }
        <hr style={{borderTop: "1px solid white"}}></hr>
        <span>Kanal: {a.channel.name}</span> <br></br>
        <span>Entry: {a.entry.price}</span> <br></br>
        {
          a.sl ? <><span>SL: {roundToSignificant(a.sl)}</span> <br></br></> : null
        }
        {
          a.tp1 ? <><span>TP1: {roundToSignificant(a.tp1)}</span> <br></br></> : null
        }
        {
          a.tp2 ? <><span>TP2: {roundToSignificant(a.tp2)}</span> <br></br></> : null
        }
        {
          a.tp3 ? <><span>TP3: {roundToSignificant(a.tp3)}</span> <br></br></> : null
        }
        {
          a.user ? a.user.name ? <><span>Author: {a.user.name}</span> <br></br></> : 
          a.entry.msg.author ? <><span>Author: {a.entry.msg.author}</span> <br></br></> : null : null
        }
      </div>
    </>
  )

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: '0px' } }} className='openTradesTableTr' style={{backgroundColor: a.exit ? styleC.style.bgColor : styleC.style.bgLighter, cursor:"default", borderRight:"1px"}}>
            <td onClick={() => setOpen(!open)}>
              <Tooltip title={tickerHoverTooltip} placement="right" arrow>
                <span style={{color: styleC.style.trimColor, cursor: (!exits.length > 0) ? "default" : "pointer"}}>{replaceBBwithArrows(a.ticker, a.bear == "BEAR")}</span>
              </Tooltip>
            </td>
            <td>
              <span onClick={() => setOpen(!open)} style={{color: styleC.style.trimColor, left:"-32px", position:"relative", width:"0px", cursor:"pointer", letterSpacing:"0px", display:"inline-block"}}>{open ? "⚊" : "☰"}</span>
              <Tooltip title={"Öppna graf"} disableInteractive>
                <span style={{left:"-55px", position:"relative", width:"0px", cursor:"pointer", letterSpacing:"0px", display:"inline-block"}}><a href={fullscreenURL} style={{color: styleC.style.trimColor, textDecoration: "none"}}>🝯</a></span>
              </Tooltip>
              <Tooltip title={new Date(a.ts).toISOString().slice(0, 19).replace("T", " ")} enterDelay={10} leaveDelay={0} disableInteractive>
                <span style={{color: styleC.style.trimColor, width:"100%", display:"inline-block", textAlign:"center"}}>{new Date(a.ts).toISOString().slice(0, 10)}</span>
              </Tooltip>
            </td>


          {
          props.authType == 0 ?
            !a.exit ? 
              <>
                <td><button style={{color: styleC.style.trimColor}} onClick={() => {
                  props.setSelectedTrade(null); 
                  props.handleReset(); 
                  props.genChangeInfo(styleC, a, props.setSelectedTrade, props.chartContainerRef, props.chartRef, props.resizeObserverRef); 
                  props.setNewTradeGen(null); 
                  props.setNewTradeGraphTicker({ticker: '', source: '', id: ''});
                  setOpen(true);
                  }}>♦</button></td>
            
                <td><button style={{color: styleC.style.trimColor}} onClick={() => {
                  props.setSelectedTrade(null); 
                  props.handleReset(); 
                  props.genMakeExit(styleC, a, props.setSelectedTrade, props.chartContainerRef, props.chartRef, props.resizeObserverRef); 
                  props.setNewTradeGen(null); 
                  props.setNewTradeGraphTicker({ticker: '', source: '', id: ''}); 
                  setOpen(true);
                  }}>X -</button></td>
            
                <td><button style={{color: styleC.style.trimColor}} onClick={() => {
                  props.setSelectedTrade(null); 
                  props.handleReset(); 
                  props.genMoveLevels(styleC, a, props.setSelectedTrade, props.chartContainerRef, props.chartRef, props.resizeObserverRef); 
                  props.setNewTradeGen(null); 
                  props.setNewTradeGraphTicker({ticker: '', source: '', id: ''});
                  setOpen(true);
                  }}>↓↑ +</button></td>
              </>
              : 
              <>
                <td colSpan={6}><span style={{textAlign:"center", width:"100%", display:"inline-block", color: styleC.style.trimDarker}}>Stängd trade</span></td>
              </>
          :
          null
          }
          </TableRow>
          <TableRow style={{cursor:"default"}}>
              <TableCell style={{color: styleC.style.trimColor, paddingBottom: 0, paddingTop: 0, borderBottom:"0px", borderTop:"0px", background: a.exit ? 'linear-gradient(' + styleC.style.bgColorLighter + ', ' + styleC.style.bgColorDarker + ')' : 'linear-gradient(' + styleC.style.bgColorDarker + ', ' + styleC.style.bgColorLighter + ')'}} colSpan={6}>
                  <Collapse in={open} timeout="auto" unmountOnExit orientation="vertical">
                      <Box sx={{ margin: 1 }}>
                          <Typography style={{color: styleC.style.trimColor}} variant="h6" gutterBottom component="div">
                              Entry
                          </Typography>
                          <Table size="small">
                              <TableHead>
                                  <TableRow>
                                  <TableCell>Datum</TableCell>
                                  <TableCell>Gav</TableCell>
                                  <TableCell align="right">Ek kvar</TableCell>
                                  <TableCell align="right">Original ek</TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow key={a._id}>
                                    <TableCell component="th" scope="row">{String(new Date(a.entry.ts+tzOffset).toISOString().slice(0, 19).replace('T', '  ⚋  '))}</TableCell>
                                    <TableCell>{(roundToSignificant((a.entry.gav ?? a.entry.price)*10000)/10000)}</TableCell>
                                    <TableCell align="right">{!a.exit ? (a.ek ?? null) : 0}</TableCell>
                                    <TableCell align="right">{a.org_size}</TableCell>
                                </TableRow>
                              </TableBody>
                          </Table>
                          <br></br>
                          {
                            exits.length > 0 ?
                          <>
                            <Typography style={{color: styleC.style.trimColor}} variant="h6" gutterBottom component="div">
                                Avslut
                            </Typography>
                            <Table style={{color: styleC.style.trimColor}} size="small">
                                <TableHead>
                                    <TableRow>
                                      <TableCell variant='body' color={styleC.style.trimColor}>Datum</TableCell>
                                      <TableCell variant='body'>Exitpris</TableCell>
                                      <TableCell variant='body' align="right">Ek</TableCell>
                                      <TableCell variant='body' align="right">Gain</TableCell>
                                      {props.authType == 0 ?
                                        <TableCell width={"1%"}>Ändra</TableCell>
                                        :
                                        null
                                      }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {exits.map((e) => (
                                    <TableRow key={e._id}>
                                        <TableCell component="th" scope="row">{String(new Date(e.exit ? e.exit.ts ? e.exit.ts+tzOffset : null : null).toISOString().slice(0, 19).replace('T', '  ⚋  '))}</TableCell>
                                        <TableCell>{e.exit ? (roundToSignificant(e.exit.price*10000)/10000) : null}</TableCell>
                                        <TableCell align="right">{e.ek ?? null}</TableCell>
                                        <TableCell align="right">{e.gain ? (Math.round(e.gain*100)/100).toFixed(2) : null}</TableCell>
                                        {props.authType == 0 ?
                                          <TableCell align="right">
                                            <button style={{color: styleC.style.trimColor}} onClick={() => {props.handleReset(); props.genChangeInfo(styleC, e, props.setSelectedTrade, props.chartContainerRef, props.chartRef, props.resizeObserverRef, true); props.setNewTradeGen(null); props.setNewTradeGraphTicker(null);}}>♦</button>
                                          </TableCell>
                                          :
                                          null
                                        }
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                          </>
                          :
                          null
                          }
                      </Box>
                  </Collapse>
              </TableCell>
          </TableRow>
    </>
  );
};