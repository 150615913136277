import '../App.css';
import Box from '@mui/material/Box';
import React, { useState, useEffect, useRef } from 'react';

import lookupInstrument from "../components/Lookup.js"
import CandleChart from '../components/CandleChart';

import Cookies from 'universal-cookie';
import { ACTIVE_DB, WEBSOCKET_URL, IO_URL, DISCORD_OAUTH_LINK, THIS_URL } from '../components/envVars'
import { StyleContext } from '../App';

const regexRemoveParenthesis = /((CASH).*)|((\(([^)]+)\).*))|\(\d+\)|(SHORT)|(LONG)/;
const regexRemoveTradingViewTimeframe = /\(\d+\)$/;
var g_access_token = "";

function removeParenthesis(a) {
  a = a.replace(regexRemoveParenthesis, ' ');
  a = a.trim();
  return a;
}

function replaceBB(a) {
  var ret = "";
	switch(a.split(' ')[0]){
		case "BULL": ret = a.slice(5); break;
		case "BEAR": ret = a.slice(5); break;
		default: ret = a; break;
	}
	return ret;
}

function replaceTradingViewTimeframe(a) {
  a = a.replace(regexRemoveTradingViewTimeframe, '');
  a = a.trim();
  return a;
}

function replaceBBwithArrows(a, bb) {
  var ret = "";
	switch(a.split(' ')[0]){
		case "BULL": ret = a.slice(5); break;
		case "BEAR": ret = a.slice(5); break;
    case "▼": return a;
    case "▲": return a;
		default: ret = a; break;
	}
  ret = bb ? "▼ "+ret : "▲ "+ret;
	return ret;
}

var activeDb = "788458344721547334"; //default till vår testdatabas

const defaultFetchLimit = 10;
const defaultFetchSort = ">ts";

//var activeDb = "871087817948798996" //Testkanalen

//759569532221653053 <- AMO


//   █████  ██████  ██ 
//  ██   ██ ██   ██ ██ 
//  ███████ ██████  ██ 
//  ██   ██ ██      ██ 
//  ██   ██ ██      ██ 

//GET ALL POSITIONS
async function getTradePositions(setTradePositions, filterParams, fetchLimit, fetchSort){
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
      body: JSON.stringify({
        db: activeDb,
        ...filterParams,
        limit: fetchLimit ?? defaultFetchLimit,
        sort: fetchSort ?? defaultFetchSort
      })
    };
  
    //console.log(requestOptions)
    const response = await fetch(IO_URL+'/getTrades/', requestOptions);
    if(response.statusText === "Method Not Allowed"){
      console.log(response)
      setTradePositions({})
      return;
    }
    const rec = await response.json();
    setTradePositions(rec)
    //console.log(rec)
  }
  catch(e){
    console.log(e)
  }
}

//GET CHANNELS
async function getChannels(setChannels) {
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
      body: JSON.stringify({
        db: activeDb,
      })
    };
  
    //console.log(requestOptions)
    const response = await fetch(IO_URL+'/getChannels/', requestOptions);
    const rec = await response.json();
    setChannels(rec)
    //console.log(rec)
  }
  catch(e){
    console.log(e)
  }
}

//GET USERS
async function getUsers(setUsers) {
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
      body: JSON.stringify({
        db: activeDb,
      })
    };
  
    //console.log(requestOptions)
    const response = await fetch(IO_URL+'/getUsers/', requestOptions);
    const rec = await response.json();
    setUsers(rec)
    //console.log(rec)
  }
  catch(e){
    console.log(e)
  }
}

//GET INSTRUMENT INFO (ENTRY)
async function getInstrumentInfoEntry(instrument, docElementPrice, docElementInstrument, docElementTicker, setNewTradeGraphTicker) {
  if(instrument === ""){
    document.getElementById(docElementPrice).value = ""
    document.getElementById(docElementInstrument).value = ""
    document.getElementById(docElementTicker).value = ""
    try{
      setNewTradeGraphTicker(instrument)
    }catch(e){
      console.log(e)
    }
  }
  try{
    instrument = lookupInstrument(instrument);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
      body: JSON.stringify({
        ticker: instrument
      })
    };
  
    //console.log(requestOptions)
    const response = await fetch(IO_URL+'/stockInfo/', requestOptions);
    const rec = await response.json();
    console.log(rec)
    document.getElementById(docElementPrice).value = rec[0].res.regularMarketPrice
    document.getElementById(docElementInstrument).value = rec[0].res.shortName + 
    (rec[0].res.exchangeDataDelayedBy > 0 ? " (15m delay)" : "");
    document.getElementById(docElementTicker).value = instrument;
    try{
      setNewTradeGraphTicker(instrument)
    }catch(e){
      console.log(e)
    }
  }
  catch(e){
    console.warn("Officiellt instrument hittades inte, kontakta admin.")
  }
}

//GET INSTRUMENT INFO (ENTRY)
async function getInstrumentInfoExit(instrument, docElementPrice) {
  try{
    instrument = lookupInstrument(instrument);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
      body: JSON.stringify({
        ticker: instrument
      })
    };
  
    //console.log(requestOptions)
    const response = await fetch(IO_URL+'/stockInfo/', requestOptions);
    const rec = await response.json();
    document.getElementById(docElementPrice).value = rec[0].res.regularMarketPrice
  }
  catch(e){
    console.log(e)
  }
}

//SEND TRADE UPDATE
async function sendTradeUpdate(params){
  console.log("Skickar följande objekt: ")
  console.log(params)
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
      body: JSON.stringify({
        db: activeDb,
        ...params
      })
    };

    const response = await fetch(IO_URL+'/tradeAPI/', requestOptions);
    const rec = await response.json();
    console.log("rec:")
    console.log(rec)
  }
  catch(e){
    console.log(e)
  }
}

async function getInstrumentHistorical(instrument, setHistoricalPriceData, setShortName, chartTimeframe) {
	try{
		const requestOptions = {
			method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
			body: JSON.stringify({
				ticker: instrument,
				timeframe: chartTimeframe
			})
		};

		//console.log(requestOptions)
		const response = await fetch(IO_URL+'/stockInfo/', requestOptions);
		const rec = await response.json();
		setHistoricalPriceData(rec[0].res.historical);
		setShortName(rec[0].res.shortName);
	}
	catch(e){
		console.log(e)
	}
}

async function getTrade(params, setNewTradeGraphTicker, setNewTradeGraphEntry, setNewTradeGraphSl, setNewTradeGraphTp1, setNewTradeGraphTp2, setNewTradeGraphTp3, setNewTradeGraphEntries, setNewTradeGraphExits, setNewTradeGraphDirection, setGraphTimeframe) {
	try{
    console.log(params)
		const requestOptions = {
			method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + g_access_token},
			body: JSON.stringify({
				...params
			})
		};

		//console.log(requestOptions)
		const response = await fetch(IO_URL+'/getTrade/', requestOptions);
		const rec = await response.json();
    console.log("rec:")
    console.log(rec)
		setNewTradeGraphTicker({ticker: rec[0].ticker, source: rec[0].data_source, id: rec[0].data_id});
		setNewTradeGraphEntry(rec[0].entry.price);
		setNewTradeGraphSl(rec[0].sl);
		setNewTradeGraphTp1(rec[0].tp1);
		setNewTradeGraphTp2(rec[0].tp2);
		setNewTradeGraphTp3(rec[0].tp3);
    let dir;
    if(rec[0].bear){
      dir = rec[0].bear == "BEAR" ? "short" : "long"
    } else if(rec[0].direction){
      dir = rec[0].direction == "short" ?  "short" : "long"
    } else {
      dir = "long"
    }
    setNewTradeGraphDirection(dir);
    let tf;
    if(rec[0].timeframe){
      tf = rec[0].timeframe
    } else{
      tf = "1d"
    }
    setGraphTimeframe(tf);
    const tzOffset = (new Date().getTimezoneOffset())*-60000; //Returnerar -60 för 60 minuters skillnad mot GMT // Gör om till millisek!
    var entries = [];
    try{
      for(var a in rec){
        entries.push({price: rec[a].entry.price, ts: String(new Date(rec[a].entry.ts+tzOffset).toISOString().slice(0, 16))})
      }
    }catch(e){
    }
    
    var exits = [];
    try{
      for(var a in rec){
        exits.push({price: rec[a].exit.price, ts: String(new Date(rec[a].exit.ts+tzOffset).toISOString().slice(0, 16))})
      }
    }catch(e){
    }
    
    setNewTradeGraphEntries(entries)
    setNewTradeGraphExits(exits)

    //const tzOffset = (new Date().getTimezoneOffset())*-60000; //Returnerar -60 för 60 minuters skillnad mot GMT // Gör om till millisek!
    //const currTime = String(new Date(rec[a].exit.ts+tzOffset).toISOString().slice(0, 16))
	}
	catch(e){
		console.log(e)
    setNewTradeGraphTicker("ERROR")
	}
} 

//  ███    ███  █████  ██ ███    ██ 
//  ████  ████ ██   ██ ██ ████   ██ 
//  ██ ████ ██ ███████ ██ ██ ██  ██ 
//  ██  ██  ██ ██   ██ ██ ██  ██ ██ 
//  ██      ██ ██   ██ ██ ██   ████ 


//MAIN FUNCTION
export default function Chart() {
  document.title = "Fullscreen chart";

  const cookies = new Cookies();
  
  const chartContainerRef = useRef();
  const chartRef = useRef();
  const resizeObserverRef = useRef();

  const [newTradeGraphTicker, setNewTradeGraphTicker] = useState();
  const [newTradeGraphEntry, setNewTradeGraphEntry] = useState();
  const [newTradeGraphSl, setNewTradeGraphSl] = useState();
  const [newTradeGraphTp1, setNewTradeGraphTp1] = useState();
  const [newTradeGraphTp2, setNewTradeGraphTp2] = useState();
  const [newTradeGraphTp3, setNewTradeGraphTp3] = useState();
  const [newTradeGraphEntries, setNewTradeGraphEntries] = useState();
  const [newTradeGraphExits, setNewTradeGraphExits] = useState();
  const [newTradeGraphDirection, setNewTradeGraphDirection] = useState();

  const styleC = React.useContext(StyleContext);
  
  const [graphTimeframe, setGraphTimeframe] = useState();

  const [loaded, setLoaded] = useState(false);
  const [tickerError, setTickerError] = useState(false);

  var activeURLDb;
  var activeChannel;
  var activeTrade;
  var activeToken;
  const urlsp = new URLSearchParams(window.location.search);

  useEffect(() => {
    if(g_access_token === ""){
      console.log("checking for cookies")
      try{
        const access_token = cookies.get("access_token")
        console.log(access_token)
        if(access_token){
          g_access_token = access_token;
        } else{
          try{
            activeToken = urlsp.get("token");
          }catch(e){
            console.log(e)
          }
          if(activeToken!==undefined){
            cookies.set("access_token", activeToken, {
              expires: new Date("2038-01-19")
            })
            g_access_token = activeToken;
            } else{
              window.location.replace(THIS_URL);
            }
        }
      }catch(e){
        console.log(e)
      }    
    }
  }, [])

  useEffect(() => {
    try{
      activeURLDb = urlsp.get("db");
      console.log("manual db: ", activeURLDb)
    }catch(e){
      console.log(e)
    }
    try{
      activeChannel = urlsp.get("channel");
    }catch(e){
      console.log(e)
    }
    try{
      activeTrade = urlsp.get("trade");
    }catch(e){
      console.log(e)
    }
    try{
      activeToken = urlsp.get("token");
    }catch(e){
      console.log(e)
    }

    var params = {};
    if(activeURLDb){
      params = {...params, db: activeURLDb};
    } else {
      params = {...params, db: activeDb};
    }
    if(activeChannel){
      params = {...params, channel: activeChannel};
    }
    if(activeToken){
      params = {...params, token: activeToken};
    }
    if(activeTrade){
      params = {...params, trade: activeTrade};
    } else {

    }

    getTrade(params, setNewTradeGraphTicker, setNewTradeGraphEntry, setNewTradeGraphSl, setNewTradeGraphTp1, setNewTradeGraphTp2, setNewTradeGraphTp3, setNewTradeGraphEntries, setNewTradeGraphExits, setNewTradeGraphDirection, setGraphTimeframe)

  }, [])

  useEffect(() => {
    if(newTradeGraphTicker && newTradeGraphTicker != "ERROR"){
      console.log("ticker OK")
      setLoaded(true);
    }
    if(newTradeGraphTicker == "ERROR"){
      console.log("ticker error")
      setTickerError(true);
    }
  }, [newTradeGraphTicker, newTradeGraphEntry, newTradeGraphSl, newTradeGraphTp1, newTradeGraphTp2, newTradeGraphTp3])

  useEffect(() => {
    let timer1 = setTimeout(() => {console.log("Timeout checker finished");if(!setLoaded)setTickerError(true)}, 15000);
      return () => {
        clearTimeout(timer1);
      };
  }, [])

  return (
    <main>
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <div className="full-container">
            {
              tickerError ? <div id='fin' style={{color: "white"}}>Ticker error</div>
              : loaded ? <CandleChart cContRef={chartContainerRef} cRef={chartRef} resObsRef={resizeObserverRef} timef={graphTimeframe} information={[newTradeGraphTicker, newTradeGraphEntry, newTradeGraphSl, newTradeGraphTp1, newTradeGraphTp2, newTradeGraphTp3, newTradeGraphEntries, newTradeGraphExits, newTradeGraphDirection]}/>
              : <div style={{color: "white"}}>Loading...</div>
            }
          </div>
        </Box>
      </div>
    </main>
  );
}