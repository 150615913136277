import { createChart, CrosshairMode, LineStyle } from 'lightweight-charts';
import React, { useEffect, useState, useRef } from 'react';
import { roundToSignificant } from './helpFunc'
import { StyleContext } from '../App';


function roundToTimeframe(num, timeframe){

	let secondsDivisor
	switch (timeframe) {
		case 5:
			secondsDivisor = 300;
			break;
	
		case 15:
			secondsDivisor = 900;
			break;
	
		case "1h":
			secondsDivisor = 3600;
			break;

		case "1d":
			secondsDivisor = 86400;
			break;

		default:
			secondsDivisor = 86400;
			break;
	}
	return(Math.round((num)/secondsDivisor)*secondsDivisor)
}

const regexRemoveParenthesis = /((CASH).*)|((\(([^)]+)\).*))|\(\d+\)|(SHORT)|(LONG)/;

function removeParenthesis(a) {
  a = a.replace(regexRemoveParenthesis, ' ');
  a = a.trim();
  return a;
}


//  ███    ███  █████  ██ ███    ██ 
//  ████  ████ ██   ██ ██ ████   ██ 
//  ██ ████ ██ ███████ ██ ██ ██  ██ 
//  ██  ██  ██ ██   ██ ██ ██  ██ ██ 
//  ██      ██ ██   ██ ██ ██   ████ 


export default function LineChartDates(props) {
	const chartContainerRef = props.cContRef;
	const chart = props.cRef;
	const resizeObserver = props.resObsRef;

	const styleC = React.useContext(StyleContext);

	console.log(props)

	var xAxisIterator = 0;

	useEffect(() => {
		if(props.chartData && props.chartData.length > 0){
			chart.current = createChart(chartContainerRef.current, {
				width: chartContainerRef.current.clientWidth,
				height: chartContainerRef.current.clientHeight, //window.innerHeight, //"300px",
				layout: {
					backgroundColor: styleC.style.bgGraph,
					textColor: styleC.style.trimColor,
				},
				grid: {
					vertLines: {
					//color: "#505050",
					visible: false,
					},
					horzLines: {
					color: styleC.style.bgGraphDarker,
					visible: true,
					}
				},
				crosshair: {
					mode: CrosshairMode.Magnet,
					// hide the horizontal crosshair line
					horzLine: {
						visible: false,
					},
					// hide the vertical crosshair label
					vertLine: {
						labelVisible: true,
						visible: false,
					},
				},
				priceScale: {
					borderColor: styleC.style.trimColor
				},
				timeScale: {
					borderColor: styleC.style.trimColor,
					rightOffset: 5,
					autoScale: false,
					timeVisible: false,
					secondsVisible: false,
					visible: true,
					ticksVisible: true,
				},
				localization: {
					priceFormatter: function(price) {
						return roundToSignificant(price) + "%";
					}
				}
			});
		
			const series = chart.current.addLineSeries({
				color: styleC.style.lineGraph,
				lineWidth: 2,
				lastValueVisible: false,
				priceLineVisible: false,
			});

			console.log("Setting series data to: ")
			console.log(props.chartData)

			series.setData(props.chartData);

			series.createPriceLine({price: 0, title: '', color: '#777', lineWidth: 1, axisLabelVisible: false});

			try{
				if(document.getElementsByClassName("tv-lightweight-charts").length > 2){
					document.getElementsByClassName("tv-lightweight-charts")[2].remove();
					document.getElementsByClassName("chart-legend")[2].remove();
				}
			}catch(e){
				//console.log(e)
			}
		}
	}, [props]);

	
	return (
		<div className="chartContainer">
			<div
				ref={chartContainerRef}
				className="lineChart"
				style={{height: "400px"}}
			>
			</div>
		</div>
	);
}