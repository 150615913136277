import '../App.css';
import React, { useState, useEffect, useRef } from 'react';

import LineChart from './LineChart';
import { convertSecondsToSignificantTimeframe, roundToSignificant } from './helpFunc';
import LineChartDates from './LineChartDates';

const regexRemoveParenthesis = /((CASH).*)|((\(([^)]+)\).*))|\(\d+\)|(SHORT)|(LONG)/;

export default function FullScreenChart(props) {
  const chartContainerRef = useRef();
	const chartRef = useRef();
	const resizeObserverRef = useRef();

  const chartContainerDateRef = useRef();
	const chartDateRef = useRef();
	const resizeObserverDateRef = useRef();

  var numOpen = 0;
  var numClosed = 0;
  var totGain = 0;
  var totGainRaw = 0;
  var numWins = 0;
  var numLoss = 0;
  var numBreakeven = 0;
  var onlyGains = 0;
  var onlyLosses = 0;
  var onlyGainsRaw = 0;
  var onlyLossesRaw = 0;
  var winrate = 0;
  var avgGainPerTrade = 0;
  var avgGainPerWin = 0;
  var avgLossPerLoss = 0;


  var stdDev = 0;
  var sharpeRatio = 0;
  var openEk = 0;
  var avgNumTradesPerWeek = 0;
  var largestWin = 0;
  var largestLoss = 0;
  var largestWinPortfolio = 0;
  var largestLossPortfolio = 0;
  var numLongTradesClosed = 0;
  var numShortTradesClosed = 0;
  var longOnlyWinrate = 0;
  var shortOnlyWinrate = 0;
  var maxConsecutiveWins = 0;
  var maxConsecutiveLosses = 0;
  var zScore = 0;
  var avgTradeLength = 0;
  var maxDrawdown = 0;
  var ATH = 0;
  var currentDrawdown = 0;
  var cagr = 0;

  const tradesUnsorted = props.currentTrades;
  const trades = tradesUnsorted.sort((a,b) => (a.ts < b.ts) ? 1 : -1).reverse();
  console.log(trades)

  
  var chartDataDate = []
  var prevDateGain = 0;

  for(var i = 0; i < trades.length; i++){
    var currDate = new Date(trades[i].ts).toISOString().slice(0, 10);

    /*
    console.log("currDate");
    console.log(currDate);
    console.log("currrent I");
    console.log(i);
    console.log(trades[i])
    */

    var subIdx = 0;

    var currGain = prevDateGain;
    while(trades[Number(i+subIdx)] && new Date(trades[Number(i+subIdx)].ts).toISOString().slice(0, 10) == currDate){
      if(trades[Number(i+subIdx)].exits){
        for(var c in trades[Number(i+subIdx)].exits){
          currGain += trades[Number(i+subIdx)].exits[c].portfolio_gain;
        }
      }
      subIdx = Number(subIdx)+1
    }
    chartDataDate.push({
      time: {year: currDate.slice(0, 4), month: currDate.slice(5, 7), day: currDate.slice(8, 10)}, 
      value: currGain, 
    });

    i = Number(i+subIdx)
    prevDateGain = currGain;
  }
  
  console.log("chartDataDate")
  console.log(chartDataDate)

  var chartData = [{time: {year: 10000, month: 1, day: 1}, value: 0}];

  for(var i in trades){
      /*console.log(trades[i])
      console.log(numOpen)
      console.log(numClosed)
      console.log(totGain)
      console.log(numWins)
      console.log(numLoss)
      console.log(numBreakeven)*/
      numOpen += trades[i].exit ? 0 : 1;
      numClosed += trades[i].exit ? 1 : 0;
      if(trades[i].exits){
        var gainBefore = totGain;
        var gainBeforeRaw = totGainRaw;
        for(var c in trades[i].exits){
          if(trades[i].exits[c].portfolio_gain){
            totGain += trades[i].exits[c].portfolio_gain;
            totGainRaw += trades[i].exits[c].gain;
          }
        }
        var gainAfter = totGain;
        var gainAfterRaw = totGainRaw;
        if(gainAfter > gainBefore){
          numWins += 1;
          onlyGains += gainAfter-gainBefore;
          onlyGainsRaw += gainAfterRaw-gainBeforeRaw;
        } else if(gainAfter < gainBefore){
          numLoss += 1;
          onlyLosses += gainAfter-gainBefore;
          onlyLossesRaw += gainAfterRaw-gainBeforeRaw;
        } else if(gainAfter == gainBefore){
          numBreakeven += 1;
        }

        chartData.push({
          time: {year: Number(i)+10001, month: 1, day: 1}, 
          value: roundToSignificant(totGain), 
          custom: {
            instrument: trades[i].name ? trades[i].name.split(' ')[trades[i].name.split(' ').length-1] == "delay)" ? trades[i].name.slice(0, -11) : trades[i].name : trades[i].ticker, 
            datum: trades[i].ts,
            gainHere: trades[i].portfolio_gain ? trades[i].portfolio_gain : 0,
          }
        });

        if(ATH < totGain){
          ATH = totGain;
        } else{
          currentDrawdown = ATH-totGain;
        }
        if(currentDrawdown > maxDrawdown){
          maxDrawdown = currentDrawdown;
        }
      } 
      if(!trades[i].exit){
        if(trades[i].ek){
          openEk += trades[i].ek;
        }
      }
    }

    winrate = (numWins/(numWins+numLoss))*100;
    avgGainPerTrade = totGainRaw/trades.length;
    avgGainPerWin = onlyGainsRaw/numWins;
    avgLossPerLoss = onlyLossesRaw/numLoss;
    openEk = roundToSignificant(openEk);

    var avgGainPerTradePortfolio = totGain/numClosed;

    var numLongWins = 0;
    var numLongLosses = 0;
    var numShortWins = 0;
    var numShortLosses = 0;

    var currentConsecWins = 0;
    var currentConsecLosses = 0;

    var zScoreR = 1;

    var totDuration = 0;

    for(var i in trades){
      if(trades[i].exit){
        var xVarianceEtc = Math.pow(trades[i].portfolio_gain-avgGainPerTradePortfolio, 2);
        stdDev += xVarianceEtc;
        if(largestWin < trades[i].gain){
          largestWin = trades[i].gain
        }
        if(largestLoss > trades[i].gain){
          largestLoss = trades[i].gain
        }
        if(largestWinPortfolio < trades[i].portfolio_gain){
          largestWinPortfolio = trades[i].portfolio_gain
        }
        if(largestLossPortfolio > trades[i].portfolio_gain){
          largestLossPortfolio = trades[i].portfolio_gain
        }

        if(trades[i].direction == "long"){
          numLongTradesClosed += 1;
          if(trades[i].gain > 0){
            numLongWins += 1;
          } else if(trades[i].gain < 0){
            numLongLosses += 1;
          }
        } else{
          numShortTradesClosed += 1;
            if(trades[i].gain > 0){
              numShortWins += 1;
            } else if(trades[i].gain < 0){
              numShortLosses += 1;
            }
        }
        if(trades[i].gain > 0){
          if(currentConsecLosses > 0){
            zScoreR += 1;
          }
          currentConsecWins += 1;
          currentConsecLosses = 0;
          if(currentConsecWins > maxConsecutiveWins){
            maxConsecutiveWins = currentConsecWins;
          }
        } else if(trades[i].gain < 0){
          if(currentConsecWins > 0){
            zScoreR += 1;
          }
          currentConsecWins = 0;
          currentConsecLosses += 1;
          if(currentConsecLosses > maxConsecutiveLosses){
            maxConsecutiveLosses = currentConsecLosses;
          }
        }
        totDuration += trades[i].exit.ts - trades[i].entry.ts;
      }
    }

    longOnlyWinrate = (numLongWins/(numLongWins+numLongLosses))*100;
    shortOnlyWinrate = (numShortWins/(numShortWins+numShortLosses))*100;

    stdDev = stdDev*100;

    stdDev /= (numClosed-1);
    stdDev = Math.sqrt(stdDev);

    //console.log("stdDev")
    //console.log(stdDev)

    sharpeRatio = (totGain-7)/stdDev;

    try{
      var totWeeksOfTrading = (trades[trades.length-1].ts - trades[10].ts)/604800000; //1a traden är nån gammal grej från typ augusti 2022
      //den vill vi inte ha med efter reseten i mars 2023
      //så vi kör därifrån ungefär genom att välja den 10e traden
      var totYearsOfTrading = totWeeksOfTrading/52;
      //console.log("totWeeksOfTrading")
      //console.log(totWeeksOfTrading)

      avgNumTradesPerWeek = trades.length/totWeeksOfTrading;
    } catch(e){
      console.warn("totWeeksOfTrading error: ", e)
    }

    //console.log("zScoreR")
    //console.log(zScoreR)
    var P = (2*numWins*numLoss)
    var zScoreT = numClosed*(zScoreR-0.5)-P
    var zScoreN = Math.sqrt((P*(P-numClosed))/(numClosed-1))

    zScore = zScoreT/zScoreN; //zScore här är lite felaktig eftersom den nu baseras på closes, och eftersom de 
                              //ofta "stänger alla trades at the end of the day" och först stänger förlusterna 
                              //och sen vinsterna så blir streaksen väldigt stora. Borde baseras på ts av open istället.

    avgTradeLength = convertSecondsToSignificantTimeframe((totDuration/trades.length)/1000);

    cagr = (Math.pow((100+(totGain))/100, 1/totYearsOfTrading)-1)*100;

    var result = (
        <>
			<LineChartDates chartData={chartDataDate} cContRef={chartContainerDateRef} cRef={chartDateRef} resObsRef={resizeObserverDateRef} />
        </>
      );
  return(result);
};

/*
	<div>
		<LineChart chartData={chartData} cContRef={chartContainerRef} cRef={chartRef} resObsRef={resizeObserverRef} />
	</div>
*/