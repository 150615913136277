import { createChart, CrosshairMode, LineStyle } from 'lightweight-charts';
import React, { useEffect, useState, useRef } from 'react';
import { roundToSignificant } from './helpFunc'
import { StyleContext } from '../App';


function roundToTimeframe(num, timeframe){

	let secondsDivisor
	switch (timeframe) {
		case 5:
			secondsDivisor = 300;
			break;
	
		case 15:
			secondsDivisor = 900;
			break;
	
		case "1h":
			secondsDivisor = 3600;
			break;

		case "1d":
			secondsDivisor = 86400;
			break;

		default:
			secondsDivisor = 86400;
			break;
	}
	return(Math.round((num)/secondsDivisor)*secondsDivisor)
}

const regexRemoveParenthesis = /((CASH).*)|((\(([^)]+)\).*))|\(\d+\)|(SHORT)|(LONG)/;

function removeParenthesis(a) {
  a = a.replace(regexRemoveParenthesis, ' ');
  a = a.trim();
  return a;
}


//  ███    ███  █████  ██ ███    ██ 
//  ████  ████ ██   ██ ██ ████   ██ 
//  ██ ████ ██ ███████ ██ ██ ██  ██ 
//  ██  ██  ██ ██   ██ ██ ██  ██ ██ 
//  ██      ██ ██   ██ ██ ██   ████ 


export default function LineChart(props) {
	const chartContainerRef = props.cContRef;
	const chart = props.cRef;
	const resizeObserver = props.resObsRef;

	const styleC = React.useContext(StyleContext);

	console.log(props)

	var xAxisIterator = 0;

	useEffect(() => {
		if(props.chartData && props.chartData.length > 0){
			chart.current = createChart(chartContainerRef.current, {
				width: chartContainerRef.current.clientWidth,
				height: chartContainerRef.current.clientHeight, //window.innerHeight, //"300px",
				layout: {
					backgroundColor: styleC.style.bgGraph,
					textColor: styleC.style.trimColor,
				},
				grid: {
					vertLines: {
					//color: "#505050",
					visible: false,
					},
					horzLines: {
					color: styleC.style.bgGraphDarker,
					visible: true,
					}
				},
				crosshair: {
					mode: CrosshairMode.Normal,
					// hide the horizontal crosshair line
					horzLine: {
						visible: false,
					},
					// hide the vertical crosshair label
					vertLine: {
						labelVisible: true,
						visible: false,
					},
				},
				priceScale: {
					borderColor: styleC.style.trimColor
				},
				timeScale: {
					borderColor: styleC.style.trimColor,
					rightOffset: 5,
					autoScale: false,
					timeVisible: true,
					secondsVisible: true,
					visible: true,
					ticksVisible: true,
					tickMarkFormatter: function(time) {
						return time.year-10000;
					}
				},
				localization: {
					priceFormatter: function(price) {
						return roundToSignificant(price) + "%";
					},
					timeFormatter: function(time) {
						return time.year-10000;
					}
				}
			});
		
			const series = chart.current.addLineSeries({
				color: styleC.style.lineGraph,
				lineWidth: 2,
				lastValueVisible: false,
				priceLineVisible: false,
			});

			console.log("Setting series data to: ")
			console.log(props.chartData)

			series.setData(props.chartData);

			series.createPriceLine({price: 0, title: '', color: '#777', lineWidth: 1, axisLabelVisible: false});

			/*
			var toolTipMargin = 10;
			var priceScaleWidth = 50;
			var toolTip = document.createElement('div');
			toolTip.className = 'chart-legend';
			toolTip.id = 'fin';
			chartContainerRef.current.appendChild(toolTip);
			toolTip.style.display = 'block';
			toolTip.style.left = 3 + 'px';
			toolTip.style.top = 3 + 'px';
			toolTip.innerHTML =	'<div>'+"Equity curve"+'</div>';
			*/

			const container = chartContainerRef.current;

			const toolTipWidth = 120;
			const toolTipHeight = 90;
			const toolTipMargin = 15;

			// Create and style the tooltip html element
			const toolTip = document.createElement('div');
			toolTip.style = `height: 84px; position: absolute; display: none; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border: 1px solid; border-radius: 2px;font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
			toolTip.style.background = 'rgba(20,20,20,0.5)';
			toolTip.style.color = '#f9fdff';
			toolTip.style.borderColor = 'rgba(9,9,9,0.5)';
			container.appendChild(toolTip);

			// update tooltip
			chart.current.subscribeCrosshairMove(param => {
				//console.log(param)
				if (
					param.point === undefined ||
					!param.time ||
					param.point.x < 0 ||
					param.point.x > container.clientWidth ||
					param.point.y < 0 ||
					param.point.y > container.clientHeight
				) {
					toolTip.style.display = 'none';
				} else {
					try {
						// time will be in the same format that we supplied to setData.
						// thus it will be YYYY-MM-DD
						const dateStr = param.time;
						toolTip.style.display = 'block';
						const data = param.seriesPrices.get(series);
						const time = param.time
						//console.log(data)
						const price = data;
						const cDI = Number(time.year-10000); //chartDataIndex
						toolTip.innerHTML = `
						<div>
							<div>${removeParenthesis(props.chartData[cDI].custom.instrument)}</div>
							<div>Gain: ${roundToSignificant(props.chartData[cDI].custom.gainHere)}%</div>
							<div>${String(new Date(props.chartData[cDI].custom.datum)).slice(4, 16)}</div>
							<div>${String(new Date(props.chartData[cDI].custom.datum)).slice(16, 21)}</div>
							<div>Totalt: ${roundToSignificant(props.chartData[cDI].custom.totHere)}%</div>
						</div>`;

						const coordinate = series.priceToCoordinate(price);
						let shiftedCoordinate = param.point.x - 50;
						if (coordinate === null) {
							return;
						}
						shiftedCoordinate = Math.max(
							0,
							Math.min(container.clientWidth - toolTipWidth, shiftedCoordinate)
						);
						const coordinateY =
							coordinate - toolTipHeight - toolTipMargin > 0
								? coordinate - toolTipHeight - toolTipMargin
								: Math.max(
									0,
									Math.min(
										container.clientHeight - toolTipHeight - toolTipMargin,
										coordinate + toolTipMargin
									)
								);
						toolTip.style.left = shiftedCoordinate + 'px';
						toolTip.style.top = coordinateY + 'px';
					}catch(e){
							
					}
				}
			});

			try{
				if(document.getElementsByClassName("tv-lightweight-charts").length > 1){
					document.getElementsByClassName("tv-lightweight-charts")[0].remove();
					document.getElementsByClassName("chart-legend")[0].remove();
				}
			}catch(e){
				//console.log(e)
			}
		}
	}, [props]);

	
	  // Resize chart on container resizes.
	useEffect(() => {
		resizeObserver.current = new ResizeObserver(entries => {
		var { width, height } = entries[0].contentRect;
		height = chartContainerRef.current.clientHeight;
		try {
			chart.current.applyOptions({ width, height });
		} catch (error) {
			
		}
		setTimeout(() => {
			try {
				chart.current.timeScale().fitContent();
			} catch (error) {
				
			}
		}, 0);
		});

		resizeObserver.current.observe(chartContainerRef.current);

		return () => resizeObserver.current.disconnect();
	}, []);

	return (
		<div className="chartContainer">
			<div
				ref={chartContainerRef}
				className="lineChart"
				style={{height: "400px"}}
			>
			</div>
		</div>
	);
}